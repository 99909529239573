import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const CategoryService ={

fetchAllCategorise:()=>{
    return axios.get(`${baseURL}/category/show`)
}

}

export default CategoryService