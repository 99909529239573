import React, { createContext, useState, useEffect } from "react";
import useSessionStorage from "./useSessionStorage";
import FavoriteService from "../Service/FavoriteService";
import { notifyError, notifySuccess } from "../../toast";
import "react-toastify/dist/ReactToastify.css";

export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useSessionStorage("favorites", []);
  const [loading, setLoading] = useState(false);
  const isLoggedIn = !!localStorage.getItem("uid");

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!isLoggedIn) return;
      setLoading(true);
      try {
        const uid = localStorage.getItem("uid");
        const response = await FavoriteService.fetchFavoriteItemUid(uid);
        if (response.data && response.data.products && Array.isArray(response.data.products)) {
          setFavorites(response.data.products);
        } else {
          setFavorites([]);
        }
      } catch (error) {
        console.error("Error fetching favorites:", error);
        // notifyError("Failed to load favorites.");
        setFavorites([]);
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [isLoggedIn]);

  const addToFavorites = async (productWithDetails) => {
    if (!isLoggedIn) return;

    setLoading(true);
    try {
      const uid = localStorage.getItem("uid");
      const response = await FavoriteService.PostFavoriteItemUid(uid, productWithDetails._id);
      
      // Check if the response was successful
      if (response.data.success) {
        setFavorites((prevFavorites) => {
          const isProductInFavorites = prevFavorites.some(
            (favProduct) => favProduct._id === productWithDetails._id
          );

          if (isProductInFavorites) {
            return prevFavorites.map((favProduct) =>
              favProduct._id === productWithDetails._id ? productWithDetails : favProduct
            );
          } else {
            return [...prevFavorites, productWithDetails];
          }
        });

        notifySuccess("Item added to favorites!");
      } else {
        notifyError("Failed to add item to favorites.");
      }
    } catch (error) {
      console.error("Error adding to favorites:", error);
      notifyError("Failed to add to favorites.");
    } finally {
      setLoading(false);
    }
  };

  let notificationShown = false;

  const removeFromFavorites = async (productId) => {
    const confirmRemoval = window.confirm(
      "Are you sure you want to remove this item from your favorites?"
    );
  
    if (!confirmRemoval) return;
  
    setLoading(true);
    try {
      if (isLoggedIn) {
        const uid = localStorage.getItem("uid");
        const response = await FavoriteService.RemoveFavoriteItemUid(uid, productId);
        
        if (response.data.success) {
          setFavorites((prevFavorites) => {
            const updatedFavorites = prevFavorites.filter(
              (favProduct) => favProduct._id !== productId
            );
            
            if (!notificationShown) {
              notifySuccess("Item successfully removed from favorites!");
              notificationShown = true; 
            }
            
            return updatedFavorites;
          });
        } else {
          notifyError("Failed to remove item from favorites.");
        }
      }
    } catch (error) {
      notifyError("Failed to remove item from favorites.");
    } finally {
      setLoading(false);
      notificationShown = false; 
    }
  };
  

  const isInFavorites = (productId) => {
    return favorites.some((product) => product._id === productId);
  };

  return (
    <FavoriteContext.Provider
      value={{
        favorites,
        addToFavorites,
        removeFromFavorites,
        isInFavorites,
        loading,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};
