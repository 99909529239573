import React, { useContext, useState, useEffect } from "react";
import axios from "axios"; // Import axios
import { CartContext } from "./CartContext";
import { FavoriteContext } from "./FavoriteContext";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../Authentication/LoginPopup";
import "../../App.css";
import SearchIcon from "../../assets/images/search.png";
import Heart from "../../assets/images/heart.png";
import Shopping from "../../assets/images/shopping.png";
import Searchbar from "./Searchbar";
import { Logo, titleName } from "../Container/Images";
import ProductService from "../Service/ProductService";

const Header = () => {
  const { cartItems, cartTotal, removeFromCart, addToCart } =
    useContext(CartContext);
  const { favorites } = useContext(FavoriteContext);
  const navigate = useNavigate();

  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [isShowLoginOpen, setShowLoginPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const toggleCartPopup = () => {
    setShowCartPopup(!showCartPopup);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCartPopup && !event.target.closest(".cart-popup")) {
        setShowCartPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCartPopup]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm.trim()) {
        try {
          const response = await ProductService.fetchProduct()
          const filteredProducts = response.data.products.filter((product) =>
            product.title.en.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setSearchResults(filteredProducts);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setShowSearchPopup(event.target.value.trim().length > 0);
  };

  const isLoggedIn = () => {
    return false; // Replace with your login logic
  };

  const handleCheckout = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isLoggedIn) {
      navigate("/checkout");
    } else {
      localStorage.setItem("intendedPath", "/checkout");
      setIsLoginOpen(true);
    }
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
    setShowSearchPopup(false);
  };

  return (
    <div className="header-container flex justify-between px-3 pt-2 pb-3 bg-white">
      <div className="flex items-center gap-2">
        <img
          src={Logo}
          alt="Company Logo"
          className="h-12 cursor-pointer"
          onClick={() => navigate("/")}
        />
        <div className="font-poppins ">
          <span className="text-lgi font-bold font-poppins whitespace-nowrap">
            {titleName}{" "}
          </span>
        </div>
      </div>
      <div className="search-icon-container flex border-slate-400 rounded-full h-9 w-9 items-center gap-2 mt-3">
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="w-9 ml-4 cursor-pointer"
          onClick={() => setShowSearchPopup(!showSearchPopup)}
        />
      </div>
      <Searchbar />

      <div className="cart-section flex items-center gap-6">
        <div
          className="flex items-center cursor-pointer relative"
          onClick={() => navigate("/favorites")}
        >
          <img
            src={Heart}
            alt="Favorite Icon"
            className="w-9 h-9 cursor-pointer"
          />
          <div className="font-poppins ml-2">
            <span className="text-xs">Favorites</span>
            <p className="font-normal text-sm mt-0 whitespace-nowrap">
              My Wishlist
            </p>
          </div>
          <div className="absolute top-0 right-20 h-4 w-4 text-center bg-blue-950 text-white text-xs rounded-full border-deep-color">
            {favorites.length}
          </div>
        </div>

        <div className="flex items-center relative ">
          <img
            src={Shopping}
            alt="Cart Icon"
            className="w-9 h-9 cursor-pointer"
            onClick={() => navigate("/cart")}
          />
          <div className="font-inter ml-2">
            <span className="text-xs whitespace-nowrap">Your Cart:</span>
            <p className="font-normal text-sm mt-0" onClick={toggleCartPopup}>
              ₹{cartTotal.toFixed(2)}
            </p>
          </div>
          <div className="absolute top-0 right-14 h-4 w-4 text-center bg-blue-950 text-white text-xs rounded-full border-deep-color">
            {cartItems.length}
          </div>
          {showCartPopup && (
            <div className="absolute right-5 mt-72 w-60 bg-white border border-gray-300 rounded shadow-lg p-2 z-10 overflow-hidden">
              {cartItems.length > 0 ? (
                <div
                  className={
                    cartItems.length > 3 ? "max-h-48 overflow-y-auto" : ""
                  }
                >
                  {cartItems.map((item) => (
                    <div
                      key={`${item._id}-${item.variant}`}
                      className="flex items-center justify-between mb-2"
                    >
                      <img
                        src={item.image[0]}
                        alt={item.title.en}
                        className="w-12 h-12 object-cover"
                        onClick={() => handleProductClick(item._id)}
                      />
                      <div className="flex-1 ml-2">
                        <div
                          className="text-[12px] font-semibold"
                          onClick={() => handleProductClick(item._id)}
                        >
                          {item.title.en}
                        </div>
                        <div className="text-xs text-gray-500">
                          {/* {item.variant && item.variant.sku} */}
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm text-gray-600 mr-2">
                            ₹
                            {(
                              parseFloat(item.price.replace("₹", "")) *
                              item.quantity
                            ).toFixed(2)}{" "}
                            / {item.quantity}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => removeFromCart(item._id, item.variant)}
                        className="text-black text-sm font-semibold border-none p-1 rounded-full"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-sm text-gray-600">Your cart is empty</div>
              )}
              <div className="flex justify-between">
                <p className="text-slate-500 text-sm mt-0">Total:</p>
                <p className="font-bold text-sm mt-0">
                  ₹{cartTotal.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => navigate("/cart")}
                  className="bg-primary-color text-white cursor-pointer py-1 px-3 border-none rounded-lg hover:bg-moderate-color"
                >
                  View Cart
                </button>
                <button
                  onClick={handleCheckout}
                  className="bg-moderate-color text-white cursor-pointer py-1 px-3 rounded-lg border-none hover:bg-primary-color"
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showSearchPopup && (
        <div className="search-popup absolute z-20 bg-white border border-gray-300 rounded shadow-lg p-4 w-full max-w-md">
          <div className="relative mb-4 items-center">
            <input
              type="text"
              placeholder="What are you looking for?"
              value={searchTerm}
              onChange={handleInputChange}
              className="pl-10 py-2 w-64 rounded-full border border-solid focus:outline-none"
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="w-8 absolute left-3 top-2 cursor-pointer"
              onClick={() => setShowSearchPopup(false)}
            />
          </div>
          {searchResults.length > 0 ? (
            <ul className="max-h-64 overflow-y-auto">
              {searchResults.slice(0, 4).map((product) => (
                <li key={product._id} className="flex items-center mb-2">
                  <img
                    src={product.image[0]}
                    alt={product.title.en}
                    onClick={() => handleProductClick(product._id)}
                    className="w-12 h-12 object-cover"
                  />
                  <div className="ml-2">
                    <div
                      className="text-sm font-semibold"
                      onClick={() => handleProductClick(product._id)}
                    >
                      {product.title.en}{" "}
                    </div>
                  </div>
                </li>
              ))}
              {searchResults.length > 4 && (
                <div className="text-center text-gray-600 mt-2">
                  Scroll for more...
                </div>
              )}
            </ul>
          ) : (
            <div className="text-sm text-gray-600">No results found</div>
          )}
        </div>
      )}

      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />
    </div>
  );
};

export default Header;
