import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProfileDashBordService from "../Service/ProfileDashBordService";
import { baseURL } from "../api";

function BillingInfo() {
  const { state } = useLocation();
  const { orderId } = state || {};

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variantData, setVariantData] = useState([]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId) {
        setError("Order ID is missing");
        setLoading(false);
        return;
      }
  
      try {

        const attributesResponse = await axios.get(`${baseURL}/attributes/show`);
        const variantData = attributesResponse.data;

        const response = await ProfileDashBordService.fetchProfileOrder(orderId);
  
        let cart = response.data.cart.map((item) => {
          if (item.variant && Array.isArray(variantData)) {
            variantData.forEach((data) => {
              data.variants.forEach((id) => {
                if (id._id === item.variant[Object.keys(item.variant)[0]]) {
                  item.variant["variantName"] = id.name.en;
                }
              });
            });
          } else {

            item.variant = { variantName: "N/A" };
          }
          return item;
        });

        response.data["cart"] = cart;
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error?.response?.data || error.message);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrderDetails();
  }, [orderId]);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white p-4 shadow-md mt-8 w-auto">
      <h2 className="text-lg font-semibold mb-4">Billing Info</h2>
      <hr className="border-t-2 border-dashed border-0 border-slate-200 mb-5" />
      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="font-medium">Address:</p>
          <p>{order.user_info.address}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">City:</p>
          <p>{order.user_info.city}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">Email:</p>
          <p>{order.user_info.email}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">Name:</p>
          <p>{order.user_info.name}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">Phone:</p>
          <p>{order.user_info.contact}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">State:</p>
          <p>{order.user_info.state}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">Zip:</p>
          <p>{order.user_info.zipCode}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">Country:</p>
          <p>{order.user_info.country}</p>
        </div>
      </div>
    </div>
  );
}

export default BillingInfo;
