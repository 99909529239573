import React, { useState, useEffect, useContext } from "react";
import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Product3 from "../../assets/images/product3.svg";
import Product4 from "../../assets/images/product4.svg";
import Flex from "../../assets/images/Redflex.png";
import Cart1 from "../../assets/images/heartcart.svg";
import Cart2 from "../../assets/images/shoppingcart.svg";
import Banner1 from "../../assets/images/Banner1.svg";
import Banner2 from "../../assets/images/Banner2.svg";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import LeftArrow from "../../assets/images/BtnL.png";
import RightArrow from "../../assets/images/BtnR.png";

import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import NewArrival from "./NewArrival";
import axios from "axios";
import FlexBannerService from "../Service/FlexBannerService";

const TodayDeal = ({ apiProducts }) => {
  const navigate = useNavigate();
  const [time, setTime] = useState(3600 * 3);

  const { addToCart } = useContext(CartContext);
  const { addToFavorites, isInFavorites } = useContext(FavoriteContext);
  const [products, setProducts] = useState([]);
  const [flexPanels, setFlexPanels] = useState(null);
  const [flex1, setFlext1] = useState(null);
  const [flex2, setFlext2] = useState(null);

  useEffect(() => {
 FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const todayItem = response.data.find(
            (item) => item.position === "Today's Deal"
          );
          const flex_1 = response.data.find(
            (item) => item.position === "Flex 1"
          );
          const flex_2 = response.data.find(
            (item) => item.position === "Flex 2"
          );
          setFlexPanels(todayItem || null);
          setFlext1(flex_1);
          setFlext2(flex_2);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  const handleImageClick = () => {
    if (flex1 && flex1.link) {
      window.open(flex1.link, "_blank").focus();
    }
    if (flex2 && flex2.link) {
      window.open(flex2.link, "_blank").focus();
    }
  };

  useEffect(() => {
    // axios
    //   .get("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products")
    //   .then((response) => {
    //     if (response.data && response.data.products) {
    //       setProducts(response.data.products);
    //       // setFilteredProducts(response.data.products);
    //     } else {
    //       console.error("Unexpected response format", response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching products:", error);
    //   });
    setProducts(apiProducts);
  }, [apiProducts]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const todayDealProducts = products.filter((product) => {
    if (product.status !== "show") {
      return false;
    }
    let tags = [];

    try {
      // Parse each tag entry

      if (Array.isArray(product.tag)) {
        tags = tags.concat(product.tag);
      } else {
        console.error("Parsed tag is not an array:");
      }
    } catch (e) {
      console.error("Error parsing tags:", e);
      return false;
    }

    // Check if "Today's Deal" is in any of the tags
    return tags.includes("Today's Deal");
  });

  // Show only the first 8 products
  const displayedProducts = todayDealProducts.slice(0, 8);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return { hours: hrs, minutes: mins, seconds: secs };
  };

  const dealDuration = 24 * 3600;


  
  const getCurrentTimeInSeconds = () => Math.floor(Date.now() / 1000);

  useEffect(() => {
    const savedEndTime = localStorage.getItem("dealEndTime");
    const currentTime = getCurrentTimeInSeconds();

    if (savedEndTime) {
      const endTime = JSON.parse(savedEndTime);
      const remainingTime = endTime - currentTime;

      if (remainingTime > 0) {
        setTime(remainingTime);
      } else {
        resetTimer();
      }
    } else {
      resetTimer();
    }
  }, []);


  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          resetTimer();
          return dealDuration;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

 
  const resetTimer = () => {
    const currentTime = getCurrentTimeInSeconds();
    const newEndTime = currentTime + dealDuration;
    localStorage.setItem("dealEndTime", JSON.stringify(newEndTime));
    setTime(dealDuration);
  };


  const { hours, minutes, seconds } = formatTime(time);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex justify-center mt-2">
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={`full-${index}`}
            className="w-5 h-5 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        ))}
        {halfStar && (
          <svg
            key="half"
            className="w-5 h-5 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <defs>
              <linearGradient id="half-star-gradient">
                <stop offset="50%" stopColor="currentColor" />
                <stop offset="50%" stopColor="gray" stopOpacity="1" />
              </linearGradient>
            </defs>
            <path
              fill="url(#half-star-gradient)"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <svg
            key={`empty-${index}`}
            className="w-5 h-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill="#D1D5DB"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        ))}
      </div>
    );
  };
  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };
  const handleViewMoreClick = () => {
    navigate("/product", { state: { section: "Today's Deal" } });
  };

  return (
    <>
      <div className="premium-full-container">
        <div className="premium flex  justify-between  pt-6 pb-8 ">
          <div className="flex-col">
            <div className="font-bold text-5xl ">Today's Deal</div>
            <div className="text-sm  mt-3">
              Choose your necessary products from this feature category
            </div>
          </div>
          <div className="flex">
            <button
              className=" relative border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color"
              onClick={handleViewMoreClick}
            >
              View More
            </button>
          </div>
        </div>
        {/* Flex Banner */}
        <div className="premium-container  flex gap-4 justify-between ">
          <div className=" today-flex-img relative w-72  text-white flex flex-col items-center justify-center">
            {/* <img src={Flex} alt="Flex" className="flex-img w-full h-full shadow-lg absolute inset-0 z-0 lg:w-64 " /> */}
            {flexPanels && flexPanels.imageUrl && (
              <img
                src={flexPanels.imageUrl}
                alt="Today's Deal"
                className="flex-img w-full h-full shadow-lg absolute inset-0 z-0 lg:w-64  "
                onClick={handleImageClick}
              />
            )}
            <div className="relative bottom-24">
              <div className="mt-72 pb-1">
                <div className="ml-10">
                  {/* <div className="bg-gray-300 h-8 w-21 mr-3 -rotate-12"></div>
                  <div className="bg-primary-color   font-bold text-white text-3xl w-36 h-10 mb-4 relative bottom-4 -rotate-12">
                    30% OFF
                  </div> */}
                </div>
                <div className="text-lg font-bold flex gap-2 text-center py-1 border-2 p-2 lg:ml-5">
                  <div className="bg-white text-5xl rounded text-black p-2">
                    {hours.toString().padStart(2, "0")}
                    <div className="text-xs  ">Hours</div>
                  </div>
                  <div className="bg-white text-5xl rounded text-black p-2">
                    {minutes.toString().padStart(2, "0")}
                    <div className="text-xs  ">Minute</div>
                  </div>
                  <div className="bg-white text-5xl rounded text-black p-2">
                    {seconds.toString().padStart(2, "0")}
                    <div className="text-xs  ">Seconds</div>
                  </div>
                </div>
                <div className="pb-7 ml-7 lg:ml-14 lg:mt-4">
                  <button
                    onClick={handleViewMoreClick}
                    className="bg-primary-color text-white font-bold py-3 px-5 rounded-31xl border-deep-color cursor-pointer mr-2 mt-4"
                  >
                    View Products
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="premium-product w-full   ">
            {displayedProducts.map((product) => (
              <div
                key={product._id}
                className="premium-product-box bg-white gap-1 shadow-md text-center rounded border-gray-600 border-1 w-52 h-full pb-2 lg:w-48  "
              >
                <div className="relative mr-13 bg-secondary-color text-sm text-white py-1 pr-9 rounded-r-3xl w-5 mt-6 whitespace-nowrap">
                Off {((product.prices.discount / product.prices.originalPrice)*100).toFixed(0)}%
                </div>
                <img
                  src={product.image[0]}
                  alt={product.title.en}
                  className="product-img w-full  cursor-pointer"
                  onClick={() => handleProductClick(product._id)}
                />
                {renderStars(product.rating)}
                <div
                  className="mt-4 text-lg  font-semibold cursor-pointer line-clamp-2 "
                  onClick={() => handleProductClick(product._id)}
                >
                  {product.title.en}
                </div>
                <div className="flex justify-center items-center gap-2 mt-2">
                  <div className="text-base font-semibold text-primary-color">
                    ₹{product.prices.price}
                  </div>
                  <div className="text-base line-through text-gray-600">
                    ₹{product.prices.originalPrice}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <NewArrival apiProducts={products} />

      <div className="Banner-flex  mx-auto">
        {/* Banners */}
        <div className="Banner-flex1 flex justify-center gap-3 ml-25 pt-14 mb-20">
          <div>
            {flex1 && flex1.imageUrl && (
              <img
                src={flex1.imageUrl}
                alt=""
                className="w-full"
                onClick={handleImageClick}
              />
            )}
            {/* <img src={Banner1} alt="" className='w-full' /> */}
          </div>
          <div>
            {flex2 && flex2.imageUrl && (
              <img
                src={flex2.imageUrl}
                alt=""
                className="w-full"
                onClick={handleImageClick}
              />
            )}
            {/* <img src={Banner2} alt="" className='w-full' /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TodayDeal;
