import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

const ConfirmationDialog = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed top-28 left-1/2 transform -translate-x-1/2 bg-white p-6 rounded shadow-lg max-w-md w-full">
        <div className="text-center mb-4 text-lg font-semibold">
          Are you sure you want to delete this address?
        </div>
        <div className="flex justify-center gap-4">
          <button
            className="bg-primary-color text-white rounded-lg px-7 py-2  border-none hover:bg-secondary-color transition duration-300"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-gray-500 text-white rounded-lg px-7 py-2 border-none hover:bg-gray-600 transition duration-300"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmationDialog;
