import React, { useEffect, useState } from "react";
import "../../App.css";

import AddressModal from "./AddressModal";
import Cod from "../../assets/images/Payment_icons/cod.jpg";
import Razor from "../../assets/images/Payment_icons/razor.png";

import editicon from "../../assets/images/Payment_icons/Editicon.png";
import deleteicon from "../../assets/images/deleteicon.svg";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import "react-toastify/dist/ReactToastify.css";
import addform from "../../assets/images/addform.png";
import tickmark from "../../assets/images/tickmark.png";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useCart } from "../header/useCart";
import { notifyError, notifySuccess } from "../../toast";
import AddressService from "../Service/AddressService";
import ProfileDashBordService from "../Service/ProfileDashBordService";
import CouponService from "../Service/CouponService";
import { baseURL } from "../api";

const CheckInfo = ({ subTotal, total, setShippingCostData, discount, isCouponApplied, couponId }) => {
  const [activeStep, setActiveStep] = useState("Personal Info");
  const [addresses, setAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [selectedShipping, setSelectedShipping] = useState("Free Shipping");
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [orderStatus, setOrderStatus] = useState("Place Order");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const { clearCart } = useCart();

  useEffect(() => {
    console.log(discount);
  }, [subTotal, total, discount]);

  const navigate = useNavigate();

  const PersonIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="w-8 h-8"
    >
      <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
    </svg>
  );

  const TrackIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className="w-8 h-8"
    >
      <path d="M112 0C85.5 0 64 21.5 64 48l0 48L16 96c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 208 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 160l-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l16 0 176 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 224l-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 144 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 288l0 128c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64 0-32 0-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7L416 96l0-48c0-26.5-21.5-48-48-48L112 0zM544 237.3l0 18.7-128 0 0-96 50.7 0L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z" />
    </svg>
  );

  const PaymentIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className="w-8 h-8"
    >
      <path d="M64 64C28.7 64 0 92.7 0 128L0 384c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64L64 64zm48 160l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zM96 336c0-8.8 7.2-16 16-16l352 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-352 0c-8.8 0-16-7.2-16-16zM376 160l80 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24l0-48c0-13.3 10.7-24 24-24z" />
    </svg>
  );

  const steps = [
    { name: "Personal Info", icon: <PersonIcon /> },
    { name: "Shipping Info", icon: <TrackIcon /> },
    { name: "Payment Info", icon: <PaymentIcon /> },
  ];

  const getStepClass = (step) => {
    const stepIndex = steps.findIndex((s) => s.name === step);
    const activeIndex = steps.findIndex((s) => s.name === activeStep);

    if (stepIndex === activeIndex) {
      // Current active step
      return "steps-list font-bold text-secondary-color bg-light-color w-1/3 whitespace-nowrap transition duration-300 ease-in-out ";
    }
    if (stepIndex < activeIndex) {
      return "steps-list font-bold text-secondary-color bg-light-color w-1/3 whitespace-nowrap transition duration-300 ease-in-out ";
    }

    return "steps-list font-bold text-gray-600 hover:bg-light-color w-1/3 whitespace-nowrap transition duration-300 ease-in-out";
  };

  const getIconClass = (step) => {
    const stepIndex = steps.findIndex((s) => s.name === step);
    const activeIndex = steps.findIndex((s) => s.name === activeStep);

    if (stepIndex === activeIndex) {
      // Current active step
      return "w-5 h-5 fill-primary-color ";
    }
    if (stepIndex < activeIndex) {
      // Completed steps
      return "w-5 h-5 fill-primary-color";
    }
    // Upcoming steps
    return "w-5 h-5 fill-gray-500";
  };

  const uid = localStorage.getItem("uid");
  const id = localStorage.getItem("id");

  useEffect(() => {
    if (uid) {
      fetchAddresses();
    }
  }, [uid]);

  useEffect(() => {
    // Set default shipping cost for Free Shipping
    if (selectedShipping === "Free Shipping") {
      setShippingCost(0);
      setShippingCostData(0);
    }
  }, [selectedShipping]);

  const fetchAddresses = async () => {
    try {
      const response = await AddressService.fetchUidAddress(uid)
      const fetchedAddresses = response.data;

      setAddresses(fetchedAddresses);

      if (fetchedAddresses.length > 0 && !selectedAddress) {
        setSelectedAddress(fetchedAddresses[0]);
      }
    } catch (err) {
      console.error("Error fetching addresses:", err);
    }
  };

  const handleAddAddress = () => {
    setCurrentAddress(null);
    setIsModalOpen(true);
  };

  const handleEditAddress = (address) => {
    setCurrentAddress(address);
    setIsModalOpen(true);
  };

  const openDeleteDialog = (address) => {
    setAddressToDelete(address);
    setIsDialogOpen(true);
  };

  const handleDeleteAddress = async () => {
    if (!addressToDelete) return;

    try {

      await AddressService.fetchUidAddressDelete(uid,addressToDelete._id)

      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address._id !== addressToDelete._id)
      );

      toast.success("Address deleted successfully");
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Error deleting address");
    } finally {
      setIsDialogOpen(false);
      setAddressToDelete(null);
    }
  };

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setAddressToDelete(null);
  };

  const handleSaveAddress = (addressData) => {
    if (currentAddress) {
      setAddresses(
        addresses.map((address) =>
          address === currentAddress ? addressData : address
        )
      );
    } else {
      setAddresses([...addresses, addressData]);
    }
    setIsModalOpen(false);
    fetchAddresses();
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address === selectedAddress ? null : address);
    toast.success(
      `Address ${address === selectedAddress ? "deselected" : "selected"}`,
      {
        position: "top-right",
        theme: "colored",
      }
    );
  };

  const handleShippingSelect = (method, cost) => {
    setSelectedShipping(method === selectedShipping ? null : method);
    setShippingCost(cost);
    setShippingCostData(cost);
    toast.success(
      `Shipping method ${
        method === selectedShipping ? "deselected" : "selected"
      }`,
      {
        position: "top-right",
        theme: "colored",
      }
    );
  };

  const handlePaymentSelect = (method) => {
    setSelectedPayment(method === selectedPayment ? null : method);
    toast.success(
      `Payment method ${
        method === selectedPayment ? "deselected" : "selected"
      }`,
      {
        position: "top-right",
        theme: "colored",
      }
    );
  };

  const placeOrder = async (payResponse) => {
    setOrderStatus("Processing...");
    setIsDisabled(true);

    let cartItem = JSON.parse(sessionStorage.getItem("cartItems"));
    let cartItems = [];
    cartItem.forEach((element) => {
      element["title"] = element.title.en;
      cartItems.push(element);
    });

    const requestObj = {
      user: id,
      uid: uid,
      invoice: 0,
      cart: cartItems,
      user_info: {
        name: `${selectedAddress.firstName} ${selectedAddress.lastName}`,
        email: selectedAddress.email,
        contact: selectedAddress.phone,
        address: selectedAddress.address,
        city: selectedAddress.city,
        country: selectedAddress.country,
        state: selectedAddress.state,
        zipCode: selectedAddress.zipCode,
      },
      subTotal: subTotal,
      shippingCost: shippingCost,
      discount: discount,
      total: total,
      shippingOption: selectedShipping,
      paymentMethod: selectedPayment,
      cardInfo: payResponse,
      status: "Pending",
    };

    try {
      // await axios.post("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/order/add", requestObj);
      await ProfileDashBordService.PostOrder(requestObj)

      if(isCouponApplied){
        const id = localStorage.getItem("id");
        const couponObj = {couponId: couponId, userId:id}
        // await axios.post("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/coupon/update-apply", couponObj);
        await CouponService.fetchCouponUpdate(couponObj)
      }

      await axios.post(`${baseURL}/cart/clear`, { uid });
      

      setTimeout(() => {
        setOrderStatus("Order Placed Successfully");
        notifySuccess("Order Placed Successfully");
        clearCart();

        setIsDisabled(true);
      }, 2000);

      setOrderStatus("Ordered");

      await new Promise((resolve) => setTimeout(resolve, 2700));
      navigate("/profile");
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Failed to place order. Please try again.", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const handlePlaceOrder = async () => {
    let cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  
    // Check if the cart is empty
    if (!cartItems || cartItems.length === 0) {
      toast.error(
        "Your cart is empty. Please add items to your cart before placing an order.",
        {
          position: "top-right",
          theme: "colored",
        }
      );
      return;
    }

if (!selectedAddress) {
  toast.error("Please provide address details!", {
    position: "top-right",
    theme: "colored",
  });
  return;
}

if (!selectedShipping) {
  toast.error("Please provide shipping details!", {
    position: "top-right",
    theme: "colored",
  });
  return;
}

if (!selectedPayment) {
  toast.error("Please provide payment details!", {
    position: "top-right",
    theme: "colored",
  });
  return;
}

  
    // Proceed with payment processing
    if (selectedPayment === "Razor Pay") {
      try {
        const totalInPaise = Math.round(total * 100);
  
        var options = {
          key: "rzp_test_iX8ErrltvdBeax",
          key_secret: "rxp7Eqvf9bJ3w7ur25iam8Jc",
          amount: totalInPaise,
          currency: "INR",
          name: "Nile and Mile",
          description: "for testing purpose",
          handler: function (response) {
            console.log(response);
            placeOrder(response);
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#3399cc",
          },
          options: {
            upi: true,
          },
        };
  
        var pay = new window.Razorpay(options);
        pay.open();
      } catch (error) {
        console.log(error);
      }
    } else if (selectedPayment === "Cash On Delivery") {
      placeOrder({});
    }
  };
  

  return (
    <div className="check-box flex items-center justify-center mx-auto p-6 bg-white h-auto rounded-md">
      <div className="w-11/12">
        <h2 className="text-2xl font-bold mb-4 text-left ">Checkout</h2>
        <hr className="border-t-2 border-dashed border-0  border-slate-200 mb-4" />
        <div className="checkout-payment-list flex mb-5 bg-slate-200 rounded-md">
          {steps.map(({ name, icon }, index) => (
            <div
              key={name}
              className={`cursor-pointer p-3 text-center flex gap-2 items-center ${getStepClass(
                name
              )} ${index === 0 ? "rounded-l-md" : ""} ${
                index === steps.length - 1 ? "rounded-r-md" : ""
              }`}
              onClick={() => setActiveStep(name)}
            >
              <div
                className={`flex items-center justify-center w-12 h-12 ${getIconClass(
                  name
                )}`}
              >
                {icon}
              </div>
              <div className="">{name}</div>
            </div>
          ))}
        </div>
        {activeStep === "Personal Info" && (
          <div className="address-text-container text-left p-36">
            <div className="justify-start">
              <div className="relative right-0 bottom-0">
                <h3 className="text-xl font-bold mb-2 text-left whitespace-nowrap">
                  Shipping Information
                </h3>
                <p className="mb-4 text-left whitespace-nowrap">
                  Please fill all information below
                </p>
              </div>
              {addresses.map((address, index) => (
                <div
                  key={index}
                  className={`address-box border cursor-pointer p-4 mb-4 ${
                    selectedAddress === address ? "bg-light-color" : ""
                  }`}
                  onClick={() => handleSelectAddress(address)}
                >
                  <div className="flex justify-between text-left items-start">
                    <div className="address-container">
                      <div className="address-row">
                        <p className="address-label font-semibold">
                          Address Type:
                        </p>
                        <p className="address-value">{address.addressName}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">City:</p>
                        <p className="address-value">{address.city}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">Email:</p>
                        <p className="address-value">{address.email}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">
                          First name:
                        </p>
                        <p className="address-value">{address.firstName}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">
                          Last name:
                        </p>
                        <p className="address-value">{address.lastName}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">Phone:</p>
                        <p className="address-value">{address.phone}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">Address:</p>
                        <p className="address-value">{address.address}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">State:</p>
                        <p className="address-value">{address.state}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">Zip:</p>
                        <p className="address-value">{address.zipCode}</p>
                      </div>
                      <div className="address-row">
                        <p className="address-label font-semibold">Country:</p>
                        <p className="address-value">{address.country}</p>
                      </div>
                    </div>
                    <div className="add-icon flex justify-end items-start space-x-2 mt-3">
                      <div
                        className="border-none"
                        onClick={() => handleEditAddress(address)}
                      >
                        <img src={editicon} alt="Edit" className="w-4 h-4" />
                      </div>
                      <div
                        className="border-none"
                        onClick={() => openDeleteDialog(address)}
                      >
                        <img
                          src={deleteicon}
                          alt="Delete"
                          className="w-4 h-4 border-none"
                        />
                      </div>
                    </div>
                  </div>
                  {selectedAddress === address && (
                    <div className="absolute top-1 right-2">
                      <img src={tickmark} className="h-5 w-5" />
                    </div>
                  )}
                </div>
              ))}

              <div className="relative add-btn bg-white p-4 border rounded  whitespace-nowrap flex items-center w-fit ml-56 ">
                <img
                  src={addform}
                  className="h-11 w-11 cursor-pointer text-lg font-semibold  mt-4"
                  onClick={handleAddAddress}
                />
                <span className="text-lgi mt-3 font-semibold">
                  Add New Address
                </span>
              </div>

              <div className="shipping-btn relative mt-28 ml-36">
                <button
                  className=" bg-primary-color border-none text-white p-2 w-48 rounded cursor-pointer"
                  onClick={() => setActiveStep("Shipping Info")}
                >
                  Proceed to Shipping
                </button>
              </div>
            </div>
          </div>
        )}

        {activeStep === "Shipping Info" && (
          <div>
            <div className="relative">
              <h3 className="text-xl font-bold mb-2 text-left whitespace-nowrap">
                Shipping Method
              </h3>
              <p className="mb-4 text-left whitespace-nowrap">
                Please select a Shipping Method
              </p>
            </div>
            <div className="relative">
              <div className="shipping-container cursor-pointer grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  // {
                  //   method: "Post Office",
                  //   cost: 100,
                  //   delivery: "Delivery In 7-15 Days",
                  // },
                  {
                    method: "Free Shipping",
                    cost: 0,
                    delivery: "Delivery In 3-5 Days",
                  },
                  {
                    method: "Express Shipping",
                    cost: 200,
                    delivery: "Delivery In 1 Days",
                  },

                  // {
                  //   method: "Standard Shipping",
                  //   cost: 120,
                  //   delivery: "Delivery In 7-10 Days",
                  // },
                ].map((shipping) => (
                  <div
                    key={shipping.method}
                    className={`shipping-box cursor-pointer border px-6 py-6 flex items-center justify-between  relative ${
                      selectedShipping === shipping.method
                        ? "bg-light-color border-4 border-primary-color"
                        : "bg-slate-200 hover:bg-light-color"
                    }`}
                    onClick={() =>
                      handleShippingSelect(shipping.method, shipping.cost)
                    }
                  >
                    <div>
                      <div className="text-sm font-bold">{shipping.method}</div>
                      <div className="text-xs">{shipping.delivery}</div>
                    </div>
                    <div className="text-sm font-bold ml-4 sm:ml-7">
                      ₹{shipping.cost.toFixed(2)}
                    </div>
                    {selectedShipping === shipping.method && (
                      <div className="absolute top-2 right-2 w-6 h-6 flex items-center justify-center">
                        <img
                          src={tickmark}
                          className="h-5 w-5"
                          alt="Selected"
                        />
                      </div>
                    )}
                  </div>
                ))}
                {/* Proceed to Payment Button */}
                <div className="col-span-1 sm:col-span-2 mt-4 flex justify-center">
                  <button
                    className="bg-primary-color border-none text-white py-2 px-4 w-full sm:w-48 rounded cursor-pointer"
                    onClick={() => setActiveStep("Payment Info")}
                  >
                    Proceed to Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeStep === "Payment Info" && (
          <div className="text-center p-10 sm:p-20">
            <div className="relative sm:right-20 sm:bottom-20">
              <h3 className="text-xl font-bold mb-2 text-left whitespace-nowrap">
                Payment Method
              </h3>
              <p className="mb-4 text-left whitespace-nowrap">
                Please select a Payment Method
              </p>
            </div>
            <div className="relative sm:right-20 sm:bottom-20">
              <div className="payment-container grid grid-cols-1 sm:grid-cols-3 gap-4">
                {[
                  {
                    src: Cod,
                    alt: "Cash On Delivery",
                    method: "Cash On Delivery",
                  },
                  { src: Razor, alt: "Razor Pay", method: "Razor Pay" },
                ].map(({ src, alt, method }) => (
                  <div
                    key={method}
                    className={`payment-box border p-4 flex items-center justify-between cursor-pointer relative ${
                      selectedPayment === method
                        ? "bg-light-color"
                        : "bg-slate-200 hover:bg-light-color"
                    }`}
                    onClick={() => handlePaymentSelect(method)}
                  >
                    <img src={src} alt={alt} className="w-10 h-10" />
                    <span className="text-sm font-bold">{method}</span>
                    {selectedPayment === method && (
                      <div className="absolute top-2 right-2 rounded-full w-6 h-6 flex items-center justify-center">
                        <img
                          src={tickmark}
                          className="h-5 w-5"
                          alt="Selected"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4 flex justify-center sm:relative sm:top-16">
              <button
                className={`bg-primary-color cursor-pointer border-none text-white py-2 px-4 w-full sm:w-48 rounded ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handlePlaceOrder}
                disabled={isDisabled}
              >
                {orderStatus}
              </button>
            </div>

          </div>
        )}
      </div>
      {isModalOpen && (
        <AddressModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveAddress}
          currentAddress={currentAddress}
          isEditMode={!!currentAddress}
        />
      )}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onConfirm={handleDeleteAddress}
        onCancel={handleDialogCancel}
        message="Are you sure you want to delete this address?"
      />
    </div>
  );
};

export default CheckInfo;
