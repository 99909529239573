import React, { useState } from 'react';

import Symbol from "../../assets/images/Symbol.svg";
import LeftArrow from "../../assets/images/BtnL.png";
import RightArrow from "../../assets/images/BtnR.png";
import profile1 from "../../assets/images/profile1.svg";


import "../../App.css"
import { getFeedBack } from '../Container/Images';

const feedlist = [
  {
    id: 1,
    rating: 4.5,
    feed: `Exceptional shopping experience! The user-friendly interface and
    seamless navigation make finding and purchasing products a breeze.
    As a Marketing Manager, I appreciate the...`,
    img: profile1,
    name: "Lisa Williams",
    role: "Regular Shopper"
  },
  {
    id: 2,
    rating: 4,
    feed: `Exceptional shopping experience! The user-friendly interface and
      seamless navigation make finding and purchasing products a breeze.
      As a Marketing Manager, I appreciate the...`,
    img: profile1,
    name: "Lisa Williams",
    role: "Regular Shopper"
  },
  {
    id: 3,
    rating: 5,
    feed: `Exceptional shopping experience! The user-friendly interface and
        seamless navigation make finding and purchasing products a breeze.
        As a Marketing Manager, I appreciate the...`,
    img: profile1,
    name: "Lisa Williams",
    role: "Regular Shopper"
  },
  {
    id: 4,
    rating: 3,
    feed: `Exceptional shopping experience! The user-friendly interface and
        seamless navigation make finding and purchasing products a breeze.
        As a Marketing Manager, I appreciate the...`,
    img: profile1,
    name: "Lisa Williams",
    role: "Software"
  },
  {
    id: 5,
    rating: 3,
    feed: `Exceptional shopping experience! The user-friendly interface and
        seamless navigation make finding and purchasing products a breeze.
        As a Marketing Manager, I appreciate the...`,
    img: profile1,
    name: "Lisa Williams",
    role: "Regular Shopper"
  },
];

const filteredFeedlist = feedlist.filter(feed => feed.rating >= 4);

const countHighRatingReviews = feedlist.filter(feed => feed.rating >= 0).length;


const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);

  const feedBack = getFeedBack()

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? filteredFeedlist.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === filteredFeedlist.length - 1 ? 0 : prevIndex + 1));
  };


  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
        <div className="flex justify-center mt-2">
            {[...Array(fullStars)].map((_, index) => (
                <svg key={`full-${index}`} className="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
            ))}
            {halfStar && (
                <svg key="half" className="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                    <defs>
                        <linearGradient id="half-star-gradient">
                            <stop offset="50%" stopColor="currentColor" />
                            <stop offset="50%" stopColor="gray" stopOpacity="1" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#half-star-gradient)" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
            )}
            {[...Array(emptyStars)].map((_, index) => (
                <svg key={`empty-${index}`} className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill="#D1D5DB" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
            ))}
        </div>
    );
};

  return (
    <>
      <div className="feed-back-container flex justify-between bg-cover bg-center p-6 px-12 py-14" style={{ backgroundImage: `url(${feedBack.FeedBgImage})`, minHeight: '400px' }}>
        <div className="feed-backsub1 max-w-4xl mx-auto p-8 pb-3">
          <img src={feedBack.SymbolIcon} alt="Symbol" className="w-12 h-12 mr-4" />
          <div className="flex mb-8">
            <div className="text-13xl text-wrap font-semibold pt-3">
              Let's explore customer sentiments towards our offerings.
            </div>
          </div>
          <div className="text-lg text-gray-700">
            Discover what customers are saying about our products. Dive into the feedback on the quality and performance of our offerings. Gain insights into how our customers perceive our products and their overall satisfaction. Your opinions matter, and we're here to listen.
          </div>
          <button
            onClick={() => setShowFeedback(!showFeedback)}
            className="mt-7 border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden z-10 transition-all duration-300 hover:text-white hover:bg-primary-color"
          >
            Write a review
          </button>
          <div className="flex mt-4 lg:mt-6">
            <img src={LeftArrow} onClick={handlePrevClick} alt="Left Arrow" className="w-12 h-12 mr-2 cursor-pointer" />
            <img src={RightArrow} onClick={handleNextClick} alt="Right Arrow" className="w-12 h-12 cursor-pointer" />
          </div>
        </div>
          <div className='feed-backsub2'>
        <div className={` customer-profile  flex flex-col bg-white h-72 w-auto shadow-xl rounded pt-5 mt-9 ${showFeedback ? 'move-feedback' : ''} transform`}>
        <div key={filteredFeedlist[currentIndex].id} className="text-center">
            <div className="text-xl font-bold ">
              {renderStars(filteredFeedlist[currentIndex].rating)}
            </div>
            <div className="mt-4">{filteredFeedlist[currentIndex].feed}</div>
            <div className="flex justify-center ">
              <img src={filteredFeedlist[currentIndex].img} alt="Profile" className="w-16 h-16 rounded-full lg:w-20 lg:h-20" />
            </div>
            <div className="mt-2 font-semibold lg:font-bold text-wrap">{filteredFeedlist[currentIndex].name}</div>
            <div className="text-gray-600">{filteredFeedlist[currentIndex].role}</div>
         </div>
        </div>

        <div className=" customer-view absolute ml-64 left-96 mt-0  bg-secondary-color rounded-md h-24 w-48 transition-transform 500 transform">
          <div className='pt-7 ml-3'>
            <img src={feedBack.FeedBackFlagIcon} alt="Flag" className='h-11 w-11' />
          </div>
          <div className="absolute ml-14 bottom-3 flex flex-col items-center space-x-2">
            <div className='text-white font-thin text-13xl'>4/5</div>
            <div className="flex items-center">
              {renderStars(4)}
            </div>
            <div className="text-white text-sm pt-1 lg:pt-2">{countHighRatingReviews} Customer view</div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;

