import React, { useEffect, useState } from 'react'

import axios from 'axios';
import FlexBannerService from '../Service/FlexBannerService';

const ProfileBanner = () => {
  const [productflex,setProductFlex]= useState()

  useEffect(() => {
FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const productflex = response.data.find(
            (item) => item.position === "Profile Flex"
          );
          setProductFlex(productflex || null);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  return (
    <>
   <div
      className='profile-banner flex justify-center '>
         {productflex && productflex.imageUrl && (
        <img src={ productflex.imageUrl} alt="" className='w-full profile-banner-img ' />
         )}
    </div>
      
    </>
  )
}

export default ProfileBanner


