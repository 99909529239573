import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";

import TodayDeal from "./Todaydeal";
import Topcategoory from "./Topcategoory";
import Summmer from "./Summmer";
import Section from "./Section";
import banner from "../../assets/images/herobanner.png";
import banner2 from "../../assets/images/pet2.png";

import Premium from "./Premium";
import Month from "./Month";
import Productlist from "./Productlist";
import Feedback from "./Feedback";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/navbar";
import Header from "../header/header";

import TabletNavbar from "../navbar/TabletNavbar";
import WhatsappButton from "../contact/WhatsappButton";
import { Brandlist } from "../Product/Brandlist";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import ProductService from "../Service/ProductService";
import HeroBannerService from "../Service/HeroBannerService";

const images = [
  { src: banner, alt: "Image 1" },
  { src: banner2, alt: "Image 2" },
  { src: banner, alt: "Image 3" },
];

const Home = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [banners, setBanners] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate("/product", { state: { category } });
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        // const response = await axios.get(
        //   "https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/banner/getAll"
        // );
        const response = await HeroBannerService.fetchHeroBanner()
        setBanners(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

  ProductService.fetchAllProduct()
      .then((response) => {
        if (response.data && response.data.products) {
          const allProducts = response.data.products;

          setProducts(allProducts);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    fetchBanners();
  }, []);

  const handleImageClick = () => {
    if (banners && banners.link) {
      window.open(banners.link, "_blank").focus();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banners.length === 0) return; 

    const autoSlideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === banners.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(autoSlideInterval);
  }, [banners]);

  return (
    <>
      <div className="home-container fixed z-10 w-full top-0">
      <ToastContainer />
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>
      <div className="banner-full-container flex pt-[108px]">
  <div className="relative pr-16 w-[1509px] ml-[81px] h-[630px] banner-container">
    {banners.length > 0 && (
      <>
        <img
          src={banners[currentIndex].imageUrl}
          alt={banners[currentIndex].title}
          onClick={handleImageClick}
          className="banner-container w-[1509px]"
        />
        <button
          onClick={() => setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? banners.length - 1 : prevIndex - 1
          )}
          className="carousel-arrow left"
        >
          &#9664; 
        </button>
        <button
          onClick={() => setCurrentIndex((prevIndex) =>
            prevIndex === banners.length - 1 ? 0 : prevIndex + 1
          )}
          className="carousel-arrow right"
        >
          &#9654; 
        </button>

      </>
    )}
    <div className="home-banner-dot absolute bottom-0 left-0 right-0 flex justify-center space-x-2 py-2 pb-7">
          {banners.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`home-banner-dot-btn cursor-pointer ${
                currentIndex === index
                  ? "bg-slate-100 text-white"
                  : "bg-slate-50"
              } p-1 rounded-full h-3`}
            ></button>
          ))}
        </div>
      </div>
    </div>

      <div className="p-4">
        <div className="section">
          <Section />
        </div>
        <TodayDeal apiProducts={products} />
        <Topcategoory />
      </div>
      <Summmer apiProducts={products} />
      <div className="p-5">
        <Premium apiProducts={products} />
      </div>
      <Month />
      <div className="p-9">
        <Productlist apiProducts={products}/>
      </div>
      <Feedback />
      <WhatsappButton />
      <Footer />

      <div className="md:hidden h-[3.6rem] sm:h-16">

      </div>
      <div className="fixed z-10  w-full bottom-0">
        <TabletNavbar />
      </div>
    </>
  );
};

export default Home;
