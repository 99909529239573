import React, { useContext, useState, useEffect } from "react";
import Products from "../../Model";
import ReviewModal from "./ReviewModal";
import { useParams } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import SuccessIcon from "../../assets/images/success.svg";
import IncrementIcon from "../../assets/images/incrementicon.svg";
import DecrementIcon from "../../assets/images/decrementicon.svg";
import Cart1 from "../../assets/images/heartcart.svg";
import Cart2 from "../../assets/images/shoppingcart.svg";
import BuyIcon from "../../assets/images/Buyicon.svg";
import FIcon from "../../assets/images/f_icon.svg";
import TIcon from "../../assets/images/t_icon.svg";
import InIcon from "../../assets/images/in_icon.svg";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";
import TabletNavbar from "../navbar/TabletNavbar";

import { FaStar } from "react-icons/fa";

import Petshop from "../../assets/images/Petshop.svg";
import Petshop_Banner from "../../assets/images/Petshop_Banner.svg";

import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Product3 from "../../assets/images/product3.svg";
import Product4 from "../../assets/images/product4.svg";

import { useNavigate } from "react-router-dom";
import LoginPopup from "../Authentication/LoginPopup";
import axios from "axios";
import FlexBannerService from "../Service/FlexBannerService";
import { baseURL } from "../api";
import { get } from "lodash";
import ProductService from "../Service/ProductService";

const Productinfo = ({ productId }) => {
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("description");

  const [modalOpen, setModalOpen] = useState(false);
  const [productflex,setProductFlex]= useState()

  useEffect(() => {
   FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const productflex = response.data.find(
            (item) => item.position === "Product Flex 2"
          );
          setProductFlex(productflex || null);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  useEffect(() => {
    setReviews([]);

    const fetchProduct = async () => {
        try {
            const response = await ProductService.fetchProductShow(productId);
            const data = response.data;
            setProduct(data);
        } catch (error) {
            console.error("Error fetching product:", error);
        }
    };

    const fetchReviews = async () => {
        try {
            const response = await ProductService.fetchProductReview(productId);
            const data = response.data;
            setReviews(data);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };

    fetchProduct();
    fetchReviews();
}, [productId]);


  if (!product) return <div>Loading...</div>;

  const title = product.title.en;
  const description = product.description.en;

  const handleOpenModal = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn) {
      setModalOpen(true);
    } else {
      setIsLoginOpen(true);
    }
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleAddReview = (newReview) => {
    setReviews([...reviews, newReview]);
  };

  const renderStars = (rating) => {
    const validatedRating = Math.max(0, Math.min(5, rating));
    const fullStars = Math.floor(validatedRating);
    const halfStar = validatedRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    // Check that no invalid lengths are created
    if (fullStars < 0 || fullStars > 5 || emptyStars < 0 || emptyStars > 5) {
      console.error("Invalid star counts:", {
        fullStars,
        halfStar,
        emptyStars,
      });
      return null; // Prevent rendering with invalid lengths
    }

    return (
      <div className="flex justify-start mt-2">
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={`full-${index}`}
            className="w-5 h-5 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        ))}
        {halfStar && (
          <svg
            key="half"
            className="w-5 h-5 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <defs>
              <linearGradient id="half-star-gradient">
                <stop offset="50%" stopColor="currentColor" />
                <stop offset="50%" stopColor="gray" stopOpacity="1" />
              </linearGradient>
            </defs>
            <path
              fill="url(#half-star-gradient)"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <svg
            key={`empty-${index}`}
            className="w-5 h-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill="#D1D5DB"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        ))}
      </div>
    );
  };

  const discriptionpoint = description
    .split(".")
    .map((point) => point.trim())
    .filter((point) => point);

  return (
    <div>
      <div className="r flex flex-col w-full relative">
        <div className="bg-white review-containe h-full w-4/5 rounded  p-4">
          <div className="flex justify-start gap-20 pt-2">
            <div
              className={`cursor-pointer relative ${
                activeTab === "description"
                  ? "font-bold text-primary-color"
                  : "hover:text-primary-color font-bold"
              }`}
              onClick={() => setActiveTab("description")}
            >
              Description
              {activeTab === "description" && (
                <div className="absolute top-7 left-0 w-full h-1 bg-primary-color transition-all duration-300"></div>
              )}
            </div>
            <div
              className={`cursor-pointer relative ${
                activeTab === "reviews"
                  ? "font-bold text-primary-color"
                  : "hover:text-primary-color font-bold"
              }`}
              onClick={() => setActiveTab("reviews")}
            >
              Reviews
              {activeTab === "reviews" && (
                <div className="absolute top-7 left-0 w-full h-1 bg-primary-color transition-all duration-300"></div>
              )}
            </div>
            {/* <div
                className={`cursor-pointer relative ${activeTab === 'warranty' ? 'font-bold text-primary-color' : 'hover:text-primary-color font-bold'}`}
                onClick={() => setActiveTab('warranty')}
              >
                Warranty Policy
                {activeTab === 'warranty' && (
                  <div className="absolute top-7 left-0 w-full h-1 bg-primary-color transition-all duration-500"></div>
                )}
              </div> */}
            {/* <div
                className={`cursor-pointer relative ${activeTab === 'shipping' ? 'font-bold text-primary-color' : 'hover:text-primary-color font-bold'}`}
                onClick={() => setActiveTab('shipping')}
              >
                Shipping Information
                {activeTab === 'shipping' && (
                  <div className="absolute top-7 left-0 w-full h-1 bg-primary-color transition-all duration-300"></div>
                )}
              </div> */}
          </div>

          <div className=" pt-10">
            {activeTab === "description" && (
              <div className="description-box flex flex-col h-64 gap-4 overflow-y-scroll scrollbar-hidden">
                <h3>Key Features:</h3>
                <ul className="list-disc pl-5 flex flex-col gap-2">
                  {discriptionpoint.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            )}
            {activeTab === "reviews" && (
              <div className="review-box flex justify-between gap-12">
                <div className="w-72 h-52 mb-10">
                  <h2 className="text-base font-bold mb-4">
                    Ratings & Reviews
                  </h2>
                  <div className="review-track">
                    {/* Calculate and display the average star rating */}
                    <div className="review-rating flex items-center mb-2 bg-slate-100 justify-between rounded">
                      {reviews.length > 0 ? (
                        <>
                          {/* Calculate the average rating */}
                          <p className="mt-0 p-1">
                            {renderStars(
                              reviews.reduce(
                                (acc, review) => acc + review.rating,
                                0
                              ) / reviews.length
                            )}
                          </p>
                          <p className="ml-2 text-gray-600 text-sm mt-1 p-1 whitespace-nowrap">
                            (
                            {(
                              reviews.reduce(
                                (acc, review) => acc + review.rating,
                                0
                              ) / reviews.length
                            ).toFixed(1)}
                            ) Out of 5
                          </p>
                        </>
                      ) : (
                        <p className="mt-0 p-1">No Reviews Yet</p>
                      )}
                    </div>
                    <div className="text-center">
                      <p className="text-gray-600 mb-4 whitespace-nowrap">
                        Total Reviews
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      {["5 Star", "4 Star", "3 Star", "2 Star", "1 Star"].map(
                        (star, index) => {
                          const rating = 5 - index; // rating value (5, 4, 3, 2, 1)
                          const ratingCount = reviews.filter(
                            (r) => Math.round(r.rating) === rating
                          ).length;
                          const barWidth =
                            (ratingCount / reviews.length) * 100 + "%"; // calculate percentage width
                          return (
                            <div key={index} className="flex items-center">
                              <span className="w-10 text-sm mr-4 whitespace-nowrap">
                                {star}
                              </span>
                              <div className="w-full review-bar rounded-full h-2">
                                <div
                                  className="filled-bar h-2 rounded-full"
                                  style={{ width: barWidth }}
                                ></div>
                              </div>
                              <div className="flex gap-1">
                                <span className="ml-2 text-sm text-gray-500">
                                  ({ratingCount})
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                      <div>
                        <button
                          className="add-review-btn mt-2 w-full border-none bg-primary-color text-white font-bold py-2 px-4 rounded hover:bg-secondary-color cursor-pointer"
                          onClick={handleOpenModal}
                        >
                          Add Your Review
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="review-custom relative text-wrap">
                  <div className="">
                    <div className="text-lg text-center review-custom-text pb-8 pt-3">
                      Review for {product.title.en}
                    </div>
                  </div>
                  <div className="review-list overflow-y-auto max-h-160">
                    <div className="review-box flex flex-col gap-4">
                      {reviews.length === 0 ? (
                        <div className="text-gray-600">
                          No reviews available for this product.
                        </div>
                      ) : (
                        reviews.map((review, index) => (
                          <div key={index} className="flex flex-col mb-4">
                            <div className="flex flex-col mb-1">
                              {renderStars(review.rating)}
                            </div>
                            <div>
                              <p>{review.comment}</p>
                              <p className="text-sm text-gray-600 mt-1">
                                -{"Nile & Mile User "}
                                {/* {review.userId ? review.userId.name : "Anonymous"} */}
                              </p>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <ReviewModal
              show={modalOpen}
              handleClose={handleCloseModal}
              handleAddReview={handleAddReview}
              productId={productId}
              userId={currentUser.id}
            />
          </div>
        </div>
        <div className="product-view-petshop-banner">
        {productflex && productflex.imageUrl && (
          <img src={productflex.imageUrl} alt="" className="w-full mt-2 product-view-petshop-banner-img  " />
        )}
        </div>
      </div>
      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />
    </div>
  );
};

export default Productinfo;
