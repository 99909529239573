import React, { createContext, useState, useEffect } from "react";
import useSessionStorage from "./useSessionStorage";
import axios from "axios";
 import CartService from "../Service/CartService"
 import ProductSerice from "../Service/ProductService"
import { baseURL } from "../api";
import { notifyError, notifySuccess } from "../../toast";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useSessionStorage("cartItems", []);
  const [cartTotal, setCartTotal] = useSessionStorage("cartTotal", 0);
  const [showCartPopup, setShowCartPopup] = useState(false);

  // Fetch cart items on mount if logged in
  useEffect(() => {
    const fetchCart = async () => {
      const uid = localStorage.getItem("uid");
      if (uid) {
        try {
          // const cartResponse = await axios.get(`https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/cart/${uid}`);
          const cartResponse = await CartService.fetchCartItemUid(uid)
          if (cartResponse.data && cartResponse.data.cartItems) {
            const cartItems = cartResponse.data.cartItems;

            const productRequests = cartItems.map(item =>
              // axios.get(`https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products/show/${item.productId}`)
              ProductSerice.fetchProductId(item.productId)
            );

            const productResponses = await Promise.all(productRequests);

            const updatedCartItems = cartItems.map(item => {
              const product = productResponses.find(
                response => response.data._id === item.productId
              ).data;

              return {
                ...item,
                title: product.title,
                description: product.description,
                slug: product.slug,
                categories: product.categories,
                image: product.image,
                stock: product.stock,
                tag: product.tag,
                rating: product.rating,
                prices: {
                  originalPrice: product.prices?.originalPrice || 0,
                  price: product.prices?.price || 0,
                  discount: product.prices?.discount || 0
                },
                variants: product.variants || [product.variants[0]],
                totalPrice: product.prices?.price * item.quantity || 0
              };
            });

            setCartItems(updatedCartItems);
          }
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      }
    };

    fetchCart();
  }, [setCartItems]);

  // Calculate cart total when cartItems change
  useEffect(() => {
    if (Array.isArray(cartItems)) {
      const total = cartItems.reduce(
        (sum, item) => sum + (item.price.replace('₹', '')|| 0.00) * item.quantity ,
        0
      );
      setCartTotal(total);
    }
  }, [cartItems, setCartTotal]);

  // Add to cart
  const addToCart = async (product) => {
    const { _id, variant = {}, price, quantity } = product;

    let updatedCartItems = [];

    setCartItems((prevItems) => {
        updatedCartItems = [...prevItems];
        const existingProductIndex = updatedCartItems.findIndex(
            (item) => 
                item._id === _id && 
                item.variant && 
                variant && 
                item.variant[Object.keys(item.variant)[0]] === variant[Object.keys(variant)[0]]
        );

        if (existingProductIndex >= 0) {
            updatedCartItems[existingProductIndex].quantity += quantity;
        } else {
            updatedCartItems.push(product);
        }

        return updatedCartItems;
    });

    const uid = localStorage.getItem("uid");
    if (uid) {
        try {
            await CartService.PostCartItemUid(uid, updatedCartItems);
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }

    setShowCartPopup(true);
};


  const removeFromCart = async (productId, variant) => {
    if (!productId) {
      console.error("Missing productId");
      return;
    }
  
    setCartItems((prevItems) =>
      prevItems.filter((item) => {
        if (variant && item.variant) {
     
          return !(
            item._id === productId &&
            item.variant[Object.keys(item.variant)[0]] === variant[Object.keys(variant)[0]]
          );
        } else {
     
          return item._id !== productId;
        }
      })
    );
    const uid = localStorage.getItem("uid");
    if (uid) {
      try {
        console.log("Attempting to remove item from cart:", { uid, productId, variant });
  
        const response = await CartService.RemoveCartItemUid(uid, productId, variant);
        console.log("Item removed from cart:", response);
      } catch (error) {
        console.error("Error removing item from cart:", error.message || error);
      }
    } else {
      console.error("UID not found in localStorage.");
    }
  };
  
  
  


  const clearCart = () => {
    sessionStorage.removeItem("cartItems");
    sessionStorage.removeItem("cartTotal");
    setCartItems([]);
    setCartTotal(0);
  };

  // Update cart item quantity
  const updateCartItemQuantity = async (productId, variant, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        const itemVariantKey = item.variant ? Object.keys(item.variant)[0] : null;
        const variantKey = variant ? Object.keys(variant)[0] : null;

        return (
          item._id === productId &&
          itemVariantKey &&
          variantKey &&
          item.variant[itemVariantKey] === variant[variantKey]
        ) ? { ...item, quantity } : item;
      })
    );

    const uid = localStorage.getItem("uid");
    if (uid) {
      try {
        await axios.post(`${baseURL}/cart/update-cart`, {
          uid,
          productId,
          variant,
          quantity,
        });
      } catch (error) {
        console.error("Error updating item quantity:", error);
      }
    }
};


  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartTotal,
        showCartPopup,
        addToCart,
        removeFromCart,
        updateCartItemQuantity,
        setShowCartPopup,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
