import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AttributesService from "../Service/AttributesService";
import ProfileDashBordService from "../Service/ProfileDashBordService";

function OrderInfo() {
  const { state } = useLocation();
  const { orderId } = state || {};

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variantData, setVariantData] = useState([]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId) {
        setError("Order ID is missing");
        setLoading(false);
        return;
      }

      try {
        const attributesResponse = await AttributesService.fetchAllAttribute();
        setVariantData(attributesResponse.data);

        const response = await ProfileDashBordService.fetchProfileOrder(orderId)
        let cart = response.data.cart.map((item) => {
          if (Array.isArray(variantData)) {
            attributesResponse.data.forEach((data) => {
              data.variants.forEach((id) => {
                if (
                  item.variant &&
                  id._id === item.variant[Object.keys(item.variant)[0]]
                ) {
                  item.variant["variantName"] = id.name.en;
                }
              });
            });
          } else {
            console.error("variantData is not an array:", variantData);
          }
          return item;
        });

        response.data["cart"] = cart;
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white p-4 shadow-md mt-8 w-auto">
      <h2 className="text-lg font-semibold mb-4">Order Info</h2>
      <hr className="border-t-2 border-dashed border-0 border-slate-200 mb-5" />
      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="font-medium whitespace-nowrap">Order Number:</p>
          <p>{order?.invoice || "N/A"}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium whitespace-nowrap">Payment Method:</p>
          <p>{order?.paymentMethod || "N/A"}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium whitespace-nowrap">Total Amount:</p>
          <p>{order ? order.total.toFixed(2) : "N/A"}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium whitespace-nowrap">Order Status:</p>
          <p>{order?.status || "N/A"}</p>
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
