import React from 'react';

import "../../App.css"

import { useNavigate } from 'react-router-dom';
import Top1 from "../../assets/images/Top1.svg"
import Top2 from "../../assets/images/Top2.svg"
import Top3 from "../../assets/images/Top3.svg"
import Top4 from "../../assets/images/Top4.svg"
import Top5 from "../../assets/images/Top5.svg"
import Top6 from "../../assets/images/Top6.svg"
import Top7 from "../../assets/images/Top7.svg"
import Top8 from "../../assets/images/Top8.svg"

const Topcategoory = () => {
  const navigate = useNavigate();

    const Toplist=[
        {id:1,img:Top1,name:"Dog Food",available:"10 items available"},
        {id:2,img:Top2,name: "Cat Food",available:"10 items available"},
        {id:3,img:Top3,name: "Bird Food",available:"10 items available"},
        {id:4,img:Top4,name: "Fish Food",available:"10 items available"},
        {id:5,img:Top5,name: "Horse Food", available:"10 items available"},
        {id:6,img:Top6,name: "Rabbit Food", available:"10 items available"},
        {id:7,img:Top7,name: "Hamster Food", available:"10 items available"},
        {id:8,img:Top8,name: "Reptile Food",available:"10 items available"},
            
    ]
    return(
        <>
          <div className='top-category-full'>
            <div className="top-category-container1 flex justify-between mb-4">
              <div className="flex-col">
                <div className="font-bold text-5xl">Top Category</div>
                <div className="text-sm">The Top Categories Of Our Platform</div>
              </div>
              {/* Uncomment if you need the View More button */}
              {/* <div>
                <button
                  className="relative border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color"
                  onClick={() => navigate("/product")}
                >
                  View More
                </button>
              </div> */}
            </div>
    
            <div className="top-category-container2 scroll-container flex overflow-x-auto pt-2 pb-3 text-center scrollbar-hide">
              {Toplist.map((list) => (
                <div key={list.id} className="bg-white shadow-lg border p-4 mx-2 min-w-[150px] flex-shrink-0 cursor-pointer" onClick={() => navigate("/product")}>
                  <img src={list.img} alt={list.name} className="rounded m-6" />
                  <div className="text-lg font-bold pt-2">{list.name}</div>
                  <div className="text-sm p-2">{list.available}</div>
                </div>
              ))}
            </div>
          </div>
        </>
    )
}

export default Topcategoory;
