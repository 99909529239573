import React, { useEffect, useState } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TabletNavbar from "../navbar/TabletNavbar";
import StarRating from "../../StarRating";
import ProfileDashBordService from "../Service/ProfileDashBordService";
import AttributesService from "../Service/AttributesService";

function TrackingStatus() {
  const { state } = useLocation();
  const { orderId } = state || {};
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variantData, setVariantData] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const navigate = useNavigate();

  const getOrderStatusClass = (step, activeStep) => {
    const stepIndex = [
      "Pending",
      "Processing",
      "Shipped",
      "Delivered",
      "Cancel",
    ].indexOf(step);
    const activeIndex = [
      "Pending",
      "Processing",
      "Shipped",
      "Delivered",
      "Cancel",
    ].indexOf(activeStep);

    if (stepIndex === activeIndex) {
      if (step === "Delivered") {
        return "bg-primary-color";
      }
      return "bg-primary-color";
    }
    if (stepIndex < activeIndex) {
      if (step === "Delivered") {
        return "bg-primary-color";
      }
      return "bg-primary-color";
    }
    return "bg-slate-300";
  };

  const getOrderStatusIcon = (status) => {
    switch (status) {
      case "Pending":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        );
      case "Processing":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="#ffff"
          >
            <path d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z"></path>
          </svg>
        );
      case "Shipped":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="#ffff"
          >
            <path d="M19.15 8a2 2 0 0 0-1.72-1H15V5a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 1 1.73 3.49 3.49 0 0 0 7 .27h3.1a3.48 3.48 0 0 0 6.9 0 2 2 0 0 0 2-2v-3a1.07 1.07 0 0 0-.14-.52zM15 9h2.43l1.8 3H15zM6.5 19A1.5 1.5 0 1 1 8 17.5 1.5 1.5 0 0 1 6.5 19zm10 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"></path>
          </svg>
        );
      case "Delivered":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        );
      case "Cancel":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6l4 2"
            />
          </svg>
        );
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "Pending":
        return "Pending";
      case "Processing":
        return "Processing";
      case "Shipped":
        return "Shipped";
      case "Delivered":
        return "Delivered";
      case "Cancel":
        return "Cancel";
      default:
        return "Unknown";
    }
  };

  const orderStatus = order?.status || "Unknown";

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        // let attributesResponse = await axios.get(
        //   "https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/attributes/show"
        // );
        let attributesResponse = await AttributesService.fetchAllAttribute();
        setVariantData(attributesResponse.data);

        // const response = await axios.get(
        //   `https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/order/${orderId}`
        // );
        const response = await ProfileDashBordService.fetchProfileOrder(orderId)
        const Date1 = new Date(response.data.createdAt).toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );
        setFormattedDate(Date1);

        let cart = response.data.cart.map((item) => {
          if (Array.isArray(attributesResponse.data) && item.variant) {
            attributesResponse.data.forEach((data) => {
              data.variants.forEach((id) => {
                if (id._id === item.variant[Object.keys(item.variant)[0]]) {
                  item.variant["variantName"] = id.name.en;
                }
              });
            });
          } else {
            item.variant = { variantName: "N/A" };
          }

          return item;
        });

        response.data["cart"] = cart;
        setOrder(response.data);
      } catch (error) {
        console.log(error);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };

    window.scrollTo(0, 0);
    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;



  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };

  return (
    <>
      <div className="bg-white ">
        <div className="bg-white p-7  mt-8">
          <h2 className="text-lg font-semibold mb-4">Tracking Order Lists</h2>
          <hr className="border-t-2 border-dashed border-0 border-slate-200" />

          <div className="relative flex justify-between items-center w-full mt-6">
            <div className="tracking-line"></div>

            <div className="relative flex justify-center mt-5 tracking-steps">
              <div className="relative p-4 flex-col tracking-text items-center text-center">
                <div
                  className={`flex items-center tracking-icon1 justify-center w-16 h-16 ${getOrderStatusClass(
                    "Pending",
                    orderStatus
                  )} rounded-full text-white ml-3`}
                >
                  {getOrderStatusIcon("Pending")}
                </div>
                <div className="mt-2 text-black truncate font-semibold tracking-text1">
                  {getStatusText("Pending")}
                </div>
              </div>

              <div className="relative p-4 text-center flex-col tracking-text items-center">
                <div
                  className={`flex items-center tracking-icon2 justify-center w-16 h-16 ${getOrderStatusClass(
                    "Processing",
                    orderStatus
                  )} rounded-full text-white ml-9`}
                >
                  {getOrderStatusIcon("Processing")}
                </div>
                <div className="mt-2 text-black truncate   font-semibold tracking-text2">
                  {getStatusText("Processing")}
                </div>
              </div>

              <div className="relative p-4 text-center flex-col tracking-text items-center">
                <div
                  className={`flex items-center tracking-icon3 justify-center w-16 h-16 ${getOrderStatusClass(
                    "Shipped",
                    orderStatus
                  )} rounded-full text-white ml-4`}
                >
                  {getOrderStatusIcon("Shipped")}
                </div>
                <div className="mt-2 text-black truncate  font-semibold tracking-text3">
                  {getStatusText("Shipped")}
                </div>
              </div>

              <div className="flex flex-col items-center">
                {/* Conditionally render the 'Delivered' status div */}
                {orderStatus === "Delivered" && (
                  <div className="relative p-4 text-center flex-col tracking-text items-center">
                    <div
                      className={`flex items-center tracking-icon4 justify-center w-16 h-16 ${getOrderStatusClass(
                        "Delivered",
                        orderStatus
                      )} rounded-full text-white mlr-6`}
                    >
                      {getOrderStatusIcon("Delivered")}
                    </div>
                    <div className="mt-2 text-black truncate  font-semibold tracking-text4">
                      {getStatusText("Delivered")}
                    </div>
                  </div>
                )}

                {/* Conditionally render the 'Cancel' status div */}
                {orderStatus === "Cancel" && (
                  <div className="relative p-4 text-center flex-col tracking-text items-center">
                    <div
                      className={`flex items-center tracking-icon5 justify-center w-16 h-16 ${getOrderStatusClass(
                        "Cancel",
                        orderStatus
                      )} rounded-full text-white ml-2`}
                    >
                      {getOrderStatusIcon("Cancel")}
                    </div>
                    <div className="mt-2 text-black truncate text-center font-semibold tracking-text5">
                      {getStatusText("Cancel")}
                    </div>
                  </div>
                )}

                {orderStatus !== "Delivered" && orderStatus !== "Cancel" && (
                  <div className="relative p-4 text-center flex-col tracking-text items-center">
                    <div
                      className={`flex items-center tracking-icon6 justify-center w-16 h-16 bg-slate-300 rounded-full text-white ml-5`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6l4 2"
                        />
                      </svg>
                    </div>
                    <div className="mt-2 text-black truncate  font-semibold tracking-text6">
                      Pending
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 shadow-md mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Order Id : {order.invoice}
          </h2>

          <div className="mb-4 flex justify-between bg-white border-2 border-slate-100 gap-3 border-solid p-5 tracking-order">
            <div className="flex flex-col justify-between items-center p-10 bg-slate-100 tracking-order-box">
              <div className="truncate">Order date:</div>
              <div className="truncate">{formattedDate || 0}</div>
            </div>

            <div className="flex flex-col justify-between items-center p-10 bg-slate-100 tracking-order-box">
              <div className="truncate">Status: </div>
              <div className="truncate">{order.status}</div>
            </div>
          </div>

          <div className="responsive-table-container">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-slate-100 whitespace-nowrap">
                  <th className="border p-2">Product</th>
                  <th className="border p-2">Variant</th>
                  <th className="border p-2">Original Price</th>
                  <th className="border p-2">Discount</th>
                  <th className="border p-2">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {order.cart.map((item) => (
                  <tr key={item._id}>
                    <td
                      className="border border-white p-2 flex items-center space-x-2 text-sm cursor-pointer"
                      onClick={() => handleProductClick(item._id)}
                    >
                      <img
                        src={item.image[0]}
                        alt={item.title}
                        className="w-10 h-10"
                      />
                      <div className="flex flex-col justify-start">
                        <div>{item.title}</div>
                        <div className="mr-48"><StarRating rating={item.rating}/></div>
                      </div>
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      {" "}
                      {item.variant?.variantName || "N/A"}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                    {item.price}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      ₹{(item.prices?.discount || 0).toFixed(2)}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                    ₹{(parseFloat(item.price.replace('₹', '')) * item.quantity).toFixed(2) || item.totalPrice || "0.00"}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="border p-2 text-sm text-left">Sub Total:</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border p-2 text-sm  text-center">
                    ₹{order.subTotal.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="border p-2 text-sm text-left">
                    Total Discount:
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border p-2 text-sm  text-center">
                    ₹{order.discount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="border p-2 text-sm text-left">
                    Shipping Charge:
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border p-2 text-sm  text-center">
                    ₹{order.shippingCost.toFixed(2)}
                  </td>
                </tr>
                <tr className="bg-slate-100">
                  <td className="border p-2 text-sm text-left">Total:</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border p-2 text-sm  text-center">
                    ₹{order.total.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>
      <div className="fixed z-10 w-full bottom-0">
        <TabletNavbar />
      </div>
    </>
  );
}

export default TrackingStatus;
