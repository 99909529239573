import {useEffect} from 'react'
import ProfileBanner from './ProfileBanner'
import Header from '../header/header'
import Navbar from '../navbar/navbar'
import ProfileDetails from './ProfileDetails'
import ProfileDashborde from './ProfileDashborde'
import ProfileOrderList from './ProfileOrderList'
import Footer from '../Footer/Footer'
import TabletNavbar from '../navbar/TabletNavbar'


const ProfileShow = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
   <div className='bg-slate-100'>

    <Header/>
    <Navbar/>
  
    <div className='profile-full-container flex justify-between gap-5 p-7'>

        <div className=''>
        <ProfileDetails/>

        </div>
        <div className='order-list-parent'>
       
          <ProfileBanner/>
          <ProfileDashborde/>
       
        <ProfileOrderList/>
        </div> 

    </div> 
    <Footer/>
    </div>

    <div className="md:hidden h-[3.6rem] sm:h-16">

   </div> 
    <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    
      
    </>
  )
}

export default ProfileShow
