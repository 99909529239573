import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBGvgncZstUzHo-pPBVTfrjcZOMeYOC2nM",
    authDomain: "nile-mile.firebaseapp.com",
    projectId: "nile-mile",
    storageBucket: "nile-mile.appspot.com",
    messagingSenderId: "43985635174",
    appId: "1:43985635174:web:c8950ceb8793ffcb522aa7"
  };

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
const db = firebaseApp.firestore();
export { db ,auth};