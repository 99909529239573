import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Product3 from "../../assets/images/product3.svg";
import Product4 from "../../assets/images/product4.svg";
import LeftArrow from "../../assets/images/BtnL.png";
import RightArrow from "../../assets/images/BtnR.png";
import Backcover from "../../assets/images/Backcover.svg";
import axios from "axios";
import SaleService from "../Service/SaleService";

const Summmer = ({ apiProducts }) => {
  const [time, setTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isTimerVisible, setIsTimerVisible] = useState(true);
  const [products, setProducts] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const navigate = useNavigate();

  // Fetch products from API
  useEffect(() => {
    // axios
    //   .get("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products")
    //   .then((response) => {
    //     if (response.data && response.data.products) {
    //       setProducts(response.data.products);
    //     } else {
    //       console.error("Unexpected response format", response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching products:", error);
    //   });
    setProducts(apiProducts);
  }, [apiProducts]);

  const id = "66cd87e2460322de07bd86f4"

  // Fetch sale data
  useEffect(() => {
  SaleService.fetchSaleData(id)
      .then((response) => response.data)
      .then((data) => {
        setSaleData(data);
        if (data.status === "show") {
          setIsTimerVisible(true);
          const endDate = new Date(data.endDate);
          const calculateTimeRemaining = () => {
            const now = new Date();
            const difference = endDate - now;
            return Math.max(0, Math.floor(difference / 1000));
          };
          setTime(calculateTimeRemaining());
        } else {
          setIsTimerVisible(false);
        }
      })
      .catch((error) => console.error("Error fetching sale data:", error));
  }, []);

  // Update timer every second
  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  // Update items per page based on screen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1149) {
        setItemsPerPage(2);
      } 
      else if (window.innerWidth <= 1550) {
        setItemsPerPage(3);
      } 
      else {
        setItemsPerPage(4);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const SeasonSaleProduct = products.filter((product) => {
    if (product.status !== "show") {
      return false;
    }
    let tags = [];
    try {
      if (Array.isArray(product.tag)) {
        tags = tags.concat(product.tag);
      } else {
        console.error("Parsed tag is not an array:");
      }
    } catch (e) {
      console.error("Error parsing tags:", e);
      return false;
    }
    return tags.includes("Season Sale Products");
  });

  // Format time for display
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hrs = Math.floor((seconds % 86400) / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return { days, hours: hrs, minutes: mins, seconds: secs };
  };

  const { days, hours, minutes, seconds } = formatTime(time);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, SeasonSaleProduct.length - itemsPerPage)
    );
  };

  const visibleItems = SeasonSaleProduct.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );
  const handleViewMoreClick = () => {
    navigate("/product", { state: { section: "Season Sale Products" } });
  };

  return (
    <div
      className="summer-container-main w-auto mt-14"
      style={{ backgroundImage: `url(${Backcover})`, minHeight: "280px" }}
    >
      <div className="summer-container flex justify-between pb-24 pt-24 ">
        <div className="flex-col gap-3 text-left ml-11 mt-16">
          {saleData && (
            <div className="flex flex-col gap-2">
              <div className="font-bold text-5xl text-black">
                {saleData.title}
              </div>
              <div className="text-5xl text-left text-secondary-color font-bold">
                {saleData.subtitle}
              </div>
              <div className="text-sm text-gray-500">
                {saleData.description}
              </div>
            </div>
          )}

          {isTimerVisible &&
            (time > 0 ? (
              <div className="text-sm font-semibold pt-5">
                Time Remaining
                <div className="text-lg font-bold flex gap-2 text-center py-1 border-2 pt-3">
                  <div className="bg-secondary-color text-5xl rounded text-white p-2">
                    {days.toString().padStart(2, "0")}
                    <div className="text-xs">Days</div>
                  </div>
                  <div className="bg-secondary-color text-5xl rounded text-white p-2">
                    {hours.toString().padStart(2, "0")}
                    <div className="text-xs">Hours</div>
                  </div>
                  <div className="bg-secondary-color text-5xl rounded text-white p-2">
                    {minutes.toString().padStart(2, "0")}
                    <div className="text-xs">Minutes</div>
                  </div>
                  <div className="bg-secondary-color text-5xl rounded text-white p-2">
                    {seconds.toString().padStart(2, "0")}
                    <div className="text-xs">Seconds</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-secondary-color text-[25px] font-bold pt-5">
                This Deal is Over
              </div>
            ))}

          <div className="mt-5">
            <button
              onClick={handleViewMoreClick}
              className="relative border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-soft-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color"
            >
              View more Products
            </button>
          </div>
        </div>

        <div className="summer-product flex flex-col items-center">
          <div className="flex justify-center ml-auto gap-2 mb-4">
            <img
              className="cursor-pointer"
              onClick={handlePrevClick}
              src={LeftArrow}
              alt="Left Arrow"
            />
            <img src={RightArrow} onClick={handleNextClick} alt="Right Arrow" />
          </div>

          <div className="flex gap-5 mb-2 ">
            {visibleItems.map((list) => (
              <div
                key={list._id}
                className="summer-product-box bg-white  w-60 rounded-md text-center p-2">
                <div>
                  <img
                    className="w-48 rounded-md"
                    src={list.image[0]}
                    alt={list.title.en}
                  />
                </div>
                <div
                  className="text-sm font-bold cursor-pointer line-clamp-3"
                  onClick={() => navigate(`/product/${list._id}`)}
                >
                  {list.title.en}
                </div>
                <div className="text-secondary-color font-semibold mt-2">
                  ₹{list.prices.price}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summmer;
