import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const CouponService = {

fetchAllCoupon:()=>{
    return axios.get(`${baseURL}/coupon/`)
}
,
 fetchUseUIdCoupon: (currentCouponId, userId) => {
    return axios.get(`${baseURL}/coupon/get-applied?couponId=${currentCouponId}&userId=${userId}`);
}
,
 fetchCouponUpdate: (couponObj) => {
    return axios.post(`${baseURL}/coupon/update-apply`,couponObj);
}
  
  

}

export default CouponService