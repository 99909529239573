import React from 'react';
import Header from '../header/header';
import Navbar from '../navbar/navbar';


import Footer from '../Footer/Footer';
import TabletNavbar from '../navbar/TabletNavbar';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/Aboutus.css"
import { Carousel } from 'react-responsive-carousel';
import { ToastContainer } from 'react-toastify';
import { ecomAddress, ecomEmailAbout, ecomfounder, ecomNumber, getAbout, titleName } from '../Container/Images';

const AboutUs = () => {

const aboutImg = getAbout()

  return (
    <>
      <div className="fixed z-10 w-full top-0">
      <ToastContainer/>

        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>
      <div className="relative mt-16 md:mt-24 h-[500px] back-ground-img" style={{ backgroundImage: `url(${aboutImg.Backcover})`}}>
       
        <div className='About-container'>
          <div className='grid grid-cols-1 tablet-md:grid-cols-2 gap-8'>
            <div className='text-center md:text-left '>
              <div className='text-[60px] font-bold mb-4 '>About Us</div>
              <p className='text-lg leading-relaxed'>
              Our new brand, Nile and Mile, is
              a pet store where we offer pet
              grooming services and sell a
              range of pet products, including
              food, treats, and accessories.
              </p>
            </div>
            <div className='flex justify-center'>
            <Carousel
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                showStatus={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const indicatorClass = isSelected ? "bg-white" : "bg-gray-300";
                  return (
                    <button
                      type="button"
                      key={index}
                      aria-label={`Slide ${index + 1}`}
                      onClick={onClickHandler}
                      className={`w-3 h-3 rounded-full ${indicatorClass} mx-1`}
                    />
                  );
                }}
              >
                <div>
                  <img src={aboutImg.Image1} alt="About Us Image 1" className='md:h-80 h-48 rounded shadow-lg'/>
                </div>
                <div>
                  <img src={aboutImg.Image2} alt="About Us Image 2" className='md:h-80 h-48 rounded shadow-lg'/>
                </div>
                <div>
                  <img src={aboutImg.Image3} alt="About Us Image 3" className='md:h-80 h-48 rounded shadow-lg'/>
                </div>
                <div>
                  <img src={aboutImg.Image2} alt="About Us Image 4" className='md:h-80 h-48 rounded shadow-lg'/>
                </div>
                <div>
                  <img src={aboutImg.Image1} alt="About Us Image 5" className='md:h-80 h-48 rounded shadow-lg'/>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="About-Subcontainer px-4 py-8">
  <div className="grid grid-cols-1 tablet-md:grid-cols-2 gap-8">
    <div className="flex justify-center">
      <img src={aboutImg.Image2} alt="About Us Image 2" className="md:h-80 h-48 rounded shadow-lg duration-300 hover:translate-y-6"/>
    </div>
    <div className="space-y-4">
      <div className="text-3xl md:text-[40px] font-bold whitespace-nowrap">{ecomfounder}</div>
      <div className="text-xl md:text-[25px]  ">Managing Director at Orion Nebula Lifestyle</div>
      
      <p className="flex items-center text-lg leading-relaxed">
        <img src={aboutImg.phoneicon} className="h-5 w-5 bg-white rounded mr-2" alt="Phone Icon"/> 
        {ecomNumber}
      </p>
      
      <p className="flex items-center text-lg leading-relaxed">
        <img src={aboutImg.aticonicon} className="h-5 w-5 bg-white rounded mr-2" alt="Email Icon"/> 
        {ecomEmailAbout}
      </p>
      
      <p className="flex items-center text-lg leading-relaxed">
        <img src={aboutImg.locationicon} className="h-5 w-5 bg-white rounded mr-2" alt="Address Icon"/> 
        {ecomAddress}
      </p>
    </div>
  </div>
</div>


      <div className='About-Subcontainer'>
        
          <div className='grid grid-cols-1 tablet-md:grid-cols-2 gap-8'>
            <div className='order-1 flex justify-center tablet-md:order-2  cursor-pointer'>
              <img src={aboutImg.Image3} alt="About Us Image 3" className='md:h-80 h-48 rounded shadow-lg duration-300 hover:translate-y-6  '/>
            </div>
            <div className='order-2 tablet-md:order-1'>
              <div className='text-3xl md:text-[25px] font-bold mb-4 '>ROOT CAUSE FOR THIS IDEA</div>
              <p className='text-lg leading-relaxed'>
              {titleName} is a brand created with the goal of raising money. The idea is to sell products or services, and then use a part of the earnings to support non-governmental organizations
              (NGOs) and animal shelters. This means that
              while you're building and growing your brand,
              you're also contributing to causes that help
              animals in need.
              </p>
            </div>
      
        </div>
      </div>

      <div className="About-Subcontainer px-4 py-8">
  <div className="grid grid-cols-1 tablet-md:grid-cols-2 gap-8">
    <div className="flex justify-center">
      <img src={aboutImg.Image2} alt="About Us Image 2" className="md:h-80 h-48 rounded shadow-lg duration-300 hover:translate-y-6"/>
    </div>
    <div>
      <div className="text-3xl md:text-[25px] font-bold mb-4 whitespace-nowrap">ONGOING BUSINESS</div>
      <ul className="list-disc list-inside  text-lg leading-relaxed">
        <li><strong>IFB Showrooms:</strong> As a dealer for IFB, I manage the distribution and sales of home appliances and kitchen solutions, ensuring top-quality products and customer service.</li>
        <li><strong>Crompton Showrooms:</strong> I handle a variety of electrical products for Crompton, including fans, lighting, and appliances, focusing on excellent customer service.</li>
        <li><strong>Signed LG: </strong> Additionally, I am a distributor for brands like Filo Milo, Signature, and AR Industries toys.</li>
      </ul>
    </div>
  </div>
</div>


      <Footer/>
      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    </>
  );
}

export default AboutUs;
