import productLogo from "../../assets/images/Logo.png"
import titleBanner from '../../assets/images/ProductBanner.svg';
import incrementicon from '../../assets/images/incrementicon.svg';
import decrementicon from '../../assets/images/decrementicon.svg';
import deleteicon from '../../assets/images/deleteicon.svg';

import Facebookicon from "../../assets/images/faceicon.svg";
import Whatsappicon from "../../assets/images/whatsapp.svg";
import InlickedIcon from "../../assets/images/inlinkicon.svg";
import GoogleIcon from "../../assets/images/googleicon.svg";
import FooterSearchIcon from "../../assets/images/FootersearchIcon.svg";

import PhoneIcon from "../../assets/images/Phoneicon.svg";
import Emailicon from "../../assets/images/Emailicon.svg";
import ContactIcon from "../../assets/images/ContactIcon.svg";

import FooterBgimage1 from "../../assets/images/Footerbg.svg";
import FooterBgimage2 from "../../assets/images/Footerback.jpeg";



import FeedBgImage from "../../assets/images/Feedback.svg";
import FeedBackFlagIcon from "../../assets/images/Flag.svg";
import SymbolIcon from "../../assets/images/Symbol.svg";


import Backcover from "../../assets/images/Backcover.svg";
import Image1 from "../../assets/images/Aboutimg1.jpg";
import Image2 from "../../assets/images/Aboutimg2.jpg";
import Image3 from "../../assets/images/Aboutimg3.jpg";
import phoneicon from "../../assets/images/phone_icon/phoneicon.svg"
import aticonicon from "../../assets/images/phone_icon/aticon.svg"
import locationicon from "../../assets/images/phone_icon/locationicon.svg"

import Free from "../../assets/images/Free.svg";
import Return from "../../assets/images/Return.svg";
import Payment from "../../assets/images/Payment.svg";
import Certified from "../../assets/images/Certified.svg";

import Term from "../../assets/images/Profile_Icon/term.svg";
import Policy from "../../assets/images/Profile_Icon/policy.svg";
import Manage from "../../assets/images/Profile_Icon/manage.svg";
import Shopping from "../../assets/images/Profile_Icon/shopping_cart.svg";
import support from "../../assets/images/Profile_Icon/support.svg";
import favorite from "../../assets/images/Profile_Icon/favorite.svg";

import Dashicon from "../../assets/images/Dashicon.svg";


import arrows from "../../assets/images/Overlay.svg"

import arrowsshow from "../../assets/images/arrows/arrowsshow.png"

import arror_up from "../../assets/images/arror-up.svg";
import arror_rigth from "../../assets/images/arror-rigth.svg";
import arror_down from "../../assets/images/arror-down.svg";


export const Logo = productLogo ;

export const TitleBanner = titleBanner ;

export const IncrementIcon = incrementicon ;

export const DecrementIcon = decrementicon ;

export const  DeleteIcon = deleteicon;

export const ArrowsIcon = arrows; 
export const ArrowsIconShow = arrowsshow; 

export const ArrowsIconUp = arror_up; 
export const ArrowsIconRigth = arror_rigth; 
export const ArrowsIconDown = arror_down; 


// *************** Start Footer Container Images ***************   Policy,Manage,Shopping,support,favorite


export const getFooter = () => {
    return {
        Facebookicon,
        Whatsappicon,
        InlickedIcon,
        GoogleIcon,

        FooterSearchIcon,
        FooterBgimage1,
        FooterBgimage2
      
    };
  };

  export const getFooterContact = () => {
    return {
        PhoneIcon,
        Emailicon,
        ContactIcon
    };
  };


// *************** End Footer Container Images ***************



// *************** Start FeedBack Container Images ***************


export const getFeedBack = () => {
  return {
    FeedBgImage,
    FeedBackFlagIcon,
    SymbolIcon,


    
  };
};



// *************** End FeedBack Container Images ***************


// *************** Start Section Container Images ***************


export const getSection = () => {
  return {
    Free,
    Return,
    Payment,
    Certified



    
  };
};



// *************** End Section Container Images ***************

// *************** Start About Container Images ***************


export const getAbout = () => {
  return {
    Backcover,
    Image1,
    Image2,
    Image3,
    aticonicon,
    phoneicon,
    locationicon,
  };
};



// *************** End About Container Images ***************

// *************** Start Profile Container Images ***************


export const getProfile = () => {
  return {
    Policy,
    Manage,
    Shopping,
    support,
    favorite,
    Term
  };
};



// *************** End Profile Container Images *************** Dashicon


// *************** Start Profile Dashbord Container Images ***************


export const getProfileDashbord = () => {
  return {
    Dashicon,
  };
};



// *************** End Profile Dashbord Container Images ***************



  export const  titleName = "Nile & Mile";
  export const  ecomfounder = "DURGA JANANI";
  export const  ecomNumber = "+91 98409 41033";
  export const  ecomEmail = "nilemilechennai@gmail.com";
  export const  ecomEmailAbout = "janaisen@gmail.com";
  export const  ecomAddress = "No 40, Harrington road, chetpet , Chennai 600031.";
