import React, { useState, useEffect, useContext } from "react";

import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Product3 from "../../assets/images/product3.svg";
import Product4 from "../../assets/images/product4.svg";

import Brand1 from "../../assets/images/Brand1.svg";
import Brand2 from "../../assets/images/Brand2.svg";
import Brand3 from "../../assets/images/Brand3.svg";
import Brand4 from "../../assets/images/Brand4.svg";
import Brand5 from "../../assets/images/Brand5.svg";
import Brand6 from "../../assets/images/Brand6.svg";

import Cart1 from "../../assets/images/heartcart.svg";
import Cart2 from "../../assets/images/shoppingcart.svg";

import Banner3 from "../../assets/images/Banner3.svg";
import Banner4 from "../../assets/images/Banner4.svg";

import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Brandlist from "../Product/Brandlist";

import Products from "../../Model";

import LeftArrow from "../../assets/images/BtnL.png";
import RightArrow from "../../assets/images/BtnR.png";
import BestSellingProducts from "./BestSellingProducts";
import FeaturedProducts from "./FeaturedProducts";
import BestDogProducts from "./BestDogProducts";
import axios from "axios";
import FlexBannerService from "../Service/FlexBannerService";

const Productlist = ({ apiProducts }) => {
  const { addToCart } = useContext(CartContext);
  const { addToFavorites, isInFavorites, removeFromFavorites } =
    useContext(FavoriteContext);

  const navigate = useNavigate();

  const [flex3, setFlext3] = useState(null);
  const [flex4, setFlext4] = useState(null);

  useEffect(() => {
FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const flex_3 = response.data.find(
            (item) => item.position === "Flex 3"
          );
          const flex_4 = response.data.find(
            (item) => item.position === "Flex 4"
          );
          setFlext3(flex_3);
          setFlext4(flex_4);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  const handleImageClick = () => {
    if (flex3 && flex3.link) {
      window.open(flex3.link, "_blank").focus();
    }
    if (flex4 && flex4.link) {
      window.open(flex4.link, "_blank").focus();
    }
  };

  const [quantity, setQuantity] = useState(1);
  //const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const handleAddToCart = (product) => {
    const defaultVariant = product.variants[0]; // Assuming the first variant is the default
    const productWithDetails = {
      ...product,
      variant: defaultVariant.name,
      price: defaultVariant.price || defaultVariant.offerPrice, // Use offerPrice if available
      quantity: quantity,
    };
    addToCart(productWithDetails);
    toast.success("Product added to cart", {
      position: "top-right",
      theme: "colored",
      autoClose: 1000,
    });
  };

  const handleAddToFavorites = (product) => {
    const defaultVariant = product.variants[0];
    const productWithDetails = {
      ...product,
      variant: defaultVariant.name,
      price: defaultVariant.offerPrice,
      discountPrice: defaultVariant.offerPrice,
    };

    if (isInFavorites(product.id)) {
      removeFromFavorites(product.id);
      toast.info("Product removed from favorites", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    } else {
      addToFavorites(productWithDetails);
      toast.success("Product added to favorites", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    }
  };


  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };
  const handleViewMoreClick = () => {
    navigate("/product");
  };
  const [hoveredProductId, setHoveredProductId] = useState(null);

  const handleMouseEnter = (productId) => {
    setHoveredProductId(productId);
  };

  const handleMouseLeave = () => {
    setHoveredProductId(null);
  };

  return (
    <>
      <div className="productlist-full-container font-sans">
        {/* Best Selling Products */}
        <BestSellingProducts apiProducts={apiProducts} />

        {/* Featured Products */}
        <FeaturedProducts apiProducts={apiProducts} />

        {/* Best of Dog Products */}
        <BestDogProducts apiProducts={apiProducts} />

        {/* Banner-flex */}

        <div className="Banner-flex2  flex justify-self-center gap-3 ml-25 pt-16  pb-12 ">
          <div>
            {flex3 && flex3.imageUrl && (
              <img
                src={flex3.imageUrl}
                alt=""
                className="w-full"
                onClick={handleImageClick}
              />
            )}
            {/* <img src={Banner3} alt="" className='w-full' /> */}
          </div>
          <div>
            {flex4 && flex4.imageUrl && (
              <img
                src={flex4.imageUrl}
                alt=""
                className="w-full"
                onClick={handleImageClick}
              />
            )}
            {/* <img src={Banner4} alt="" className='w-full' /> */}
          </div>
        </div>

        {/* Top Brands*/}

        <Brandlist />
      </div>
    </>
  );
};

export default Productlist;
