import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const AddressService = {
    fetchUidAddress: (uid) => {
      return axios.get(`${baseURL}/address/${uid}`);
    },
  
    fetchUidAddressDelete: (uid, addressToDelete) => {
      return axios.delete(`${baseURL}/address/${uid}/${addressToDelete}`);
    },
  
    fetchUidAddressUpdate: (uid, addressToUpdate, addressData) => {
      return axios.put(`${baseURL}/address/${uid}/${addressToUpdate}`, addressData);
    },
  
    fetchUidAddressCreate: (uid, addressData) => {
      return axios.post(`${baseURL}/address/`, { uid, ...addressData });
    }
  };
  
  export default AddressService;
  