import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getProfile, Logo, titleName } from "../Container/Images";

const ProfileDetails = () => {
  const navigate = useNavigate();

  const profileIcon = getProfile()

  const [uid, setUid] = useState(null);
  const [phone, setPhone] = useState(null);

  // const storedUid = localStorage.getItem('uid');
  const storedPhone = localStorage.getItem("phoneNumber");

  const handleLogout = async () => {
    try {
      const uid = localStorage.getItem("uid");

      // if (uid) {
      //   // Clear user-related data from the server if needed
      //   await axios.post('https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/favorite/clear', { uid });

      //   // Optionally, you might want to clear the cart from the server as well
      //   // await axios.post('https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/cart/clear', { uid });
      // }

      // Clear local storage
      localStorage.clear();

      // Clear session storage
      sessionStorage.clear();
      navigate("/");
      window.location.reload(); // Optionally, reload the page to ensure all changes are applied
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      <div className="profile-details bg-white rounded-md w-60 h-96 p-7 flex flex-col justify-between">
        <div className="flex items-center space-x-4">
          <img src={Logo} alt="Logo" className="h-10 w-10 cursor-pointer" />
          <div className="flex flex-col gap-1">
            <div>{titleName} User</div>
            <div>+ {storedPhone}</div>
          </div>
        </div>

        <div className="flex flex-col space-y-4 mt-8">
          <div
            className="flex items-center space-x-3"
            onClick={() => navigate("/favorites")}
          >
            <img src={profileIcon.favorite} alt="Favorite" className="h-5 w-5  " />
            <div className="hover:text-primary-color cursor-pointer">Wishlist</div>
          </div>
          <div
            className="flex items-center space-x-3"
            onClick={() => navigate("/cart")}
          >
            <img src={profileIcon.Shopping} alt="Cart" className="h-5 w-5" />
            <div className="hover:text-primary-color cursor-pointer">Cart</div>
          </div>
          <div
            className="flex items-center space-x-3"
            onClick={() => navigate("/Manageprofile")}
          >
            <img src={profileIcon.Manage} alt="Manage Profile" className="h-5 w-5" />
            <div className="hover:text-primary-color cursor-pointer">
              Manage Profile
            </div>
          </div>
          <div
            className="flex items-center space-x-3"
            onClick={() => navigate("/Termsandconditions")}
          >
            <img src={profileIcon.Term} alt="Terms And Conditions" className="h-5 w-5" />
            <div className="hover:text-primary-color cursor-pointer">
              Terms And Conditions
            </div>
          </div>
          <div
            className="flex items-center space-x-3"
            onClick={() => navigate("/Privacypolicy")}
          >
            <img src={profileIcon.Policy} alt="Privacy Policy" className="h-5 w-5" />
            <div className="hover:text-primary-color cursor-pointer">
              Privacy Policy
            </div>
          </div>
          {/* <div className='flex items-center space-x-3' onClick={() => navigate('/')}>
      <img src={support} alt="Support" className='h-5 w-5' />
      <div className='hover:text-primary-color cursor-pointer'>Support</div>
    </div> */}
        </div>

        <div
          className="text-center mt-8 hover:text-primary-color cursor-pointer"
          onClick={handleLogout}
        >
          Log out
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
