import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import cancel from "../../assets/images/phone_icon/cancle.svg";
import axios from "axios";
import { toast } from "react-toastify";
import ProductService from "../Service/ProductService";

const ReviewModal = ({
  show,
  handleClose,
  handleAddReview,
  productId,
  userId,
}) => {
  const [newReview, setNewReview] = useState("");
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [userName, setUserName] = useState("");

  // useEffect(() => {
  //   if (userId) {
  //     const fetchUserName = async () => {
  //       try {
  //         const response = await axios.get(`https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/customer/${userId}`);

  //         setUserName(response.data.name || '');
  //       } catch (err) {
  //         console.error('Error fetching user data:', err);
  //       }
  //     };
  //     fetchUserName();
  //   }
  // }, [userId]);

  if (!show) {
    return null;
  }

  const uid = localStorage.getItem("uid");

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {

      const reviewData = {
        uid,                
        productId,       
        rating,            
        comment: newReview, 
        verifiedPurchase: true,  
      };

      const response = await ProductService.fetchProductReviewAdd(reviewData)

      handleAddReview(response.data);
      handleClose();
      toast.success("Successfully added Your Review", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    } catch (err) {
      console.error("Error submitting review:", err);
      toast.error("Failed to submit review. Please try again.", {
        position: "top-right",
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8 w-1/2 relative">
        <div
          className="absolute top-2 right-2 p-3 rounded-full cursor-pointer"
          onClick={handleClose}
        >
          <img src={cancel} className="h-7 w-7" alt="Cancel" />
        </div>
        <h2 className="text-2xl mb-4">Add Review</h2>
        <p className="text-lg mb-4">
          Reviewing as: <strong>{userName}</strong>
        </p>
        <div className="flex items-center mb-4">
          {[...Array(5)].map((_, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <input
                  type="radio"
                  name="rating"
                  className="hidden"
                  value={ratingValue}
                  onClick={() => setRating(ratingValue)}
                />
                <FaStar
                  className={`cursor-pointer ${
                    ratingValue <= (hover || rating)
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                  size={24}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
        <textarea
          className="w-full p-2 border rounded mb-4"
          placeholder="Your review"
          value={newReview}
          onChange={(e) => setNewReview(e.target.value)}
        />
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-primary-color text-white border-none rounded-lg"
            onClick={handleReviewSubmit}
          >
            Submit Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
