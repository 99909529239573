import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const SubscribeService ={

    PostEmail:(email)=>{
return axios.post(`${baseURL}/subscribe`,{mail : email})
    }

}

export default SubscribeService