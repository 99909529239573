import React, { useEffect, useState } from "react";
import ProdBanner from "../../assets/images/ProductBanner.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import TabletNavbar from "../navbar/TabletNavbar";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import { baseURL } from "../api";

const BrandAll = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const getBrand = async () => {
      try {
        const response = await axios.get(`${baseURL}/brand/`);
        console.log("Brands fetched:", response.data);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    getBrand();
    window.scrollTo(0, 0);
  }, []);

  const handleBrandClick = (brandName) => {
    navigate("/product", { state: { brand: brandName } });
  };

  const handleProductAll = () => {
    navigate("/product");
  };

  return (
    <div>
      <div className="fixed z-10 w-full top-0">
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>

      <div className="pt-[108px]">
        <div
          className="flex-col justify-center relative"
          style={{
            backgroundImage: `url(${ProdBanner})`,
            minHeight: "250px",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative flex flex-col gap-3 items-center justify-center h-full pt-28">
            <div className="text-3xl font-poppins text-white font-bold">
              All Brand
            </div>
            <div className="text-base font-poppins text-white">
              Home / All Brand
            </div>
          </div>
        </div>

    <div className=" w-full">
      {/* <div className="justify-between flex">
        <div className="flex-col mb-7">
          <div className="font-bold text-5xl">Top Brands</div>
          <div className="text-sm ">Shop By Brands</div>
        </div>
        <div className="flex">
          <button
            className="relative border-none h-11  rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color whitespace-nowrap"
            onClick={handleProductAll}
          >
            View More
          </button>
        </div>
      </div> */}
      <div className="top-brand-all flex  ">
        {brands.map((brand, index) => (
          <div
            key={index}
            className="brand-box-all cursor-pointer flex items-center p-1 mb-4 bg-white rounded-full shadow-lg transition-all duration-300 hover:shadow-xl"
            onClick={() => handleBrandClick(brand.name)}
          >
            <img
              src={brand.image}
              alt={brand.name}
              className="h-12 w-12 mr-4 rounded-full"
            />
            <div>
              <div className="font-bold cursor-pointer">{brand.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>

    </div>

      <Footer />
      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    </div>
  );
};

export default BrandAll;
