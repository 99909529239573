import './App.css';
import "../src/assets/css/Section.css"
import "../src/assets/css/Todaydeal.css"
import "../src/assets/css/Newarrival.css"
import "../src/assets/css/Summersale.css"
import "../src/assets/css/Premium.css"
import "../src/assets/css/Footer.css"
import "../src/assets/css/Header.css"
import "../src/assets/css/Flexbanner.css"
import "../src/assets/css/Serachbar.css"
import "../src/assets/css/Topcategory.css"
import "../src/assets/css/Topbrand.css"
import "../src/assets/css/Phonemenu.css"
import "../src/assets/css/Feedback.css"
import "../src/assets/css/Login.css"
import "../src/assets/css/Checkout.css"


import "../src/assets/css/Productall.css"
import "../src/assets/css/Month.css"

import "../src/assets/css/productview.css"

import "../src/assets/css/Profile.css"

import "../src/assets/css/Tracking.css"

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import ProductShow from './components/Product/ProductShow';
import { CartProvider } from './components/header/CartContext';
import { FavoriteProvider } from './components/header/FavoriteContext'; 
import FavoriteList from './components/usercart/FavoriteList';
import CartItems from './components/usercart/CartItems';
import Productview from "./components/Product/Productview";
import CheckShow from './components/Checkout/CheckShow';
import ProfileShow from './components/Profile/ProfileShow';
import PrivacyPolicy from './components/Profile/PrivacyPolicy';
import TermsAndConditions from './components/Profile/TermsAndConditions';
import { ManageProfile } from './components/Profile/ManageProfile';
import TrackingShow from './components/Profile/TrackingShow';
import OrderDetails from './components/Profile/OrderDetails';
import AboutUs from './components/AboutUs/AboutUs';
import WhatsappButton from './components/contact/WhatsappButton';
import BrandAll from './components/Product/BrandAll';
<meta name="viewport" content="width=device-width, initial-scale=1.0" />

function App() {
  return (
    <div className='font-poppins bg-slate-100 '>
      <FavoriteProvider> 
        <CartProvider> 
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product" element={<ProductShow />} />
             
              <Route path="/profile" element={<ProfileShow />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/favorites" element={<FavoriteList />} />
              <Route path="/cart" element={<CartItems />} />
              <Route path="/checkout" element={<CheckShow />} />
              <Route path="/product/:id" element={<Productview />} />
              <Route path="/Privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/Termsandconditions" element={<TermsAndConditions />} />
              <Route path="/Manageprofile" element={<ManageProfile />} />

              <Route path="/tracking" element={<TrackingShow />} />
              <Route path="/orderdetails" element={<OrderDetails/>} />
              <Route path="/brandall" element={<BrandAll/>} />


            </Routes>
          </Router>
        </CartProvider>
      </FavoriteProvider>


      <WhatsappButton/>
    </div>
  );
}

export default App;
