import Product1 from "./assets/images/product1.svg";
import Product2 from "./assets/images/product2.svg";
import Product3 from "./assets/images/product3.svg";
import Product4 from "./assets/images/product4.svg";

const Products = [
  {
    id: 1,
    img: Product1,
    name: "Whiskas Cat Food",
    oldPrice: "₹21.00",
    newPrice: "₹19.00",
    rating: 4.5,
    category: "Cats",
    brand: "Whiskas",
    stock: 10,
    description: [
      "Whiskas Cat Food is a complete and balanced meal designed to meet the nutritional needs of your feline friend.",
      "Made with high-quality ingredients including real meat, grains, and essential vitamins, it ensures your cat stays healthy and happy.",
      "This cat food is specially formulated to support a shiny coat, strong immune system, and overall vitality.",
      "Whether you have a kitten, adult, or senior cat, Whiskas provides the right nutrients at every life stage."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹19.00", offerPrice: "₹21.00" },
      { name: "1000 Gms", price: "₹35.00", offerPrice: "₹36.00" }
    ]
  },
  {
    id: 2,
    img: Product2,
    name: "Pedigree Meat Jerky",
    oldPrice: "₹36.00",
    newPrice: "₹25.00",
    rating: 4.0,
    category: "Dogs",
    brand: "Pedigree",
    stock: 15,
    description: [
      "Pedigree Meat Jerky is a delicious and nutritious treat for your dog.",
      "Made with real meat, this jerky is packed with protein to support strong muscles.",
      "The chewy texture helps to clean teeth and gums as your dog enjoys each bite.",
      "Free from artificial colors and flavors, Pedigree Meat Jerky is a healthy snack you can feel good about giving your pet. Ideal for training, rewarding, or just showing some extra love."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 15,
    variants: [
      { name: "500 Gms", price: "₹25.00", offerPrice: "₹36.00" },
      { name: "1000 Gms", price: "₹35.00", offerPrice: "₹40.00" }
    ]
  },
  {
    id: 3,
    img: Product3,
    name: "Pedigree Dog Food",
    oldPrice: "₹85.00",
    newPrice: "₹45.00",
    rating: 3.5,
    category: "Dogs",
    brand: "Pedigree",
    stock: 5,
    description: [
      "Pedigree Dog Food provides complete and balanced nutrition for your dog.",
      "With real meat as the first ingredient, it offers high-quality protein to support strong muscles.",
      "The blend of vitamins and minerals ensures a healthy immune system, while the added fiber aids in digestion.",
      "This dog food is designed to meet the dietary needs of dogs of all sizes and ages. Give your dog the best with Pedigree's trusted formula."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹45.00", offerPrice: "₹85.00" },
      { name: "1000 Gms", price: "₹85.00", offerPrice: "₹90.00" }
    ]
  },
  {
    id: 4,
    img: Product4,
    name: "Puppy Pack - Bonebones",
    oldPrice: "₹170.00",
    newPrice: "₹90.00",
    rating: 4.0,
    category: "Dogs",
    brand: "Bonebones",
    stock: 20,
    description: [
      "The Puppy Pack by Bonebones is specially curated for your growing puppy.",
      "This pack includes a variety of toys, treats, and training aids to help your puppy develop healthy habits and skills.",
      "The durable toys are perfect for teething, while the nutritious treats are great for training rewards.",
      "Each item in the pack is selected to support your puppy's overall development and keep them entertained."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹90.00", offerPrice: "₹170.00" },
      { name: "1000 Gms", price: "₹170.00", offerPrice: "₹180.00" }
    ]
  },
  {
    id: 5,
    img: Product1,
    name: "Royal Canin Kitten Food",
    oldPrice: "₹80.00",
    newPrice: "₹65.00",
    rating: 4.8,
    category: "Cats",
    brand: "Royal Canin",
    stock: 12,
    description: [
      "Royal Canin Kitten Food is formulated to meet the specific nutritional needs of kittens up to 12 months old.",
      "This food provides a balanced diet with the right mix of proteins, vitamins, and minerals to support healthy growth and development.",
      "The kibble is specially designed for the small mouths of kittens and helps promote dental health.",
      "Royal Canin's formula also supports a strong immune system and digestive health."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹65.00", offerPrice: "₹80.00" },
      { name: "1000 Gms", price: "₹120.00", offerPrice: "₹130.00" }
    ]
  },
  {
    id: 6,
    img: Product2,
    name: "IAMS ProActive Health",
    oldPrice: "₹90.00",
    newPrice: "₹85.00",
    rating: 4.7,
    category: "Dogs",
    brand: "IAMS",
    stock: 8,
    description: [
      "IAMS ProActive Health dog food is designed to support your dog's overall health and well-being.",
      "Made with real chicken and wholesome grains, this formula provides high-quality protein and essential nutrients.",
      "The blend of antioxidants, vitamins, and minerals helps maintain a strong immune system, while the tailored fiber blend promotes healthy digestion.",
      "IAMS ProActive Health is a great choice for keeping your dog healthy and active."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹85.00", offerPrice: "₹90.00" },
      { name: "1000 Gms", price: "₹150.00", offerPrice: "₹160.00" }
    ]
  },
  {
    id: 7,
    img: Product3,
    name: "Blue Buffalo Wilderness",
    oldPrice: "₹130.00",
    newPrice: "₹115.00",
    rating: 4.6,
    category: "Dogs",
    brand: "Blue Buffalo",
    stock: 6,
    description: [
      "Blue Buffalo Wilderness dog food is inspired by the diet of wolves, providing your dog with a high-protein, grain-free formula.",
      "Made with real meat, fruits, and vegetables, this food supports strong muscles, healthy skin, and a shiny coat.",
      "The addition of LifeSource Bits—a blend of antioxidants, vitamins, and minerals—ensures your dog gets the complete nutrition they need.",
      "Perfect for active and adventurous dogs."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹115.00", offerPrice: "₹130.00" },
      { name: "1000 Gms", price: "₹220.00", offerPrice: "₹240.00" }
    ]
  },
  {
    id: 8,
    img: Product4,
    name: "Hill's Science Diet",
    oldPrice: "₹140.00",
    newPrice: "₹125.00",
    rating: 4.4,
    category: "Dogs",
    brand: "Hill's",
    stock: 7,
    description: [
      "Hill's Science Diet offers precise nutrition tailored to the specific needs of your dog.",
      "This formula contains high-quality proteins and essential nutrients to support muscle maintenance and overall health.",
      "Rich in omega-6 fatty acids and vitamins, it promotes a healthy coat and skin.",
      "Hill's Science Diet is formulated to support your dog's digestive health and energy levels, making it a great choice for daily feeding."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹125.00", offerPrice: "₹140.00" },
      { name: "1000 Gms", price: "₹230.00", offerPrice: "₹250.00" }
    ]
  },
  {
    id: 9,
    img: Product1,
    name: "Whiskas Cat Food",
    oldPrice: "₹21.00",
    newPrice: "₹19.00",
    rating: 4.5,
    category: "Cats",
    brand: "Whiskas",
    stock: 10,
    description: [
      "Whiskas Cat Food is a complete and balanced meal designed to meet the nutritional needs of your feline friend.",
      "Made with high-quality ingredients including real meat, grains, and essential vitamins, it ensures your cat stays healthy and happy.",
      "This cat food is specially formulated to support a shiny coat, strong immune system, and overall vitality.",
      "Whether you have a kitten, adult, or senior cat, Whiskas provides the right nutrients at every life stage."
    ], reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹19.00", offerPrice: "₹21.00" },
      { name: "1000 Gms", price: "₹35.00", offerPrice: "₹36.00" }
    ]
  },
  {
    id: 10,
    img: Product2,
    name: "Pedigree Meat Jerky",
    oldPrice: "₹36.00",
    newPrice: "₹25.00",
    rating: 4.0,
    category: "Dogs",
    brand: "Pedigree",
    stock: 15,
    description: [
      "The Puppy Pack by Bonebones is specially curated for your growing puppy.",
      "This pack includes a variety of toys, treats, and training aids to help your puppy develop healthy habits and skills.",
      "The durable toys are perfect for teething, while the nutritious treats are great for training rewards.",
      "Each item in the pack is selected to support your puppy's overall development and keep them entertained."
    ],
     reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 15,
    variants: [
      { name: "500 Gms", price: "₹25.00", offerPrice: "₹36.00" },
      { name: "1000 Gms", price: "₹35.00", offerPrice: "₹40.00" }
    ]
  },
  {
    id: 11,
    img: Product3,
    name: "Pedigree Dog Food",
    oldPrice: "₹85.00",
    newPrice: "₹45.00",
    rating: 3.5,
    category: "Birds",
    brand: "Pedigree",
    stock: 5,
    description: [
      "Pedigree Dog Food provides complete and balanced nutrition for your dog.",
      "With real meat as the first ingredient, it offers high-quality protein to support strong muscles.",
      "The blend of vitamins and minerals ensures a healthy immune system, while the added fiber aids in digestion.",
      "This dog food is designed to meet the dietary needs of dogs of all sizes and ages. Give your dog the best with Pedigree's trusted formula."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹45.00", offerPrice: "₹85.00" },
      { name: "1000 Gms", price: "₹85.00", offerPrice: "₹90.00" }
    ]
  },
  {
    id: 12,
    img: Product4,
    name: "Puppy Pack - Bonebones",
    oldPrice: "₹180.00",
    newPrice: "₹90.00",
    rating: 4.0,
    category: "Dogs",
    brand: "Bonebones",
    stock: 20,
    description: [
      "The Puppy Pack by Bonebones is specially curated for your growing puppy.",
      "This pack includes a variety of toys, treats, and training aids to help your puppy develop healthy habits and skills.",
      "The durable toys are perfect for teething, while the nutritious treats are great for training rewards.",
      "Each item in the pack is selected to support your puppy's overall development and keep them entertained."
    ],
    reviews: [],
    warrantyPolicy: "1 year",
    shippingInformation: "Ships within 2-3 business days",
    offer: 10,
    variants: [
      { name: "500 Gms", price: "₹90.00", offerPrice: "₹180.00" },
      { name: "1000 Gms", price: "₹170.00", offerPrice: "₹180.00" }
    ]
  }
];

export default Products;
