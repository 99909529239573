// src/components/LoadingSpinner.js

// import React from 'react';
// import './LoadingSpinner.css';

// const LoadingSpinner = () => (
//   <div className="loading-spinner">
//     <div className="spinner"></div>
//   </div>
// );

// export default LoadingSpinner;


import React from 'react';
import './LoadingSpinner.css'; 

import Dog from '../../assets/images/dog_svgrepo.com.svg';
import Cat from '../../assets/images/cat_svgrepo.com.svg';
import Bird from '../../assets/images/bird-media-network_svgrepo.com.svg';
import Smallanimal from '../../assets/images/Container.svg';
import Fish from '../../assets/images/fish_svgrepo.com.svg';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner">
        <img src={Dog} alt="Dog" style={{ animationDelay: '0s' }} />
        <img src={Cat} alt="Cat" style={{ animationDelay: '0.4s' }} />
        <img src={Bird} alt="Bird" style={{ animationDelay: '0.8s' }} />
        <img src={Smallanimal} alt="Small Animal" style={{ animationDelay: '1.2s' }} />
        <img src={Fish} alt="Fish" style={{ animationDelay: '1.6s' }} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
