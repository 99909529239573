import React, { useState, useEffect } from "react";
import "../../assets/css/Addresspopup.css";
import cancel from "../../assets/images/phone_icon/cancle.svg";
import AddressService from "../Service/AddressService";
import { notifySuccess, ToastContainer } from "../../toast";

const countries = ["India"];

const AddressModal = ({
  onClose,
  onSave,
  currentAddress,
  isOpen,
  isEditMode,
}) => {
  const [addressData, setAddressData] = useState({
    addressName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    zipCode: "",
    city: "",
    state: "",
  });

  useEffect(() => {
    if (isEditMode && currentAddress) {
      setAddressData({
        addressName: currentAddress.addressName || "",
        firstName: currentAddress.firstName || "",
        lastName: currentAddress.lastName || "",
        email: currentAddress.email || "",
        phone: currentAddress.phone || "",
        country: currentAddress.country || "",
        address: currentAddress.address || "",
        zipCode: currentAddress.zipCode || "",
        city: currentAddress.city || "",
        state: currentAddress.state || "",
      });
    }
  }, [currentAddress, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone' || name === 'zipCode') {
      const numericValue = value.replace(/\D/, '');
      setAddressData((prevData) => ({ ...prevData, [name]: numericValue }));
    } else {
      setAddressData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const uid = localStorage.getItem("uid");
    if (!uid) {
      console.error("User ID not found in localStorage");
      return;
    }
  
    try {
      let result;
  
      if (isEditMode) {
       
        result = await AddressService.fetchUidAddressUpdate(uid, currentAddress._id, addressData);
      } else {
        result = await AddressService.fetchUidAddressCreate(uid, addressData);
      }
  
      notifySuccess("Address saved successfully");
      onSave(result.data); 
      onClose();         
  
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <ToastContainer/>
      <div className="modal-content bg-white p-10 rounded-lg w-full max-w-2xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {isEditMode ? "Edit Address" : "Create Address"}
          </h2>
          <img src={cancel} onClick={onClose} className="cursor-pointer" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">Address Type</label>
              <input
                type="text"
                name="addressName"
                value={addressData.addressName}
                onChange={handleChange}
                className=" border-solid border-gray-300 p-2 rounded"
                placeholder="Enter Type"
                required
              />
            </div>
            <div className="flex justify-between gap-2">
              <div className="flex flex-col w-1/2">
                <label className="text-sm font-medium mb-1">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={addressData.firstName}
                  onChange={handleChange}
                  className=" border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter First Name"
                  required
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="text-sm font-medium mb-1">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={addressData.lastName}
                  onChange={handleChange}
                  className="border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter Last Name"
                  required
                />
              </div>
            </div>
            <div className="flex justify-between gap-2">
              <div className="flex flex-col w-1/2">
                <label className="text-sm font-medium mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={addressData.email}
                  onChange={handleChange}
                  className=" border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="text-sm font-medium mb-1">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  value={addressData.phone}
                  onChange={handleChange}
                  className="border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter Number"
                  pattern="[0-9]*"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">Country</label>
              <select
                name="country"
                value={addressData.country}
                onChange={handleChange}
                className=" border-solid border-gray-300 p-2 rounded"
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">Address</label>
              <textarea
                name="address"
                value={addressData.address}
                onChange={handleChange}
                className="border-solid border-gray-300 p-2 rounded"
                placeholder="Enter Address"
                required
              />
            </div>
            <div className="flex justify-between gap-2">
              <div className="flex flex-col w-1/3">
                <label className="text-sm font-medium mb-1">Zip Code</label>
                <input
                  type="number"
                  name="zipCode"
                  value={addressData.zipCode}
                  onChange={handleChange}
                  className="border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter Zip Code"
                  pattern="[0-9]*"
                  required
                />
              </div>
              <div className="flex flex-col w-1/3">
                <label className="text-sm font-medium mb-1">City</label>
                <input
                  type="text"
                  name="city"
                  value={addressData.city}
                  onChange={handleChange}
                  className="border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter City"
                  required
                />
              </div>
              <div className="flex flex-col w-1/3">
                <label className="text-sm font-medium mb-1">State</label>
                <input
                  type="text"
                  name="state"
                  value={addressData.state}
                  onChange={handleChange}
                  className=" border-solid border-gray-300 p-2 rounded"
                  placeholder="Enter State"
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4 button-group">
            <button
              type="button"
              onClick={onClose}
              className="bg-moderate-color hover:bg-primary-color text-white font-bold py-2 px-4 mr-2 border-none rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border-none rounded-md"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressModal;
