import React, { useEffect, useState } from "react";
import { getProfileDashbord } from "../Container/Images";
import ProfileDashBordService from "../Service/ProfileDashBordService";

const ProfileDashborde = () => {
  const [orders, setOrders] = useState([]);

  const profileDashbordIcon = getProfileDashbord()

  const [orderCounts, setOrderCounts] = useState({
    all: 0,
    placed: 0,
    shipped: 0,
    delivered: 0,
  });

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        let requestObj = { id: localStorage.getItem("id") };
        let response = await ProfileDashBordService.fetchProfileDashBord(requestObj)
        const fetchedOrders = response.data.orders;
        setOrders(fetchedOrders);

        const all = response.data.totalDoc;
        const placed = response.data.pending + response.data.processing;
        const shipped = response.data.shipped;
        const delivered = response.data.delivered;

        setOrderCounts({ all, placed, shipped, delivered });
      } catch (e) {
        console.error("Failed to fetch orders", e);
      }
    };

    fetchOrder();
  }, []);

  return (
    <div className="pt-3">
      <h4>Profile</h4>
      <div className="profile-dash flex justify-between pt-2 pb-7">
        <div className="profile-dash-box bg-white flex py-8 px-10 items-center space-x-2 rounded-md">
          <div>
            <img src={profileDashbordIcon.Dashicon} alt="Icon" />
          </div>
          <div>
            <div className="text-sm font-bold profiledashborde-text">
              All Orders
            </div>
            <div className="text-slate-500 text-sm pt-1">
              Total: {orderCounts.all}
            </div>
          </div>
        </div>
        <div className="profile-dash-box bg-white flex py-8 px-10 items-center space-x-2 rounded-md">
          <div>
            <img src={profileDashbordIcon.Dashicon} alt="Icon" />
          </div>
          <div>
            <div className="text-sm font-bold profiledashborde-text">
              Placed Orders
            </div>
            <div className="text-slate-500 text-sm pt-1">
              Total: {orderCounts.placed}
            </div>
          </div>
        </div>
        <div className=" profile-dash-box bg-white flex py-8 px-10 items-center space-x-2 rounded-md">
          <div>
            <img src={profileDashbordIcon.Dashicon} alt="Icon" />
          </div>
          <div>
            <div className="text-sm font-bold profiledashborde-text">
              Shipped Orders
            </div>
            <div className="text-slate-500 text-sm pt-1">
              Total: {orderCounts.shipped}
            </div>
          </div>
        </div>
        <div className="profile-dash-box bg-white flex py-8 px-10 items-center space-x-2 rounded-md">
          <div>
            <img src={profileDashbordIcon.Dashicon} alt="Icon" />
          </div>
          <div>
            <div className="text-sm font-bold profiledashborde-text">
              Delivered Orders
            </div>
            <div className="text-slate-500 text-sm pt-1">
              Total: {orderCounts.delivered}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDashborde;
