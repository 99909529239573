import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const HeroBannerService =  {

fetchHeroBanner:()=>{
    return axios.get(`${baseURL}/banner/getAll`)
}

}

export default HeroBannerService