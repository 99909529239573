import {useEffect, useState} from 'react'
import CheckoutBanner from './CheckoutBanner'
import Header from '../header/header'
import Navbar from '../navbar/navbar'
import CheckInfo from './CheckInfo'
import OrderSummary from './OrderSummary'
import Footer from '../Footer/Footer'
import TabletNavbar from '../navbar/TabletNavbar'

const CheckShow = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [dataFromChild, setDataFromChild] = useState(0);
const [subTotal,setSubTotal]=useState(0)
const [total,setTotal]=useState(0)
const [discount,setDiscount]=useState(0)

const [isCouponApplied,setIsCouponApplied]=useState(false)
const [couponId,setCouponId]=useState("")

const [shippingCost,setShippingCost]=useState(0)

  // Function to update the state with data from the child
  const handleDataFromChild = (subtotal,total,discount) => {
  setSubTotal(subtotal);
  setTotal(total)
  setDiscount(discount)
  };

  const handleCouponChange = (isCouponApplied, couponId)=>{
    setIsCouponApplied(isCouponApplied);
setCouponId(couponId);
  }

  const handleDataFromShipping = (shippingCost) => {
    setShippingCost(shippingCost);
  
    };
  return (
    <>
        <div className='home-container fixed z-10 w-full top-0'>
    <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
    <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
</div>
<div className='pt-16'>
      <CheckoutBanner/>
        <div className='checkout-full-container flex justify-between gap-3 px-16 py-10 bg-slate-100'>

            <div>
                <CheckInfo subTotal={subTotal} total={total}  setShippingCostData={handleDataFromShipping} discount={discount} isCouponApplied={isCouponApplied} couponId={couponId}/>
            </div>
            <div>
                <OrderSummary setTotal={handleDataFromChild} shippingCost={shippingCost} handleCouponChange={handleCouponChange}/>
            </div>

        </div>
        <Footer />
        </div>
        <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>


    </>
  )
}

export default CheckShow
