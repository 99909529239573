import React, { useState, useEffect } from "react";
import { Slider } from "@mui/material";
import axios from "axios";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "../../App.css";
import CategoryService from "../Service/CategoryService";
import BrandService from "../Service/BrandService";

const Filter = ({
  productsProp,
  onFilterChange,
  selectedCategory,
  selectedCategoryId,
  selectedBrand,
}) => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(30000);
  const [value, setValue] = useState([0, 30000]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [hasResetOccurred, setHasResetOccurred] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [filters, setFilters] = useState({
    category: [],
    categoryId: [],
    brand: [],
  });

  const resetFilters = () => {
    setFilters({
      category: [],
      categoryId: [],
      brand: [],
    });
    setValue([0, 30000]);
    setMinPrice(0);
    setMaxPrice(30000);
    applyFilters();
  };
  

  const applyFilters = () => {
    onFilterChange({
      category: filters.category,
      categoryId: filters.categoryId,
      minPrice,
      maxPrice,
      brand: filters.brand,
    });
  };

  useEffect(() => {
    setProducts(productsProp);
  }, [productsProp]);

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await CategoryService.fetchAllCategorise();
            const data = response.data; 
            const childCategories = data.flatMap((category) => category.children);
            setCategories(childCategories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    fetchCategories();
}, []);

  useEffect(() => {
    const getBrand = async () => {
      try {
        const response = await BrandService.fetchAllBrand()
        setBrands(
          response.data.map((brand) => ({
            id: brand._id,
            name: brand.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    getBrand();
  }, []);

  const getCategoryCounts = () => {
    const categoryCounts = {};
    products.forEach((product) => {
      product.categories.forEach((cat) => {
        const categoryId = cat._id;
        if (categoryId) {
          if (!categoryCounts[categoryId]) {
            categoryCounts[categoryId] = 0;
          }
          categoryCounts[categoryId]++;
        }
      });
    });
    return categoryCounts;
  };

  const getBrandCounts = () => {
    const brandCounts = {};
    products.forEach((product) => {
      const brandName = product.brand?.name;
      if (brandName) {
        if (brandCounts[brandName]) {
          brandCounts[brandName]++;
        } else {
          brandCounts[brandName] = 1;
        }
      }
    });
    return brandCounts;
  };

  const brandCounts = getBrandCounts();



  const handleCategoryChange = (category, id) => {
    setFilters((prevFilters) => {
      console.log(prevFilters)
  
      let updatedCategory;
      let updatedCategoryId;
  
        updatedCategoryId = prevFilters.categoryId.includes(id._id)
          ? prevFilters.categoryId.filter((cat) => cat !== id._id)
          : [...prevFilters.categoryId, id._id];
      
  
      console.log(prevFilters);
      // Avoid updating state unnecessarily if the category is already selected
      // if (prevFilters.categoryId.includes(id._id)) return prevFilters;


      // Toggle logic for category selection
      // updatedCategory = prevFilters.category.includes(category)
      //   ? prevFilters.category.filter((cat) => cat !== category)
      //   : [...prevFilters.category, category];

      updatedCategoryId = prevFilters.categoryId.includes(id._id)
        ? prevFilters.categoryId.filter((cat) => cat !== id._id)
        : [...prevFilters.categoryId, id._id];

      return {
        ...prevFilters,
        category: [],
        categoryId: updatedCategoryId,
      };
    });
  };

  const handleBrandChange = (brand) => {
    setFilters((prevFilters) => {
      const updatedBrands = prevFilters.brand.includes(brand)
        ? prevFilters.brand.filter((b) => b !== brand)
        : [...prevFilters.brand, brand];
      return {
        ...prevFilters,
        brand: updatedBrands,
      };
    });
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  const toggleCategoryExpand = (categoryId) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [categoryId]: !prevExpanded[categoryId],
    }));
  };

  const toggleSubCategoryExpand = (subCategoryId) => {
    setExpandedSubCategories((prevExpanded) => ({
      ...prevExpanded,
      [subCategoryId]: !prevExpanded[subCategoryId],
    }));
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    if (
      selectedCategory &&
      !filters.category.includes(selectedCategory) &&
      !filters.category.includes(selectedCategoryId)
    ) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        category: [selectedCategory],
        categoryId: [selectedCategoryId],
      }));
    }
  }, [selectedCategory, selectedCategoryId]);

  useEffect(() => {
    if (selectedBrand && !filters.brand.includes(selectedBrand)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        brand: [selectedBrand],
      }));
    }
  }, [selectedBrand]);
  useEffect(() => {
    categories.forEach((category) => {
      let shouldExpandCategory = false;

      // Check if the parent category itself is selected
      if (filters.categoryId.includes(category._id)) {
        shouldExpandCategory = true;
      }

      // Check if any of the child categories are selected
      category.children.forEach((childCategory) => {
        if (filters.categoryId.includes(childCategory._id)) {
          shouldExpandCategory = true;
          setExpandedSubCategories((prevExpanded) => ({
            ...prevExpanded,
            [childCategory._id]: true,
          }));
        }

        // Check if any of the sub-child categories are selected
        childCategory.children.forEach((subChildCategory) => {
          if (filters.categoryId.includes(subChildCategory._id)) {
            shouldExpandCategory = true;
            setExpandedSubCategories((prevExpanded) => ({
              ...prevExpanded,
              [childCategory._id]: true,
            }));
          }
        });
      });

      // If any subcategory is selected, expand the parent category
      if (shouldExpandCategory) {
        setExpandedCategories((prevExpanded) => ({
          ...prevExpanded,
          [category._id]: true,
        }));
      }
    });
  }, [filters.categoryId, categories]);

  const hasActiveFilters = () => {
    return (
      filters.category.length > 0 ||
      filters.categoryId.length > 0 ||
      filters.brand.length > 0 ||
      (minPrice > 0 || maxPrice < 30000)
    );
  };

  const renderCategories = (category) => (
    <div key={category._id}>
      {/* Parent Category */}
      <div
        className={`flex items-center justify-between cursor-pointer hover:bg-slate-100 p-2 rounded-lg transition-all ${
          filters.categoryId.includes(category._id) ? "bg-blue-50 font-medium text-primary-color" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          toggleCategoryExpand(category._id);
        }}
      >
        <span
          className="hover:primary-color"
          onClick={() => handleCategoryChange(category.name.en, category)}
        >
          {category.name.en}
        </span>
        <div className="flex items-center gap-1">
          <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs">
            {getCategoryCounts()[category._id] || 0}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              toggleCategoryExpand(category._id);
            }}
          >
            {expandedCategories[category._id] ? (
              <FaChevronUp className="text-gray-500" />
            ) : (
              <FaChevronDown className="text-gray-500" />
            )}
          </span>
        </div>
      </div>
  
      {/* Child Categories */}
      {expandedCategories[category._id] && category.children.length > 0 && (
        <div className="ml-4 mt-2 pl-4 relative">
          <div className="absolute left-0 top-0 h-full w-px bg-slate-300"></div>
  
          {category.children.map((childCategory) => renderCategories(childCategory))}
        </div>
      )}
    </div>
  );

  return (
    <div className="filter-container flex flex-col gap-0.5 pt-4 border-b w-72 text-left">
      <div className="bg-white p-6 rounded">
        <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold pt-4 ml-2 mb-4">Filter</h2> 
        {hasActiveFilters() && (
            <button
              className="rounded-lg w-16 h-9 border-none bg-primary-color hover:bg-secondary-color text-white cursor-pointer"
              onClick={resetFilters}
            >
              Reset
            </button>
          )}     </div>

        <hr className="border-t-2 border-dashed border-slate-200 mb-4" />

        <h3 className="text-sm uppercase ml-2 pb-2">Category</h3>

        <div className="flex flex-col gap-4 pt-2 text-sm text-slate-600 overflow-hidden overflow-y-auto max-h-96">
        {categories.map((category) => renderCategories(category))}
      </div>
      </div>

      <div className="bg-white p-6 rounded">
        <h3 className="font-semibold text-sm mb-2">FILTER BY PRICE</h3>
        <Slider
          value={value}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={0}
          max={30000}
          className="custom-slider"
        />
        <div className="flex justify-center items-center space-x-2 mt-1 ">
          <input
            type="number"
            placeholder="Min"
            value={minPrice}
            onChange={(e) => setMinPrice(Number(e.target.value))}
            className="border rounded p-2 w-20"
          />
          <span>-</span>
          <input
            type="number"
            placeholder="Max"
            value={maxPrice}
            onChange={(e) => setMaxPrice(Number(e.target.value))}
            className="border rounded p-2 w-20"
          />
        </div>
        <button
          onClick={applyFilters}
          className="mt-4 p-2 px-11 w-full bg-secondary-color  border rounded-full text-base cursor-pointer text-white hover:text-white hover:bg-secondary-color hover:border-slate-950"
        >
          Filter
        </button>
      </div>

      <div className="bg-white p-6 rounded">
        <h3 className="font-semibold text-sm mb-2">BRAND</h3>

        <div className="flex flex-col gap-4 pt-2 text-sm text-slate-600 overflow-hidden overflow-y-auto max-h-96">
          {brands.map((brand) => (
            <div key={brand.id}>
              <div
                className={`flex items-center justify-between cursor-pointer hover:bg-slate-100 p-2 rounded-lg transition-all ${
                  filters.brand.includes(brand.name)
                    ? "bg-blue-50 font-medium text-primary-color"
                    : ""
                }`}
                onClick={() => handleBrandChange(brand.name)}
              >
                <span className="hover:text-primary-color">{brand.name}</span>
                <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs">
                  {brandCounts[brand.name] || 0}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
