import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import "firebase/compat/auth";
import cancel from "../../assets/images/phone_icon/cancle.svg";
import { auth } from "../Service/firebase.config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CustomerService from "../Service/CustomerService";

const LoginPopup = ({ isOpen, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [resendTime, setResendTime] = useState(60);
  const [isResendActive, setIsResendActive] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpInvalidMessage, setOtpInvalidMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer = null;
    if (resendTime > 0 && isResendActive) {
      timer = setTimeout(() => {
        setResendTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (resendTime === 0 && isResendActive) {
      setIsResendActive(false);
    }

    return () => clearTimeout(timer);
  }, [resendTime, isResendActive]);

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    resetOTPStates();
  };

  const resetOTPStates = () => {
    setOtpCode("");
    setResendTime(60);
    setIsResendActive(false);
    setShowOtpInput(false);
    setConfirmationResult(null);
    setOtpInvalidMessage("");
  };

  const handleSendOTP = async () => {
    if (phoneNumber.trim() === "") {
      alert("Please enter a valid phone number.");
      return;
    }
    setIsLoading(true);
    const appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: () => {
        console.log("Recaptcha resolved");
      },
      "expired-callback": () => {
        console.log("Recaptcha expired");
      },
    });

    try {
      const result = await auth.signInWithPhoneNumber(
        `+${phoneNumber}`,
        appVerifier
      );
      setConfirmationResult(result);
      console.log(result);
      setShowOtpInput(true);
      setIsResendActive(true);
      console.log("OTP sent to", phoneNumber);
    } catch (error) {
      console.log("Error sending OTP:", error);
    }
  };

  const handleResendOTP = async () => {
    if (isResendActive) return; 
    setResendTime(60);
    setIsResendActive(true);
    await handleSendOTP();
  };

  const handleVerifyOTP = async () => {
    if (!confirmationResult) {
      console.error("No confirmation result");
      return;
    }

    try {
      const result = await confirmationResult.confirm(otpCode);
      const user = result.user;
      console.log("OTP verified successfully");

      toast.success("Successfully Logged In", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });

      console.log(user);

      localStorage.setItem("phoneNumber", phoneNumber);
      localStorage.setItem("uid", user.uid);
      localStorage.setItem("isLoggedIn", "true");

      // let response = await axios.post(
      //   "https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/customer/register",
      //   {
      //     phoneNumber: phoneNumber,
      //     uid: user.uid,
      //   }
      // );
      let response = await CustomerService.PostUserData(phoneNumber,user.uid);
      localStorage.setItem("id", response.data._id);

      console.log("Phone number and UID posted");

      const intendedPath = localStorage.getItem("intendedPath");
      localStorage.removeItem("intendedPath");

      navigate(intendedPath);

      onClose();
    } catch (error) {
      console.error("Invalid OTP:", error);
      setOtpInvalidMessage("Invalid OTP");
      if (error.code === "auth/too-many-requests") {
        toast.error("Too many requests. Please try again later.");
      }
  
      setIsResendActive(true);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleEditNumber = () => {
    resetOTPStates();
  };

  if (!isOpen) return null;

  return (
<div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
  <div className="login-container bg-white p-4 md:p-6 rounded-md shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg relative">
    <div className="absolute top-0 right-0 mt-1 mr-1">
      <img
        src={cancel}
        onClick={onClose}
        className="text-black mr-4 mt-4 border-none font-bold cursor-pointer hover:text-gray-700"
        alt="Cancel"
      />
    </div>

    <div className="text-center mb-4">
      {!showOtpInput ? (
        <>
          <h2 className="text-lg md:text-xl font-bold mb-2">Login/Register</h2>
          <h4 className="font-semibold text-slate-600">
            Enter Your Mobile Number
          </h4>
        </>
      ) : (
        <div className="text-center mb-4">
          <h2 className="text-lg md:text-xl font-bold">OTP Verification</h2>
          <h4 className="font-semibold text-slate-600 cursor-pointer">
            Enter OTP Sent to {phoneNumber}
            <span className="text-blue-500 ml-2" onClick={handleEditNumber}>
              (edit)
            </span>
          </h4>
        </div>
      )}
    </div>

    {!showOtpInput ? (
      <div className="input-phn flex justify-center mb-4 mt-3 ml-2 md:ml-24">
        <PhoneInput
          country={"in"}
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </div>
    ) : (
      <>
<div className="otp-box mb-4 mt-5 flex justify-center">
  {[1, 2, 3, 4, 5, 6].map((index) => (
    <input
      key={index}
      type="text"
      maxLength={1}
      value={otpCode[index - 1] || ""}
      onChange={(e) => {
        const newOtpCode = [...otpCode];
        newOtpCode[index - 1] = e.target.value;
        setOtpCode(newOtpCode.join(""));
        if (e.target.value !== "" && index < 6) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Backspace") {

          if (index > 1) {
            document.getElementById(`otp-input-${index - 0}`).focus();
            console.log(`otp-input1-${index - 1}`)
          }
        } else if (e.key.length === 1) {
          if (e.target.value !== "" && index < 6) {
            document.getElementById(`otp-input-${index + 1}`).focus();
          }
          console.log(`otp-input2-${index + 1}`)
        }
      }}
      onPaste={(e) => {
        e.preventDefault(); 
        const pastedData = e.clipboardData.getData("Text").split(""); 
        const newOtpCode = pastedData.slice(0, 6).map((digit, i) => {
          return i < 6 ? digit : '';
        });
        const updatedOtpCode = [...otpCode];
        newOtpCode.forEach((digit, i) => {
          if (digit) {
            updatedOtpCode[i] = digit; 
            if (i < 5) {
              document.getElementById(`otp-input-${i + 2}`).focus(); 
            }
          }
        });

        setOtpCode(updatedOtpCode.join(""));
      }}

      id={`otp-input-${index}`}
      className="w-10 h-12 text-center mx-1 px-2 py-2 border border-soft-color rounded focus:outline-none focus:ring focus:border-primary-color"
      placeholder=""
    />
  ))}
</div>
        <div className="flex justify-between items-center cursor-pointer">
          {isResendActive ? (
            <p className="text-gray-500 text-sm">
              Resend OTP (
              {`00:${resendTime < 10 ? `0${resendTime}` : resendTime}`})
            </p>
          ) : (
            <button onClick={handleResendOTP} className="ml-2 border-none">
              Resend OTP
            </button>
          )}

          {otpInvalidMessage && (
            <p className="text-primary-color text-sm text-center">
              {otpInvalidMessage}
            </p>
          )}

          {showOtpInput && (
            <button
              onClick={handleVerifyOTP}
              className="confirm-otp bg-primary-color border-none text-white px-4 py-2 rounded-lg mt-2 hover:bg-secondary-color"
            >
              Confirm
            </button>
          )}
        </div>
      </>
    )}

{!showOtpInput && (
          <button
            onClick={handleSendOTP}
            className={`send-otp bg-primary-color right-0 border-none text-white px-4 py-2  rounded-lg mt-3 hover:bg-secondary-color ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={isLoading} 
          >
            {isLoading ? "Sending..." : "Send OTP"} 
          </button>
        )}

    <div id="recaptcha-container"></div>
  </div>
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
</div>
  );
};

export default LoginPopup;
