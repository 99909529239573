import React from 'react';
import { TitleBanner } from '../Container/Images';

const ProductBanner = ({selectedSection, selectedCategory, selectedBrand }) => {
  return (
    <div
      className="flex-col justify-center relative"
      style={{
        backgroundImage: `url(${TitleBanner})`,
        minHeight: '250px',
        backgroundPosition: 'center',
      }}
    >
      <div className='absolute inset-0 bg-black opacity-50'></div>
      <div className='relative flex flex-col gap-3 items-center justify-center h-full pt-16'>
        <div className='text-3xl font-poppins text-white font-bold'>
        { selectedSection ? `Section: ${selectedSection}` : selectedCategory ? `Category: ${selectedCategory}` : 'All Products'
        
    }
          {selectedBrand && ` - Brand: ${selectedBrand}`}
        
        </div>
        <div className='text-base font-poppins text-white'>
          Home / {selectedSection ? `${selectedSection}` : selectedCategory ? selectedCategory : 'All Products'}
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;
