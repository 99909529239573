import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const SaleService = {

    fetchSaleData:(id)=>{
        return axios.get(`${baseURL}/sale/${id}`)
    }

}

export default SaleService