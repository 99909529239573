import { useEffect, useState } from "react";
import ProfileDetails from "./ProfileDetails";
import ProfileBanner from "./ProfileBanner";
import ProdBanner from "../../assets/images/ProductBanner.svg";
import TabletNavbar from "../navbar/TabletNavbar";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";
import addform from "../../assets/images/addform.png";
import tickmark from "../../assets/images/tickmark.png";
import axios from "axios";
import editicon from "../../assets/images/Payment_icons/Editicon.png";
import deleteicon from "../../assets/images/deleteicon.svg";
import { toast } from "react-toastify";
import AddressModal from "../Checkout/AddressModal";
import Petshop_Banner from "../../assets/images/Petshop_Banner.svg";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { notifyError, notifySuccess } from "../../toast";
import AddressService from "../Service/AddressService";
import FlexBannerService from "../Service/FlexBannerService";

export const ManageProfile = () => {
  const [addresses, setAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false); 
  const [addressToDelete, setAddressToDelete] = useState(null);
  
  const [productflex,setProductFlex]= useState()

  useEffect(() => {
FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const productflex = response.data.find(
            (item) => item.position === "Profile Flex"
          );
          setProductFlex(productflex || null);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uid = localStorage.getItem("uid");

  useEffect(() => {
    if (uid) {
      fetchAddresses();
    }
  }, [uid]);

  const fetchAddresses = async () => {
    try {
      const response = await AddressService.fetchUidAddress(uid)
      setAddresses(response.data);
    } catch (err) {
      console.error("Error fetching addresses:", err);
    }
  };

  const handleAddAddress = () => {
    setCurrentAddress(null);
    setIsModalOpen(true);
  };

  const handleEditAddress = (address) => {
    setCurrentAddress(address);
    setIsModalOpen(true);
  };

  const openDeleteDialog = (address) => {
    setAddressToDelete(address);
    setIsDialogOpen(true);
  };

  const handleDeleteAddress = async () => {
    if (!addressToDelete) return;

    try {
      await AddressService.fetchUidAddressDelete(uid,addressToDelete._id)
      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address._id !== addressToDelete._id)
      );
      toast.success("Address deleted successfully");
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Error deleting address");
    } finally {
      setIsDialogOpen(false);
      setAddressToDelete(null);
    }
  };

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setAddressToDelete(null);
  };

  const handleSaveAddress = (addressData) => {
    if (currentAddress) {
      setAddresses(
        addresses.map((address) =>
          address === currentAddress ? addressData : address
        )
      );
    } else {
      setAddresses([...addresses, addressData]);
    }
    setIsModalOpen(false);
    fetchAddresses();
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address === selectedAddress ? null : address);
  };

  return (
    <>
      <div className="home-container fixed z-10 w-full top-0">
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>

      <div className="pt-24">
        <div
          className="flex flex-col items-center justify-center relative"
          style={{
            backgroundImage: `url(${ProdBanner})`,
            minHeight: "250px",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative flex flex-col gap-3 items-center justify-center h-full">
            <div className="text-3xl font-poppins text-white font-bold text-center">
              Manage Profile
            </div>
            <div className="text-base font-poppins text-white text-center">
              Home / Manage Profile
            </div>
          </div>
        </div>
      </div>

      <div className="manage-profile-main flex justify-center gap-20 py-7">
        <div>
          <ProfileDetails />
        </div>

        <div className="manage-profile-contain">
          <div className="profile-banner-profile flex justify-center">
          {productflex && productflex.imageUrl && (
            <img src={productflex.imageUrl} alt="" className="w-full profile-banner-profile-img" />
          )}
          </div>

          <div className="address-profile-container bg-white p-7 mt-3">
            <div className="text-left">
              <div className="justify-start">
                <div className="relative right-0 bottom-0">
                  <p className="mb-4 text-left whitespace-nowrap">
                    User Details
                  </p>
                </div>
                {addresses.map((address, index) => (
                  <div
                    key={index}
                    className={`address-box border p-4 mb-4 ${
                      selectedAddress === address ? "bg-light-color" : ""
                    }`}
                    onClick={() => handleSelectAddress(address)}
                  >
                    <div className="flex justify-between text-left items-start">
                      <div className="address-container">
                        <div className="address-row">
                          <p className="address-label font-semibold">
                            Address:
                          </p>
                          <p className="address-value">{address.addressName}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">City:</p>
                          <p className="address-value">{address.city}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">Email:</p>
                          <p className="address-value">{address.email}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">
                            First name:
                          </p>
                          <p className="address-value">{address.firstName}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">
                            Last name:
                          </p>
                          <p className="address-value">{address.lastName}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">Phone:</p>
                          <p className="address-value">{address.phone}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">
                            Address:
                          </p>
                          <p className="address-value">{address.address}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">State:</p>
                          <p className="address-value">{address.state}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">Zip:</p>
                          <p className="address-value">{address.zipCode}</p>
                        </div>
                        <div className="address-row">
                          <p className="address-label font-semibold">
                            Country:
                          </p>
                          <p className="address-value">{address.country}</p>
                        </div>
                      </div>
                      <div className="add-icon flex justify-end items-start space-x-2 mt-3">
                        <div
                          className="border-none cursor-pointer"
                          onClick={() => handleEditAddress(address)}
                        >
                          <img src={editicon} alt="Edit" className="w-4 h-4" />
                        </div>
                        <div
                          className="border-none cursor-pointer"
                          onClick={() => openDeleteDialog(address)}
                        >
                          <img
                            src={deleteicon}
                            alt="Delete"
                            className="w-4 h-4 border-none"
                          />
                        </div>
                      </div>
                    </div>
                    {selectedAddress === address && (
                      <div className="absolute top-1 right-2">
                        <img src={tickmark} className="h-5 w-5" />
                      </div>
                    )}
                  </div>
                ))}
                <div className="relative bg-white p-4 border rounded whitespace-nowrap flex items-center w-fit">
                  <img
                    src={addform}
                    className="h-11 w-11 cursor-pointer text-lg font-semibold mt-4"
                    onClick={handleAddAddress}
                  />
                  <span className="text-lgi mt-3 font-semibold">
                    Add New Address
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
      {isModalOpen && (
        <AddressModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveAddress}
          currentAddress={currentAddress}
          isEditMode={!!currentAddress}
        />
      )}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onConfirm={handleDeleteAddress}
        onCancel={handleDialogCancel}
        message="Are you sure you want to delete this address?"
      />
    </>
  );
};

export default ManageProfile;
