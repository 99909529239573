import React from 'react';
import "../../App.css";
import { getSection } from '../Container/Images';

const Section = () => {

  const sectionImg = getSection();

  return (
    <div className="section  pb-8">
      <div className="section-box ">
        <div className="item gap-3 justify-start">
          <img src={sectionImg.Free} alt="Free Shipping" />
          <div>
            <p className="text-base font-bold">Free Shipping</p>
            <p className="text-sm text-gray-600">Enjoy the free shipping month for all our latest products</p>
          </div>
        </div>
        <div className="item gap-3">
          <img src={sectionImg.Payment} alt="Secure Payment" />
          <div>
            <p className="text-base font-bold">Secure Payment</p>
            <p className="text-sm text-gray-600">Complete your payment with trusted Payment Gateways</p>
          </div>
        </div>
        <div className="item gap-3">
          <img src={sectionImg.Return} alt="Return Policy" />
          <div>
            <p className="text-base font-bold">Return Policy</p>
            <p className="text-sm text-gray-600">A 14 day return policy for our special offer</p>
          </div>
        </div>
        <div className="item gap-2">
          <img src={sectionImg.Certified} alt="Certified For Pets" />
          <div>
            <p className="text-base font-bold">Certified For Pets</p>
            <p className="text-sm text-gray-600">Trusted sources who can bring you the product you need</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
