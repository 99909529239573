import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const CustomerService = {

    PostUserData:(phoneNumber,uid)=>{

        return axios.post(`${baseURL}/customer/register`,{
            phoneNumber,
            uid
        })
    }

}

export default CustomerService