import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import whatsappicon from "../../assets/images/whatsappicon.svg"

const WhatsappButton = () => {
  return (
    <a
      href="https://wa.me/9840941033"
      className="fixed bottom-32 right-4  z-20  transition-colors"
      target="_blank"
      rel="noopener noreferrer"
    >
    <img src={whatsappicon} />
    </a>
  );
};

export default WhatsappButton;
