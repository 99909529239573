import React from 'react'

import { TitleBanner } from '../Container/Images';

const CheckoutBanner = () => {
  return (
    <>
    <div
      className='flex-col justify-center relative  '
      style={{ backgroundImage: `url(${TitleBanner})`, minHeight: '250px', backgroundPosition: 'center' }}
    >
          <div className='absolute inset-0 bg-black opacity-50'></div>
      <div className='relative flex flex-col gap-3 items-center justify-center h-full pt-28'>
        <div className='text-3xl font-poppins text-white font-bold'>
         CheckOut
        </div>
        <div className='text-base font-poppins text-white'>
          Home /Checkout
        </div>
      </div>


    </div>

      
    </>
  )
}

export default CheckoutBanner
