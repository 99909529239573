import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../header/CartContext";
import cancel from "../../assets/images/phone_icon/cancle.svg";
import Products from "../../Model"; // Ensure this is the correct import path
import axios from "axios";
import { notifyError, notifySuccess } from "../../toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CouponService from "../Service/CouponService";
const OrderSummary = ({ setTotal, shippingCost,handleCouponChange }) => {
  const { cartItems, cartTotal, removeFromCart } = useContext(CartContext);
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  setTotal(cartTotal, cartTotal - discount + shippingCost, discount);
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await CouponService.fetchAllCoupon()
        setCoupons(response.data);
        console.log(response.data);
      } catch (err) {
        console.error("Error fetching coupons:", err);
      }
    };
    fetchCoupons();
  }, []);

  const cartRemoval = (id,variant)=>{


    setIsCouponApplied(false);
    setCouponCode("");
    setDiscount(0)
    handleCouponChange(false,"");
    removeFromCart(id,variant);

  }

  useEffect(() => {
    setTotal(cartTotal, cartTotal - discount + shippingCost, discount);
  }, [shippingCost]);

  const handleApplyCoupon = async () => {
    const currentCoupon = coupons.find(
      (coupon) => coupon.couponCode === couponCode
    );

    if (!currentCoupon) {
      notifyError("Coupon code is invalid.");
    } else {
      const currentDate = new Date();
      const expiryDate = new Date(currentCoupon.endTime);

      if (currentDate > expiryDate) {
        notifyError("Coupon has expired.");
        return;
      }

      if (cartTotal < currentCoupon.minimumAmount) {
        notifyError(
          `Minimum cart total of ₹${currentCoupon.minimumAmount} required to use this coupon.`
        );
        return;
      }

      const id = localStorage.getItem("id");

      try {
        const response = await CouponService.fetchUseUIdCoupon(currentCoupon._id, id);     
        console.log(response.data);
        if(response.data.message){
          notifyError("Coupon code has been used already.");
return;
        }
      } catch (err) {
        notifyError("Coupon code is invalid.");
      }

      let discountValue = 0;
      if (currentCoupon.discountType.type === "percentage") {
        discountValue = (cartTotal * currentCoupon.discountType.value) / 100;
      } else if (currentCoupon.discountType.type === "fixed") {
        discountValue = currentCoupon.discountType.value;
      }
      handleCouponChange(true,currentCoupon._id);
      setDiscount(discountValue);
      setTotal(cartTotal, cartTotal - discountValue, discount);
      setIsCouponApplied(true);
      notifySuccess("Coupon applied successfully!");
    }
  };

  return (
    <div className="order-summary-container p-4 bg-white w-[450px] rounded-md h-auto">
      <h2 className="text-xl font-bold mb-4">Order Summary</h2>
      <hr className="border-t-2 border-dashed border-0 border-slate-200 mb-4" />
      <div className="flex justify-between p-3 bg-slate-100 mb-3 rounded-md">
        <span className="text-sm font-semibold">Product</span>
        <span className="text-sm font-semibold">Prices</span>
      </div>
      <div className="product-list-container overflow-y-auto max-h-48">
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div key={item._id} className="flex justify-between mb-2 relative">
              <div className="flex gap-4 items-center">
                <button
                  onClick={() => cartRemoval(item._id, item.variant) }
                  className="border-none relative"
                >
                  <img
                    src={cancel}
                    alt="Delete"
                    className="h-6 w-6 cursor-pointer absolute top-1/2 transform -translate-y-1/2"
                  />
                </button>
                <img
                  src={item.image[0]}
                  alt={item.title}
                  className="h-12 w-12"
                />
              </div>
              <div className="flex flex-col justify-start ml-2">
                <div className="text-sm font-semibold">
                  {item.title.en} ({item.variant && item.variant.sku})
                </div>
                <div className="text-sm text-gray-600">
                  ₹{parseFloat(item.price.replace("₹", "")).toFixed(2)} /{" "}
                  {item.quantity}
                </div>
              </div>
              <div className="flex flex-col justify-start ml-auto">
                <span className="text-sm font-semibold">
                  {" "}
                  ₹
                  {(
                    parseFloat(item.price.replace("₹", "")) * item.quantity
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-sm text-gray-600 mb-5">Your cart is empty</div>
        )}
      </div>
      <hr className="border-t-slate-200 border-1 border-slate-200 mb-4" />
      <div className="mb-4 flex pt-3">
        <input
          type="text"
          placeholder="Enter your Coupon"
          className="border border-gray-300 p-3 w-full mb-2 border-r-0 rounded-l-md hover:border-primary-color"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <button
          className={`p-3 h-full border rounded-r-md cursor-pointer ${
            isCouponApplied
              ? "bg-moderate-color text-white"
              : "bg-green-500 text-white border-green-500"
          }`}
          onClick={handleApplyCoupon}
          disabled={isCouponApplied}
        >
          {isCouponApplied ? "Applied" : "Apply"}
        </button>
      </div>
      <hr className="border-t-slate-200 border-1 border-slate-200 mb-4" />
      <div className="flex justify-between p-3 mb-3">
        <span className="text-sm font-semibold">Sub Total:</span>
        <span className="text-sm font-semibold">₹{cartTotal.toFixed(2)}</span>
      </div>
      <div className="flex justify-between p-3 bg-slate-100 mb-3 rounded-md">
        <span className="text-sm font-semibold">Shipping Cost:</span>
        <span className="text-sm font-semibold">₹{shippingCost}</span>
      </div>
      <div className="flex justify-between p-3 bg-slate-100 mb-3 rounded-md">
        <span className="text-sm font-semibold">Discount:</span>
        <span className="text-sm font-semibold text-green-500">
          -₹{discount.toFixed(2)}
        </span>
      </div>
      <div className="flex justify-between p-3 bg-slate-100 mb-3 rounded-md">
        <span className="text-sm font-semibold">Total:</span>
        <span className="text-sm font-semibold">
          ₹{(cartTotal - discount + shippingCost).toFixed(2)}
        </span>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default OrderSummary;
