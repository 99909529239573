import React, { useState, useEffect, useContext, useRef } from "react";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import StarRating from "../../StarRating";

const BestDogProducts = ({ apiProducts }) => {
  const { addToCart } = useContext(CartContext);
  const { addToFavorites, isInFavorites, removeFromFavorites } =
    useContext(FavoriteContext);

  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const scrollContainerRef = useRef(null); 
  useEffect(() => {
    // axios
    //   .get("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products")
    //   .then((response) => {
    //     if (response.data && response.data.products) {
    //       setProducts(response.data.products);
    //       // setFilteredProducts(response.data.products);
    //     } else {
    //       console.error("Unexpected response format", response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching products:", error);
    //   });

    setProducts(apiProducts);
  }, [apiProducts]);

  const BestSellingProduct = products.filter((product) => {
    if (product.status !== "show") {
      return false;
    }
    let tags = [];
    try {
      if (Array.isArray(product.tag)) {
        tags = tags.concat(product.tag);
      }
    } catch (e) {
      console.error("Error parsing tags:", e);
      return false;
    }
    return tags.includes("Best Dog Products");
  });

  const displayedProducts = BestSellingProduct.slice(0, 8);

  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = (product) => {
    const defaultVariant = product.variants[0];
    const productWithDetails = {
      ...product,
      variant: defaultVariant.name,
      price: defaultVariant.price || defaultVariant.offerPrice,
      quantity: quantity,
    };
    addToCart(productWithDetails);
    toast.success("Product added to cart", {
      position: "top-right",
      theme: "colored",
      autoClose: 1000,
    });
  };

  const handleAddToFavorites = (product) => {
    const defaultVariant = product.variants[0];
    const productWithDetails = {
      ...product,
      variant: defaultVariant.name,
      price: defaultVariant.offerPrice,
      discountPrice: defaultVariant.offerPrice,
    };

    if (isInFavorites(product.id)) {
      removeFromFavorites(product.id);
      toast.info("Product removed from favorites", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    } else {
      addToFavorites(productWithDetails);
      toast.success("Product added to favorites", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    }
  };

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };

  const handleViewMoreClick = () => {
    navigate("/product", { state: { section: "Best Dog Products" } });
  };

  const handleScrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -200, 
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200, 
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="new-arrival-container flex justify-between pt-2 pb-2">
        <div className="flex-col">
          <div className="font-bold text-5xl">Best Dog Products</div>
          <div className="text-sm mt-3">
            Choose your necessary products from this feature category
          </div>
        </div>
        <div className="flex">
          <button
            className="relative border-none whitespace-nowrap h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color"
            onClick={handleViewMoreClick}
          >
            View More
          </button>
        </div>
      </div>

      <div className="relative new-arrival-product">
      <button
        onClick={handleScrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-[#504a4a] border-2 border-[#7c7b7b] rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-lg hover:bg-primary-color hover:text-white transition-all duration-300"
      >
        &#9664; 
      </button>
        <div
          ref={scrollContainerRef}
          className="scroll-container snap-x flex overflow-x-auto pt-2 pb-3 text-center scrollbar-hide scroll-smooth"
        >
          <div className="new-arrival-box flex justify-between space-x-8">
            {displayedProducts.map((product) => (
              <div key={product._id} className="flex-shrink-0 w-64 snap-center">
                <div className="bg-white rounded shadow-lg p-4">
                  <div className="bg-secondary-color text-white text-sm py-1 w-16 mr-2 whitespace-nowrap rounded-r-full top-2 left-0">
                  Off {((product.prices.discount / product.prices.originalPrice)*100).toFixed(0)}%
                  </div>
                  <img
                    src={product.image[0]}
                    alt={product.title.en}
                    className="w-full  object-contain cursor-pointer "
                    onClick={() => handleProductClick(product._id)}
                  />
                  <div
                    className="text-sm font-bold mt-2 cursor-pointer  line-clamp-2 "
                    onClick={() => handleProductClick(product._id)}
                  >
                    {product.title.en}
                  </div>
                  <div className="flex justify-center mt-2">
                    <StarRating rating={product.rating} />
                  </div>
                  <div className="flex items-center justify-center mt-2 space-x-2">
                    <div className="text-lg font-semibold text-primary-color">
                      ₹{product.prices.price}
                    </div>
                    <div className="text-base line-through text-gray-500">
                      ₹{product.prices.originalPrice}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
        onClick={handleScrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-[#504a4a] border-2 border-[#7c7b7b] rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-lg hover:bg-primary-color hover:text-white transition-all duration-300"
      >
      &#9654; 
      </button>
      </div>
    </>
  );
};

export default BestDogProducts;


