import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const FlexBannerService = {

fetchFlexImage:()=>{
    return axios.get(`${baseURL}/flex-panel/getAll`)
}

}

export default FlexBannerService