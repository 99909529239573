import React, { useState, useEffect } from "react";
import deleteicon from "../../assets/images/Profile_Icon/delete.svg";
import cancelicon from "../../assets/images/Profile_Icon/cancel_icon.png";
import visibility from "../../assets/images/Profile_Icon/visibility.svg";
import location from "../../assets/images/Profile_Icon/location.svg";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { notifyError, notifySuccess } from "../../toast";
import { wait } from "@testing-library/user-event/dist/utils";
import ProfileDashBordService from "../Service/ProfileDashBordService";

const ProfileOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(8);
  const [orderData, setOrderData] = useState({});
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        let requestObj = { id: localStorage.getItem("id") };
       let response = await ProfileDashBordService.fetchProfileOrderList(currentPage, requestObj);
        setOrders(response.data.orders);
        setOrderData(response.data);
        console.log(response.data);
      } catch (e) {
        console.error("Failed to fetch orders", e);
      }
    };
  
    fetchOrder();
  }, [currentPage]);
  

  const fetchOrderPage = async (pageNumber) => {
    try {
      let requestObj = { id: localStorage.getItem("id") };
      let response = await ProfileDashBordService.fetchProfileOrderPage(pageNumber,requestObj)
      setOrders(response.data.orders);
      setOrderData(response.data);
      console.log(response.data);
    } catch (e) {
      console.error("Failed to fetch orders", e);
    }
  };

  useEffect(() => {
    const today = new Date();

    let filteredOrders = orders;

    if (filter === "Today") {
      filteredOrders = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return orderDate.toDateString() === today.toDateString();
      });
    } else if (filter === "Last 7 Days") {
      filteredOrders = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 7);
        return orderDate >= last7Days && orderDate <= today;
      });
    } else if (filter === "Last 30 Days") {
      filteredOrders = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        const last30Days = new Date(today);
        last30Days.setDate(today.getDate() - 30);
        return orderDate >= last30Days && orderDate <= today;
      });
    } else if (filter === "All") {
      // No filtering, show all orders
      filteredOrders = orders;
    }

    setFilteredOrders(filteredOrders);
  }, [filter, orders]);

  const handleCancel = async (orderId, newStatus) => {
    const allowedStatuses = ["Pending", "Processing", "Cancel"];

    try {
      const response = await ProfileDashBordService.fetchProfileOrder(orderId)
      
      const order = await response.data;

      console.log(`Order: ${order}`);

      const currentStatus = order.status;
      console.log(`Current status: ${currentStatus}`);

      if (allowedStatuses.includes(currentStatus)) {
        if (newStatus === "Cancel") {
          const confirmCancel = window.confirm(
            "Are you sure you want to cancel this order?"
          );
          if (!confirmCancel) {
            return;
          }
        }
        const updateResponse = await ProfileDashBordService.fetchProfileOrderUpdate(orderId,newStatus)

        const updateData = await updateResponse.data;

        if (updateData.message) {
          notifySuccess(updateData.message);
        }
      } else {
        notifyError(
          "Only 'Pending', 'Processing', or 'Cancel' statuses are allowed."
        );
      }
    } catch (error) {
      console.error("Error handling status update:", error);
    }
  };

  // const indexOfLastOrder = currentPage * ordersPerPage;
  // const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  // const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => {
    fetchOrderPage(pageNumber);

    setCurrentPage(pageNumber);
  };

  const handleDelete = (orderId) => {
    const updatedOrders = orders.filter((order) => order._id !== orderId);
    setOrders(updatedOrders);
  };

  return (
    <div className="profile-order-container w-auto bg-white p-7 overflow-x-auto">
      <div className="flex justify-between items-center py-2">
        <h2 className="text-xl font-semibold">Order List</h2>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border p-2 rounded-lg"
        >
          <option value="All">All</option>
          <option value="Today">Today</option>
          <option value="Last 7 Days">Last 7 Days</option>
          <option value="Last 30 Days">Last 30 Days</option>
        </select>
      </div>

      <table className="profile-order-container-table w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 whitespace-nowrap">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Order ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Place On
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Total
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Order Status
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Payment Method
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Options
            </th>
          </tr>
        </thead>
        {loading ? (
          <div className="loader">Loading...</div>
        ) : (
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOrders.map((order) => (
              <tr key={order._id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center h-3 w-14 text-gray-900">
                  <div className="bg-soft-color py-2 rounded w-16">
                    {order.invoice}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                  {new Date(order.createdAt).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                  {order.cart.reduce((total, item) => total + item.quantity, 0)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                  ₹ {order.total.toFixed(2)}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm">
                  <div className="rounded-full text-center">{order.status}</div>
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm">
                  <div className="rounded-full text-center">
                    {order.paymentMethod}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm flex gap-4">
                  <div className="relative group">
                    <img
                      src={visibility}
                      alt=""
                      className="h-5 w-5 cursor-pointer"
                      onClick={() =>
                        navigate("/orderdetails", {
                          state: { orderId: order._id },
                        })
                      }
                    />
                    <span className="absolute left-1/2 bottom-full mb-2 hidden group-hover:block transform -translate-x-1/2 bg-primary-color text-white text-xs rounded py-1 px-2">
                      View Order Details
                    </span>
                  </div>

                  <div className="relative group">
                    <img
                      src={location}
                      alt=""
                      className="h-5 w-5 cursor-pointer"
                      onClick={() =>
                        navigate("/tracking", { state: { orderId: order._id } })
                      }
                    />
                    <span className="absolute left-1/2 bottom-full mb-2 hidden group-hover:block transform -translate-x-1/2 bg-primary-color text-white text-xs rounded py-1 px-2">
                      Track Order
                    </span>
                  </div>

                  <div className="relative group">
                    <img
                      src={cancelicon}
                      alt=""
                      className="h-5 w-5 rounded-sm bg-light-color cursor-pointer"
                      onClick={() => handleCancel(order._id, "Cancel")}
                    />
                    <span className="absolute left-1/2 bottom-full mb-2 hidden group-hover:block transform -translate-x-1/2 bg-primary-color text-white text-xs rounded py-1 px-2">
                      Cancel Order
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <div className="flex justify-end mt-4 mr-5">
        <Pagination
          ordersPerPage={ordersPerPage}
          totalOrders={orderData?.totalDoc}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const Pagination = ({ ordersPerPage, totalOrders, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalOrders / ordersPerPage);

  console.log(currentPage);
  console.log(totalPages);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-end">
      <div className="mx-1">
        <button
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
          className="border-none bg-light-color p-2 cursor-pointer"
        >
          &lt;
        </button>
      </div>
      {pageNumbers.map((number) => (
        <div key={number} className="mx-1">
          <button
            onClick={() => paginate(number)}
            className={`border-none bg-light-color p-2 cursor-pointer ${
              currentPage === number ? "bg-moderate-color" : ""
            }`}
          >
            {number}
          </button>
        </div>
      ))}
      <div className="mx-1">
        <button
          onClick={() =>
            paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
          }
          className="border-none bg-light-color p-2 cursor-pointer"
        >
          &gt;
        </button>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ProfileOrderList;
