import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const ProfileDashBordService = {

fetchProfileDashBord :(requestObj)=>{
return axios.post(`${baseURL}/order/getCustomerOrder`,requestObj)
}
,

fetchProfileOrderList:(currentPage, requestObj) => {
return axios.post(`${baseURL}/order/getCustomerOrder?page=${currentPage}`, requestObj);
}
,
fetchProfileOrderPage:(pageNumber,requestObj)=>{
return axios.post(`${baseURL}/order/getCustomerOrder?page=${pageNumber}`, requestObj);   
}
,
fetchProfileOrder:(orderId)=>{
return axios.get(`${baseURL}/orders/${orderId}`);   
}
,
fetchProfileOrderUpdate: (orderId, newStatus) => {
return axios.put(`${baseURL}/orders/${orderId}`, { status: newStatus });
},
PostOrder: (requestObj) => {
    return axios.post(`${baseURL}/order/add`, requestObj);
    }
  


}

export default ProfileDashBordService