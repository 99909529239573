import React, { useContext, useState, useEffect } from "react";
import axios from "axios"; // Import axios
import { CartContext } from "./CartContext";
import { FavoriteContext } from "./FavoriteContext";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../Authentication/LoginPopup";
import "../../App.css";
import Logo from "../../assets/images/Logo.png";
import SearchIcon from "../../assets/images/search.png";
import Heart from "../../assets/images/heart.png";
import Shopping from "../../assets/images/shopping.png";
import ProductService from "../Service/ProductService";

const Searchbar = () => {
  const { cartItems, cartTotal, removeFromCart, addToCart } =
    useContext(CartContext);
  const { favorites } = useContext(FavoriteContext);
  const navigate = useNavigate();

  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showSearchPop, setShowSearchPop] = useState(false);
  const [isLoginOpen, setShowLoginPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm.trim()) {
        try {
          // const response = await axios.get(
          //   `https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products`
          // );
          const response = await ProductService.fetchProduct()
          const filteredProducts = response.data.products.filter((product) =>
            product.title.en.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setSearchResults(filteredProducts);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setShowSearchPop(event.target.value.trim().length > 0);
  };

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
    setShowSearchPop(false);
  };

  const handleCloseLogin = () => {
    setShowLoginPopup(false);
  };

  return (
    <>
      <div className="Search-bar flex items-center relative w-full max-w-md mx-4">
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="w-7 absolute left-3 cursor-pointer"
          onClick={() => setShowSearchPop(!showSearchPop)}
        />
        <input
          type="text"
          placeholder="What are you looking for?"
          value={searchTerm}
          onChange={handleInputChange}
          className="pl-10 py-2 w-full rounded-lg border border-solid focus:outline-none"
        />
      </div>

      {showSearchPop && searchResults.length > 0 && (
        <div
          className={`search-results ${
            showSearchPop ? "show" : ""
          } absolute top-14 left-80 ml-80 w-96 bg-white border border-gray-300 shadow-lg mt-2 z-20`}
        >
          <ul className="max-h-64 overflow-y-auto">
            {searchResults.slice(0, 4).map((product) => (
              <li
                key={product._id}
                className="flex items-center mb-2 hover:bg-soft-color p-2"
              >
                <img
                  src={product.image[0]}
                  alt={product.title.en}
                  onClick={() => handleProductClick(product._id)}
                  className="w-12 h-12 object-cover"
                />
                <div className="ml-2">
                  <div
                    className="text-sm font-semibold"
                    onClick={() => handleProductClick(product._id)}
                  >
                    {product.title.en}
                  </div>
                </div>
              </li>
            ))}
            {searchResults.length > 4 && (
              <div className="text-center text-gray-600 mt-2">
                Scroll for more...
              </div>
            )}
            {searchResults.length === 0 && (
              <div className="text-sm text-gray-600">No results found</div>
            )}
          </ul>
        </div>
      )}

      {isLoginOpen && <LoginPopup onClose={handleCloseLogin} />}
    </>
  );
};

export default Searchbar;
