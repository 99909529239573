import React, { useState } from "react";

import { notifyError, notifySuccess } from "../../toast";
import { useNavigate } from "react-router-dom";
import { ecomAddress, ecomEmail, ecomNumber, getFooter, getFooterContact, Logo, titleName } from "../Container/Images";
import SubscribeService from "../Service/SubscribeService";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const footerIcon = getFooter();
  const footerContactIcon = getFooterContact()

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      notifyError("Please enter a valid email address");
      return;
    }

    try {
      // const response = await fetch("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/subscribe", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ mail: email }),
      // });

      const response = await SubscribeService.PostEmail(email)
   


      const data = response.data;
      setMessage(data.message);
      setEmail("");
      notifySuccess("Your email has been sent successfully");
    } catch (error) {
      setMessage(error.message);
      notifyError("Subscription failed");
    }
  };

  return (
    <>
      {/* Footer Main */}

      <div
        className="foot-container1 flex justify-between gap-28 bg-cover bg-center"
        style={{ backgroundImage: `url(${footerIcon.FooterBgimage1})`, minHeight: "300px" }}
      >
        <div className="foot-sub1 flex gap-5 backdrop-blur-lg bg-white bg-opacity-10 border  border-white h-36 w-[300px] pr-5 rounded-md mt-24 ">
          <div>
            <img src={footerContactIcon.ContactIcon} alt="" className="mt-10 ml-4 " />
          </div>
          <div className="mt-12">
            <div className="text-lg text-white font-bold   ">
              {ecomNumber}
            </div>
            <div className="text-sm text-gray-400    mt-3 whitespace-nowrap">
              Need Help? Call Now
            </div>
          </div>
        </div>

        <div className="foot-sub2 mr-20">
          <div className="text-white text-5xl    font-bold mt-24 whitespace-nowrap">
            Subscribe To Our Newsletter
          </div>
          <div className="text-gray-400 text-sm    mt-2 lg:text-base text-wrap">
            Choose your necessary products from these categories
          </div>
          <form onSubmit={handleSubmit} className="relative mt-6">
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-none px-14 rounded-full w-96 h-12"
              required
            />
            <img
              src={footerIcon.FooterSearchIcon}
              alt="Submit"
              className="footer-icon absolute top-1/2 right-1 transform -translate-y-1/2 h-10 w-10 cursor-pointer"
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>

      {/* Footer Sub */}
      <div
        className="foot-container2 cursor-pointer  flex justify-between gap-4 p-14 "
        style={{ backgroundImage: `url(${footerIcon.FooterBgimage2})`}}
      >
        <div>
          <div className="flex items-center cursor-pointer">
            <img src={Logo} alt="" className="h-12 w-12" />
            <div className="flex-col ml-2 font-poppins text-left">
              <span className="text-lgi font-bold font-poppins">
                {titleName}{" "}
              </span>
              {/* <p className="font-normal text-sm mt-0 font-poppins ">The tail tales Story begins</p> */}
            </div>
          </div>

          <div className="text-sm text-wrap break-keep mt-3">
            Your go-to destination for seamless shopping experiences.
            <div>
              Discover convenience, quality, and unbeatable deals right at your
              fingertips.
            </div>
            Shop smart, shop CartUser!
          </div>

          <div className="flex items-center text-sm  hover:text-primary-color  mt-3">
            <img src={footerContactIcon.PhoneIcon} alt="" className="mr-2" />
            {ecomNumber}
          </div>

          <div className="flex items-center text-sm  hover:text-primary-color   pt-2">
            <img src={footerContactIcon.Emailicon} alt="" className="mr-2" />
            {ecomEmail}
          </div>

          <div className="flex items-center text-sm hover:text-primary-color   pt-2">
            <img src={footerContactIcon.Emailicon} alt="" className="mr-2" />
            {ecomAddress}
          </div>
        </div>

        <div>
          <div className="text-mini    font-bold text-black">
            IMPORTANT LINKS
          </div>
          <div className="flex flex-col gap-5 text-sm mt-3 cursor-pointer">
            <div
              onClick={() => navigate("/")}
              className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color"
            >
              Home
            </div>
            <div
              onClick={() => navigate("/product")}
              className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color"
            >
              Products
            </div>
            {/* <div className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color">
              Shop
            </div> */}
          </div>
        </div>

        <div>
          <div className="text-mini    font-bold text-black">QUICK LINKS</div>
          <div className="flex flex-col gap-5 text-sm mt-3 cursor-pointer">
            <div
              onClick={() => navigate("/Privacypolicy")}
              className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color"
            >
              Privacy Policy
            </div>
            <div
              onClick={() => navigate("/Termsandconditions")}
              className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color"
            >
              Terms and Conditions
            </div>
            {/* <div    onClick={() => navigate("/home")} className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color">
              Return Policy
            </div>
            <div    onClick={() => navigate("/home")} className="transition-transform duration-300 hover:scale-105 hover:translate-x-2 hover:text-primary-color">
              Shipping Information
            </div> */}
          </div>
        </div>

        <div>
          <div className="text-mini    font-bold text-black">
            Stay Connected with Us!
          </div>
          <div className="text-sm pt-3">
            Stay updated! Follow us for exclusive deals & updates!
          </div>
          <div className="flex gap-3 pt-2">
            <img
              src={footerIcon.Facebookicon}
              alt=""
              className="h-10 w-9 cursor-pointer "
            />
            <img
              src={footerIcon.GoogleIcon}
              alt=""
              className="h-10 w-9  cursor-pointer "
            />
            <img
              src={footerIcon.InlickedIcon}
              alt=""
              className="h-10 w-9  cursor-pointer  "
            />
            <img
              src={footerIcon.Whatsappicon}
              alt=""
              className="h-10 w-9  cursor-pointer "
            />
          </div>
        </div>
      </div>

      <div
        className="flex justify-center gap-4 mb-5 "
        style={{ backgroundImage: `url(${footerIcon.FooterBgimage2})` }}
      >
        <div className="text-xs">© 2024 {titleName}. All rights reserved.</div>
      </div>
    </>
  );
};

export default Footer;
