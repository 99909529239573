import React, { useEffect, useState } from "react";
import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Product3 from "../../assets/images/product3.svg";
import Product4 from "../../assets/images/product4.svg";
import Flex from "../../assets/images/Flex-2.svg";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StarRating from "../../StarRating";
import FlexBannerService from "../Service/FlexBannerService";

const Premium = ({ apiProducts }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const [premiumItem, setPremiumItem] = useState(null);

  useEffect(() => {
FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const premiumItem = response.data.find(
            (item) => item.position === "Premium Products"
          );
          if (premiumItem) {
            setPremiumItem(premiumItem);
          } else {
            console.error('No item found with position "Premium Products"');
          }
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  const handleImageClick = () => {
    if (premiumItem && premiumItem.link) {
      navigate(premiumItem.link);
    }
  };

  useEffect(() => {
    // axios
    //   .get("https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/products")
    //   .then((response) => {
    //     if (response.data && response.data.products) {
    //       setProducts(response.data.products);
    //       // setFilteredProducts(response.data.products);
    //     } else {
    //       console.error("Unexpected response format", response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching products:", error);
    //   });

    setProducts(apiProducts);
  }, [apiProducts]);

  const PremiumProducts = products.filter((product) => {
    if (product.status !== "show") {
      return false;
    }
    let tags = [];

    try {
      // Parse each tag entry

      if (Array.isArray(product.tag)) {
        tags = tags.concat(product.tag);
      } else {
        console.error("Parsed tag is not an array:");
      }
    } catch (e) {
      console.error("Error parsing tags:", e);
      return false;
    }

    return tags.includes("Premium Products");
  });

  // Show only the first 8 products
  const displayedProducts = PremiumProducts.slice(0, 8);

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };
  const handleViewMoreClick = () => {
    navigate("/product", { state: { section: "Premium Products" } });
  };

  return (
    <>
      <div className="premium-full-container">
        <div className="premium flex justify-between items-center pt-4 pb-8">
          <div className="flex-col">
            <div className="premium-ftn font-bold text-5xl  ">
              Premium Products on sale
            </div>
          </div>
          <div className="flex">
            <button
              className="premium-btn relative whitespace-nowrap border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color"
              onClick={handleViewMoreClick}
            >
              View More
            </button>
          </div>
        </div>

        <div className="premium-container flex justify-between gap-3">
          <div className=" relative min-w-72 text-white flex flex-col  pt-1">
            {/* <img src={Flex} alt="Flex" className="premium-container-img w-72 h-6/12 object-cover  inset-0 " /> */}
            {premiumItem && (
              <img
                src={premiumItem.imageUrl}
                alt="Premium"
                className="premium-container-img w-72 h-6/12 object-cover  inset-0 "
                onClick={handleImageClick}
              />
            )}
          </div>

          <div className="premium-product w-full         ">
            {displayedProducts.map((product) => (
              <div
                key={product._id}
                className="premium-product-box bg-white gap-1 shadow-md text-center rounded border-gray-600 border-1 w-52 h-full pb-2 lg:w-48  "
              >
                <div className="relative mr-13 bg-secondary-color text-sm text-white py-1 pr-9 rounded-r-3xl w-4 mt-6 whitespace-nowrap">
                Off {((product.prices.discount / product.prices.originalPrice)*100).toFixed(0)}%
                </div>
                <img
                  src={product.image[0]}
                  alt={product.title.en}
                  className="product-img w-full  cursor-pointer"
                  onClick={() => handleProductClick(product._id)}
                />
                <StarRating rating={product.rating} />
                <div
                  className="mt-4 text-lg  font-semibold cursor-pointer line-clamp-2 "
                  onClick={() => handleProductClick(product._id)}
                >
                  {product.title.en}
                </div>
                <div className="flex justify-center items-center gap-2 mt-2">
                  <div className="text-base font-semibold text-primary-color">
                    ₹{product.prices.price}
                  </div>
                  <div className="text-base line-through text-gray-600">
                    ₹{product.prices.originalPrice}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Premium;
