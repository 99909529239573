import React from 'react'
import OrderDetails from './OrderDetails'
import TrackingStatus from './TrackingStatus'
import BillingInfo from './BillingInfo'
import OrderInfo from './OrderInfo'
import Header from '../header/header'
import Navbar from '../navbar/navbar'
import Footer from '../Footer/Footer'
import TabletNavbar from '../navbar/TabletNavbar'

const TrackingShow = () => {
  return (
    <div >
        <div className='fixed z-10 w-full top-0 '>
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>

        <div className="tracking-tablet mx-auto pt-28 pb-7 p-6">
       
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 tracking-container">

          <div className="md:col-span-2">
          <TrackingStatus />
           
          </div>

          <div>
            <BillingInfo />
            <OrderInfo />
          </div>

        </div>
          </div>
       <Footer/>
       <div className="md:hidden h-[3.6rem] sm:h-16">

</div>
       <div className="fixed z-10 w-full bottom-0">
        <TabletNavbar />
      </div>
    </div>
  )
}

export default TrackingShow