import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const AttributesService = {

    fetchAllAttribute:()=>{
        return axios.get(`${baseURL}/attributes/show`)
    }

}

export default AttributesService