import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../../components/Authentication/LoginPopup"; // Adjust the path as per your project structure

import profile from "../../assets/images/phone_icon/profile.svg";
import shopping from "../../assets/images/phone_icon/shopping.svg";
import menu from "../../assets/images/phone_icon/menu.svg";
import home1 from "../../assets/images/phone_icon/home1.svg";
import favorite from "../../assets/images/phone_icon/favorite.svg";
import cate_icon from "../../assets/images/categaries_icon.svg";

import arror_up from "../../assets/images/arror-up.svg";
import arror_rigth from "../../assets/images/arror-rigth.svg";
import arror_down from "../../assets/images/arror-down.svg";


import cancel from "../../assets/images/phone_icon/cancle.svg";
import assign from "../../assets/images/phone_icon/assign.svg";
import home from "../../assets/images/phone_icon/home.svg";
import favori from "../../assets/images/phone_icon/favori.svg";
import logout from "../../assets/images/phone_icon/logout.svg";
import privacy from "../../assets/images/phone_icon/privacy.svg";
import product from "../../assets/images/phone_icon/product.svg";
import profile2 from "../../assets/images/phone_icon/profile2.svg";

import Overloy from "../../assets/images/Overlay.svg";
import { ArrowsIcon, ArrowsIconRigth, ArrowsIconUp, Logo, titleName } from "../Container/Images";
import CategoryService from "../Service/CategoryService";

const TabletNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openCategories, setOpenCategories] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await CategoryService.fetchAllCategorise()
        const data = response.data;

        const childCategories = data.flatMap((category) => category.children);
        setCategories(childCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProfileClick = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isLoggedIn) {
      navigate("/profile");
    } else {
      setIsLoginOpen(true);
      setIsDropdownOpen(false);
    }
  };

  const handleProduct = () => {
    setIsDropdownOpen(false);
    navigate("/product");
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleProfile = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isLoggedIn) {
      navigate("/profile");
    } else {
      localStorage.setItem("intendedPath", "/profile");
      setIsLoginOpen(true);
      setIsDropdownOpen(false);
    }
  };

  const handleCategoryToggle = (categoryId) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleCategorySelect = (category) => {
    setIsDropdownOpen(false);
    console.log(`Selected category: ${category.category}`);
  };

  const handleViewMoreFavorite = () => {
    navigate("/favorites");
  };
  const handleViewCart = () => {
    navigate("/cart");
  };
  const handleCategoryClick = (category, id) => {
    setSelectedCategory(category);
    navigate("/product", { state: { category, id } });
  };

  const renderCategory = (category) => {
    const hasChildren = category.children && category.children.length > 0;

    return (
      <div key={category._id} className="group relative text-black">
        <div
          className="flex items-center gap-2 cursor-pointer p-3 hover:bg-slate-100"
          onClick={() => handleCategoryToggle(category._id)}
        >
           {category.icon ? (
          <img src={category.icon} alt={`${category.name.en} icon`} className="h-8 w-8" />
            ) : (
              
          " "
            )}
          <span onClick={() => handleCategoryClick(category.name.en, category)}>
            {category.name.en}
          </span>
          {hasChildren && (
            <span className="ml-auto">
              {openCategories[category._id] ? <img src={ArrowsIconUp} className="h-5"/>: <img src={ArrowsIconRigth} className="h-5"/>}
            </span>
          )}
        </div>

        {hasChildren && openCategories[category._id] && (
          <div className="ml-4">
            {category.children.map((childCategory) => renderCategory(childCategory))}
          </div>
        )}
      </div>
    );
  };

  const visibleCategories = categories.slice(0, 8);
  const additionalCategories = categories.slice(8);
  return (
    <>
      <div className="phone_menu_button flex justify-between items-center bg-white p-4">
        <div
          className="flex flex-col items-center gap-2 cursor-pointer"
          onClick={toggleMenu}
        >
          <img src={menu} alt="Menu" />
          <span className="text-sm hover:text-primary-color">MENU</span>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate("/")}
        >
          <img src={home1} alt="Contact" />
          <span className="text-sm hover:text-primary-color">HOME</span>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={handleViewMoreFavorite}
        >
          <img src={favorite} alt="Wishlist" />
          <span className="text-sm hover:text-primary-color">WISHLIST</span>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={handleViewCart}
        >
          <img src={shopping} alt="Cart" />
          <span className="text-sm hover:text-primary-color">CART</span>
        </div>
        <div
          className="flex flex-col items-center gap-2 mr-10"
          onClick={handleProfileClick}
        >
          <img src={profile} alt="Profile" />
          <span className="text-sm hover:text-primary-color">PROFILE</span>
        </div>
      </div>

      <div className={`phone_menu ${isMenuOpen ? "open" : ""}`}>
        <div className="flex flex-col p-4">
          <div className="flex items-center justify-start">
            <img
              src={Logo}
              alt="Company Logo"
              className="h-10 mt-3"
              onClick={toggleMenu}
            />
            <div className="flex font-poppins items-center pt-3 mr-36">
              <div>
                <span className="text-lgi font-bold font-poppins whitespace-nowrap">
                 {titleName}{" "}
                </span>
              </div>
              <div className="ml-24">
                <img src={cancel} className="cancel-btn" onClick={toggleMenu} />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-7">
          <div className="relative">
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`text-2xl cat-button cursor-pointer w-[225px] h-8 text-center items-center flex gap-2 justify-start pt-2 ${
              isDropdownOpen
                ? "text-white bg-secondary-color"
                : "text-gray-400 bg-secondary-color"
            }`}
          >
            <img src={cate_icon} alt="Categories" className="ml-3" />
            Category
          </div>
          {isDropdownOpen && (
            <div className="nav-bar-category-list absolute top-9 z-10 w-64 p-1">
              <div className="drop-down-nav-list absolute w-[200px]  mb-6 bg-white p-2 overflow-hidden shadow-2xl">
              <div className="h-full overflow-y-auto custom-scrollbar pb-12">
                {categories.map((category) => renderCategory(category))}
                  <div className="drop-down-view text-moderate-color font-semibold p-3 cursor-pointer flex items-center gap-2 absolute bottom-0 bg-white w-full justify-between">
                    <span
                      className="whitespace-nowrap mt-1"
                      onClick={handleProduct}
                    >
                      View All
                    </span>
                    <img
                      src={ArrowsIcon}
                      alt=""
                      className="h-7 w-7 mr-10 cursor-pointer"
                      onClick={handleProduct}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
          </div>

          <div className="mt-4 flex flex-col justify-between gap-5 ml-3">
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img src={home} alt="Profile" />
              <span className="text-sm">Home</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={() => navigate("/product")}
            >
              <img src={product} alt="Profile" />
              <span className="text-sm">Product</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={handleProfile}
            >
              <img src={profile2} alt="Profile" />
              <span className="text-sm">Profile</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={() => navigate("/favorites")}
            >
              <img src={favori} alt="Cart" />
              <span className="text-sm">Wishlist</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={() => navigate("/Termsandconditions")}
            >
              <img src={assign} alt="Wishlist" />
              <span className="text-sm">Term & Conditions</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={() => navigate("/Privacypolicy")}
            >
              <img src={privacy} alt="Contact" />
              <span className="text-sm">Privacy Policy</span>
            </div>
            <div
              href="#"
              className="flex items-center gap-2 my-2 hover:text-primary-color  cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img src={logout} alt="Profile" />
              <span className="text-sm">Log Out</span>
            </div>
          </div>
        </div>
      </div>

      {/* Login Popup */}

      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />
    </>
  );
};

export default TabletNavbar;
