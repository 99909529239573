import axios from 'axios'
import React from 'react'
import {baseURL} from "../api"

const ProductService ={

fetchProduct:() => {
return axios.get(`${baseURL}/products`)
}
,
fetchProductShows:() => {
    return axios.get(`${baseURL}/products/show/`)
    }
    ,

fetchProductShow:(productId) => {
    return axios.get(`${baseURL}/products/show/${productId}`)
    }
    ,
fetchAllProduct:() => {
    return axios.get(`${baseURL}/products?price=published`)
}
,
fetchProductId:(productId) => {
    return axios.get(`${baseURL}/products/show/${productId}`)
}
,
fetchProductReview:(productId) => {
    return axios.get(`${baseURL}/reviews/product/${productId}`)
}
,
fetchProductReviewAdd: (reviewData) => {
    return axios.post(`${baseURL}/reviews`, reviewData);
  }

}

export default ProductService