import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const FavoriteService = {

fetchFavoriteItemUid:(uid)=>{

    return axios.get(`${baseURL}/favorite/${uid}`)

},

PostFavoriteItemUid:(uid,productId)=>{

    return axios.post(`${baseURL}/favorite/add-to-favorite`,{ 
    uid ,
    productId
    })

}

,
RemoveFavoriteItemUid:(uid, productId) => {
    return axios.post(`${baseURL}/favorite/remove-from-favorite`, {
        uid,
        productId
    });
}


}

export default FavoriteService