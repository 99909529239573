import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const CartService = {

fetchCartItemUid:(uid)=>{
    return axios.get(`${baseURL}/cart/${uid}`)
}
,
PostCartItemUid: (uid, updatedCartItems) => {
    return axios.post(`${baseURL}/cart/add-to-cart`, { uid, cartItems: updatedCartItems });
  },
RemoveCartItemUid:(uid,productId,variant)=>{
    return axios.post(`${baseURL}/cart/remove-from-cart`,{uid,productId,variant})
}
,
ClearCartItemUid:(uid)=>{
    return axios.post(`${baseURL}/cart/clear`,uid)
}
  

}

export default CartService