import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../api";

const Brandlist = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const getBrand = async () => {
      try {
        const response = await axios.get(`${baseURL}/brand/`);
        console.log("Brands fetched:", response.data);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    getBrand();
  }, []);

  const handleBrandClick = (brandName) => {
    navigate("/product", { state: { brand: brandName } });
  };

  const handleProductAll = () => {
    navigate("/brandall");
  };

  return (
    <div className="top-brand-container w-full">
      <div className="justify-between flex top-brand-text">
        <div className="flex-col mb-7 ">
          <div className="font-bold text-5xl">Top Brands</div>
          <div className="text-sm ">Shop By Brands</div>
        </div>
        <div className="flex">
          <button
            className="relative border-none h-11  rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color whitespace-nowrap"
            onClick={handleProductAll}
          >
            View More
          </button>
        </div>
      </div>
      <div className="top-brand-sub2 flex justify-between">
        {brands.slice(0, 8).map((brand, index) => (
          <div
            key={index}
            className="brand-box cursor-pointer flex items-center p-1 mb-4 bg-white rounded-full shadow-lg transition-all duration-300 hover:shadow-xl"
            onClick={() => handleBrandClick(brand.name)}
          >
            <img
              src={brand.image}
              alt={brand.name}
              className="h-12 w-12 mr-4 rounded-full"
            />
            <div>
              <div className="font-bold cursor-pointer">{brand.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brandlist;
