import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const BrandService =  {

fetchAllBrand:()=>{
    return axios.get(`${baseURL}/brand/`)
}

}

export default BrandService