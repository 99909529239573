import React, { useContext, useState, useEffect } from 'react';
import { FavoriteContext } from '../header/FavoriteContext';
import Header from '../header/header';
import Navbar from '../navbar/navbar';
import Footer from "../Footer/Footer";
import TabletNavbar from '../navbar/TabletNavbar';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DecrementIcon, DeleteIcon, IncrementIcon, TitleBanner } from '../Container/Images';

const FavoriteList = () => {
  const { favorites, removeFromFavorites } = useContext(FavoriteContext);
  const [counts, setCounts] = useState({});

  useEffect(() => {
    const initialCounts = favorites.reduce((acc, product) => {
      acc[product._id] = 1;
      return acc;
    }, {});
    setCounts(initialCounts);
    window.scrollTo(0, 0);
  }, [favorites]);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex items-center mt-1">
        {[...Array(fullStars)].map((_, i) => (
          <svg
            key={`full-${i}`}
            className="w-4 h-4 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        ))}
        {halfStar && (
          <svg
            key="half-star"
            className="w-4 h-4 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        )}
        {[...Array(emptyStars)].map((_, i) => (
          <svg
            key={`empty-star-${i}`}
            className="w-4 h-4 text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        ))}
      </div>
    );
  };

  const handleIncrement = (productId) => {
    setCounts(prevCounts => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 1) + 1
    }));
  };

  const handleDecrement = (productId) => {
    setCounts(prevCounts => ({
      ...prevCounts,
      [productId]: Math.max(1, (prevCounts[productId] || 1) - 1)
    }));
  };

  const calculatePrice = (price, quantity) => {
    const priceStr = String(price);
    const numericPrice = parseFloat(priceStr.replace('₹', ''));
    return isNaN(numericPrice) ? '₹0' : `₹${(numericPrice * quantity).toFixed(2)}`;
  };
  

  const calculateTotal = () => {
    const total = favorites.reduce((total, product) => {
      const variantPrice = product.variants[0]?.price || '₹0';
      const quantity = counts[product._id] || 1;
      const numericPrice = parseFloat(variantPrice.replace('₹', ''));
  
      return total + (numericPrice * quantity);
    }, 0);
  
    return `₹${total.toFixed(2)}`;
  };
  
  const navigate = useNavigate();

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };

  return (
    <>
       <ToastContainer />
      <Header />
      <Navbar />
      <div
        className="relative flex-col justify-center"
        style={{
          backgroundImage: `url(${TitleBanner})`,
          minHeight: '250px',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col gap-3 items-center justify-center h-full pt-16">
          <div className="text-3xl font-poppins text-white font-bold">Wishlist Items</div>
          <div className="text-base font-poppins text-white">Home / Wishlist Items</div>
        </div>
      </div>
      <div className="flex justify-center mx-auto p-6 bg-slate-100">
        <div className="overflow-x-auto">
          <table className="w-full bg-white rounded py-4 px-10">
            <thead className="text-black uppercase text-sm leading-normal">
              <tr className='bg-slate-100 whitespace-nowrap '>
                <th className="py-3 px-6 text-left">Product</th>
                <th className="py-3 px-6 text-center">Qty</th>
                <th className="py-3 px-6 text-center">Price</th>
                <th className="py-3 px-6 text-center">Discount Price</th>
                <th className="py-3 px-6 text-center">Stock</th>
                <th className="py-3 px-6 text-right">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {favorites.map(product => {
                const quantity = counts[product._id] || 1;
                const discountPrice = product.discountPrice || product.prices.discount;
                const variantPrice = product.variants[0]?.price || product.prices.price;

                return (
                  <tr key={product._id} className="border-b border-gray-200">
                    <td className="py-3 px-6 text-left cursor-pointer">
                      <div className="flex items-center">
                        <img
                          src={product.image[0]}
                          alt={product.title.en}
                          className="w-20 h-20 object-cover mr-4"
                          onClick={() => handleProductClick(product._id)}
                        />
                        <div>
                          <div className="font-semibold"  onClick={() => handleProductClick(product._id)}>{product.title.en}</div>
                          <div className="flex items-center mt-1">
                            {renderStars(product.rating || 0)} 
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center bg-white border-slate-200 border-solid p-1 border-y-2 rounded-full">
                        <button
                          onClick={() => handleDecrement(product._id)}
                          aria-label="Decrement quantity"
                          className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
                        >
                          <img src={DecrementIcon} alt="Decrease quantity" className="w-4 h-4" />
                        </button>
                        <span className="mx-2">{quantity}</span>
                        <button
                          onClick={() => handleIncrement(product._id)}
                          aria-label="Increment quantity"
                          className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
                        >
                          <img src={IncrementIcon} alt="Increase quantity" className="w-4 h-4" />
                        </button>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      {calculatePrice(variantPrice, quantity)}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {calculatePrice(discountPrice, quantity)}
                    </td>
                    <td className="py-3 px-6 text-center whitespace-nowrap">
                      <span className={product.stock > 0 ? "text-green-500" : "text-primary-color"}>
                        {product.stock > 0 ? "In Stock" : "Out of Stock"}
                      </span>
                    </td>

                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => removeFromFavorites(product._id)}
                        aria-label="Remove from favorites"
                        className="text-secondary-color border-none bg-white cursor-pointer"
                      >
                        <img src={DeleteIcon} alt="Remove from favorites" />
                      </button>
                    </td>
                  </tr>
                );
              })}
              {favorites.length === 0 && (
                <tr>
                  <td colSpan="6" className="py-3 px-6 text-center">
                    No items in the wishlist
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />

      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>
      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    </>
  );
};

export default FavoriteList;
  