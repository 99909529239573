import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const MonthlyService = {

getMonthlySale:(saleId)=>{

return axios.get(`${baseURL}/monthly/${saleId}`)

}

}

export default MonthlyService