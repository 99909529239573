import React from 'react'
import { useEffect } from 'react';
import ProdBanner from '../../assets/images/ProductBanner.svg';
import Footer from '../Footer/Footer';
import Header from '../header/header';
import Navbar from '../navbar/navbar';
import "../../assets/css/Privacypolicy.css"
import TabletNavbar from '../navbar/TabletNavbar';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
     <div className='home-container fixed z-10 w-full top-0 '>
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xls:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xls:py-6" />
      </div>
      <div className='pt-24'>
      <div
        className='flex flex-col items-center justify-center relative '
        style={{ 
          backgroundImage: `url(${ProdBanner})`, 
          minHeight: '250px', 
          backgroundPosition: 'center',
          backgroundSize: 'cover', 
        }}
      >
        <div className='absolute inset-0 bg-black opacity-50'></div>
        <div className='relative flex flex-col gap-3 items-center justify-center h-full '>
          <div className='text-3xl font-poppins text-white font-bold text-center'>
          Terms And Conditions
          </div>
          <div className='text-base font-poppins text-white text-center'>
            Home / Terms And Conditions
          </div>
        </div>
      </div>
      </div>

      <div className='container mx-auto py-14 px-6 flex flex-col gap-7  w-2/4'>
        <div className='text-xls font-semibold font-poppins'>
          Terms And Conditions
        <div className='text-xls font-bold font-poppins'>
          Last Updated: 02-03-2024
        </div>
        </div>
       
        <div className='text-slate-500 text-sm'>
        Please read these terms and conditions carefully before using the CartUser e-commerce platform.
        </div>

        <div>
          <div className='text-xls font-bold font-poppins pb-3'>1. Acceptance of Terms</div> 
          <div className='text-slate-500 text-sm'>
            By accessing or using the CartUser website and its related services, you agree to comply with and be bound by these terms and conditions. If you disagree with any part of these terms, please refrain from using our platform.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>2. User Accounts</div>
          <div className='text-slate-500 text-sm  pb-1'>
          <span className='font-semibold'> Account Information:</span>  Users are responsible for maintaining the confidentiality of their account information and passwords.
          </div>
          <div className='text-slate-500 text-sm '>
          <span className='font-semibold'> Account Termination:</span>  CartUser reserves the right to terminate or suspend user accounts without prior notice if there is a violation of these terms.
          </div>
        </div>    

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>3. Products and Services</div>
        <div className='text-slate-500 text-sm pb-1'>
          <span className='font-semibold'> Product Information:</span>  While we strive for accuracy, we do not guarantee the completeness or reliability of product information on CartUser. Users are encouraged to verify details independently.
          </div>
          <div className='text-slate-500 text-sm '>
          <span className='font-semibold'>Pricing:</span>  Prices are subject to change without notice. CartUser reserves the right to modify or discontinue any product or service without prior notice.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>4. Privacy Policy</div>
          <div className='text-slate-500 text-sm '>
            Your use of CartUser is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>5. Intellectual Property</div>
        <div className='text-slate-500 text-sm  pb-1'>
          <span className='font-semibold'> Ownership:</span> All content and materials on CartUser, including but not limited to text, graphics, logos, and images, are the property of [Your Company Name] and are protected by intellectual property laws.
          </div>
          <div className='text-slate-500 text-sm '>
          <span className='font-semibold'> Use Restrictions:</span> Users are prohibited from using, reproducing, or distributing any content from CartUser without explicit permission.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>6. Disclaimers</div>
        <div className='text-slate-500 text-sm  pb-1'>
            <span className='font-semibold'>Availability:</span> CartUser strives to maintain uninterrupted service, but we do not guarantee continuous, error-free operation of the platform.
          </div>
          <div className='text-slate-500 text-sm '>
            <span className='font-semibold'>Content Accuracy:</span> We do our best to provide accurate and up-to-date information, but we make no warranties or representations regarding the accuracy of the content on CartUser.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>7. Limitation of Liability</div>
          <div className='text-slate-500 text-sm '>
            CartUser, its affiliates, and partners are not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>8. Changes to Terms</div>
          <div className='text-slate-500 text-sm '>
            CartUser reserves the right to modify these terms and conditions at any time. Users are responsible for regularly reviewing these terms, and continued use of the platform constitutes acceptance of any changes.
          </div>
        </div>

        <div>
        <div className='text-xls font-bold font-poppins pb-3'>Contact Information</div>
          <div className='text-slate-500 text-sm '>
            If you have any questions or concerns regarding these terms and conditions, please contact us.
          </div>
        </div>
      </div>

      <Footer />

      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    
    </>
  )
}

export default TermsAndConditions